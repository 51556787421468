import React from "react";
import ProfileAddress from "./components/address";
import OrderHistory from "./components/order-history";
import {NavLink} from "react-router-dom";
import ProfileCard from "./components/profile-card";
import Helmet from "react-helmet"
import {config} from "../../env";
// import ProfileSidebar from "./components/sidebar";
import Iframe from 'react-iframe'
import ProfileHeader from "./components/header";
import ViewWalletTransaction from "./components/view-wallet-transaction";
import {isArray} from "../../utils/util";

const ProfilePage = props => {
    let data = props;
    const {
        addressList,
        userDetail,
        orderHistory,
        totalCartData,
        handleLogout,
        walletHistory,
        isWalletCRLoading,
        getAllWalletCreditDebit,
        matchSchedule,
        selectedTeam
    } = props;
    var match = {};
    if (isArray(matchSchedule) && matchSchedule.length > 0)
        match = matchSchedule[0];
    // console.log(match);
    // console.log(props, "selected team");
    return (

        <div>
            <Helmet>
                <title>Profile - Retro in Metro</title>
            </Helmet>
            <ProfileHeader handleLogout={handleLogout}/>
            <section className="profile">
                <div className="container">
                    <div className="text-right mb-3">
                        <NavLink
                            to="/products"
                            className="btn btn-secondary back-tomenu  d-md-none"
                        >
                            Back to Menu
                        </NavLink>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-5">
                            <ProfileCard
                                userDetail={userDetail}
                                handleLogout={handleLogout}
                            />
                            <ul className="profile-nav">
                                <li>
                                    <button
                                        onClick={e => data.order()}
                                        className="btn-nothing active profile-order-trigger"
                                    >
                                        Orders
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={e => data.address()}
                                        className="btn-nothing profile-address-trigger"
                                    >
                                        Address
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-8 col-md-7">
                            <div className="mb-3">
                                <NavLink
                                    to="/products"
                                    className="btn btn-secondary back-tomenu float-right d-none d-md-inline-block"
                                >
                                    Back to Menu
                                </NavLink>
                                <h6 className="pt-2 text-uppercase">Profile</h6>
                                <div className="clearfix"></div>
                            </div>
                            {/*<div className="todays-match">*/}
                            {/*    <div className="">*/}
                            {/*        <div className="row">*/}
                            {/*            {selectedTeam && selectedTeam['team_id'] == undefined && (*/}
                            {/*                <div className="offset-md-3">*/}

                            {/*                </div>*/}
                            {/*            )}*/}
                            {/*            <div className="col-md-6">*/}
                            {/*                <div className="todays-match-vs">*/}
                            {/*                    <p>Today's Match</p>*/}
                            {/*                    <ul>*/}

                            {/*                        /!*<p>{matchSchedule && isArray(matchSchedule)}</p>*!/*/}
                            {/*                        <li>*/}
                            {/*                            <div><img className="team-img" src={`${match.team1_img}`}*/}
                            {/*                                      alt=""/></div>*/}
                            {/*                        </li>*/}
                            {/*                        <li>*/}
                            {/*                            <div><img className="team-img" src={`${match.team2_img}`}*/}
                            {/*                                      alt=""/></div>*/}
                            {/*                        </li>*/}
                            {/*                    </ul>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            {selectedTeam && selectedTeam['team_id'] && (*/}
                            {/*                <div className="col-md-6">*/}
                            {/*                    <div className="wallet-ipl">*/}
                            {/*                        <p className="wallet-ipl-p">your current runs</p>*/}
                            {/*                        <h1><img src="/images/teams/bat-big.png"*/}
                            {/*                                 alt=""/><span>{userDetail.customer_wallet_balance &&*/}
                            {/*                        userDetail.customer_wallet_balance}</span></h1>*/}
                            {/*                        <p className="wallet-ipl-p1 opa pt-2">your team</p>*/}
                            {/*                        <p className="wallet-ipl-p1 pb-2">*/}
                            {/*                            <strong>{selectedTeam.team_name}</strong></p>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            )}*/}

                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="full-table">*/}
                            {/*    <p><strong>IPL T20 Schedule</strong></p>*/}
                            {/*    <div className="row">*/}
                            {/*        {isArray(matchSchedule) &&*/}
                            {/*        matchSchedule.map(matchItem => (*/}
                            {/*            <div key={matchItem.team1} className="col-md-4">*/}
                            {/*                <div>*/}
                            {/*                    <ul className="schedule-table">*/}
                            {/*                        <li>*/}
                            {/*                            <div className="schedule">*/}
                            {/*                                <div className="schedule-img">*/}
                            {/*                                    <img src={`${matchItem.team1_img}`}*/}
                            {/*                                         alt=""/> {matchItem.team1}*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}

                            {/*                        </li>*/}
                            {/*                        <li className="padding-12"><strong>VS</strong></li>*/}
                            {/*                        <li>*/}
                            {/*                            <div className="schedule">*/}
                            {/*                                <div className="schedule-img">*/}
                            {/*                                    <img src={`${matchItem.team2_img}`}*/}
                            {/*                                         alt=""/> {matchItem.team2}*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        </li>*/}
                            {/*                        <li>{matchItem.match_date}</li>*/}
                            {/*                    </ul>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        ))}*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="transact">*/}
                            {/*    <button*/}
                            {/*        className="float-right btn py-2 mt-2 btn-brand"*/}
                            {/*        data-target="#TransactionModal"*/}
                            {/*        data-toggle="modal"*/}
                            {/*        onClick={getAllWalletCreditDebit}*/}
                            {/*    >*/}
                            {/*        /!*Runs History*!/*/}
                            {/*        Credit History*/}
                            {/*    </button>*/}
                            {/*    <p className="mb-1">Your Current Credits</p>*/}
                            {/*    <h3 className="mb-0">*/}
                            {/*        {userDetail.customer_wallet_balance &&*/}
                            {/*        userDetail.customer_wallet_balance}*/}
                            {/*    </h3>*/}
                            {/*</div>*/}
                            <div className="mt-4"></div>
                            {/*<div>*/}
                            {/*    <Iframe className="iframe" url="https://widget.crictimes.org/"*/}
                            {/*            width="100%"*/}
                            {/*            height="450px"*/}
                            {/*            border="none"*/}
                            {/*            id="myId"*/}
                            {/*            className="myClassname"*/}
                            {/*            display="initial"*/}
                            {/*            position="relative"/>*/}
                            {/*</div>*/}
                            <OrderHistory
                                orderHistory={orderHistory}
                                totalCartData={totalCartData}
                                handleOrderDetails={props.handleOrderDetails}
                            />
                            <ProfileAddress
                                addressList={addressList}
                                handleSelectAddress={props.handleSelectAddress}
                                handleDeleteAddress={props.handleDeleteAddress}
                                addressId={props.addressId}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <ViewWalletTransaction
                walletHistory={walletHistory}
                isWalletCRLoading={isWalletCRLoading}
            />
        </div>
    );
};
export default ProfilePage;
