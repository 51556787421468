import React, { Component } from 'react';
import AboutUsPage  from './about-us-html'; 
class AboutUs extends Component {
    constructor(props){ 
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
        return(
            <AboutUsPage 
            />
        )
    }
}
export default AboutUs;