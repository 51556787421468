/*global google*/

import React, { Component } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Polyline,
} from 'react-google-maps';
import io from 'socket.io-client';
import { getValue, setValue } from '../../utils/lodash';
import decodePolyline from 'decode-google-map-polyline';
import styles from './mapStyle';
import { config } from '../../env';
class Map extends Component {
  /* -------------------------------------------------------------------------- */
  /*                                  State Section                             */
  /* -------------------------------------------------------------------------- */

  state = {
    directions: null,
    origin: {},
    dest: {},
    deliveryAgentLocation: {},
    defaultCenter: {},
    latlng: [],
  };

  /* -------------------------------------------------------------------------- */
  /*                                    API Section                             */
  /* -------------------------------------------------------------------------- */

  componentDidMount() {
    // sending  branch and customer location info to direction map to getwaypoints
    this.getGoogleDirections(
      {
        lat: parseFloat(this.props.branchLatLng[0]),
        lng: parseFloat(this.props.branchLatLng[1]),
      },
      {
        lat: parseFloat(this.props.customerLatLng[0]),
        lng: parseFloat(this.props.customerLatLng[1]),
      }
    );
    // updating branch and customer location state from API lat and lng
    this.setState({
      origin: {
        lat: parseFloat(this.props.branchLatLng[0]),
        lng: parseFloat(this.props.branchLatLng[1]),
      },
      dest: {
        lat: parseFloat(this.props.customerLatLng[0]),
        lng: parseFloat(this.props.customerLatLng[1]),
      },
      defaultCenter: {
        lat: parseFloat(this.props.branchLatLng[0]),
        lng: parseFloat(this.props.branchLatLng[1]),
      },
    });

    /* ---------------------------  Socket Section   ------------------------ */

    let persistData = JSON.parse(localStorage.getItem('persist:potful'));
    let loginReducerData =
      persistData &&
      persistData.LoginReducer &&
      JSON.parse(persistData.LoginReducer);

    const socket = io(`${config.streamURL}`, {
      reconnectionDelayMax: 10000,
      transports: ['websocket'],
      path: '/socket.io',
      nsp: '/customers',
      polling: {
        extraHeaders: {
          Authorization: `Token=${this.props.accessToken}`,
          'x-clientid': 'bab29e50ab9f413087648e15cb586b31',
          version: '2.0',
          domain: 'potful.in',
          'Content-Type': 'text/plain',
        },
      },
    });
    let agentInfo =
      getValue(this.props, `totalCartData.bill_status.length`, 0) > 0 &&
      getValue(this.props, `totalCartData.bill_status`, []).filter(
        (item) => item.bill_status === '7'
      );
    if (getValue(agentInfo, `length`, 0) > 0) {
      socket.on('connect', function () {
        // console.log('Client has connected to the server!');
        let event = {
          order_id: getValue(this.props, `orderId`, ''),
          customer_id: loginReducerData.customer_id,
          delivery_person_id: getValue(
            agentInfo,
            `[${0}].assigned_to.assigned_to`,
            0
          ),
        };
        socket.emit('subscribe', event);
        // console.log(event);
      });

      socket.on('location_updated', (data) => {
        let location = JSON.parse(data);
        console.log(location, '----------->socket location');
        // console.log(this.state.latlng);
        this.getGoogleDirectionsByDeliveryPersonLocation({
          lat: getValue(location, `latestLocation.coordinate[${0}]`, ''),
          lng: getValue(location, `latestLocation.coordinate[${1}]`, ''),
        });
        this.setState({
          deliveryAgentLocation: {
            lat: getValue(location, `latestLocation.coordinate[${0}]`, ''),
            lng: getValue(location, `latestLocation.coordinate[${1}]`, ''),
          },
          //   origin: {
          //     lat: getValue(location, `orders[${0}].coordinate[${0}]`, ''),
          //     lng: getValue(location, `orders[${0}].coordinate[${1}]`, ''),
          //   },
          //   defaultCenter: {
          //     lat: getValue(location, `latestLocation.coordinate[${0}]`, ''),
          //     lng: getValue(location, `latestLocation.coordinate[${1}]`, ''),
          //   },
        });
        this.props.setOrderId(getValue(location, `orders[${0}].order_id`, ''));
        this.props.setSocketAllOrders(getValue(location, `orders`, ''));
      });
    }
  }

  getGoogleDirections = (origin, dest) => {
    console.log('**********........Google API hitting......**********');
    // it will trigger only at very first time and will provide polyline route in map
    // console.log(this.props.branchLatLng, this.props.customerLatLng);
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: dest,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          /* ---------------------------     Decoding polyline latlng        ------------------------ */
          let polylines = decodePolyline(
            getValue(result, `routes[${0}].overview_polyline`, '')
          );
          this.setState({
            directions: result,
            latlng: polylines,
          });
          this.props.setOrderTime(
            getValue(result, `routes[${0}].legs[${0}].duration.text`, '')
          );
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  getGoogleDirectionsByDeliveryPersonLocation = (delivery_person_loc) => {
    let i;
    for (let j = 0; j < getValue(this.state, `latlng.length`, 0); j++) {
      const latLong1 = new window.google.maps.LatLng(
        delivery_person_loc.lat,
        delivery_person_loc.lng
      );
      const latLong2 = new window.google.maps.LatLng(
        getValue(this.state, `latlng[${j}].lat`, ''),
        getValue(this.state, `latlng[${j}].lng`, '')
      );

      // console.log(delivery_person_loc.lat,
      // delivery_person_loc.lng,"----------->delevery guy location")
      // console.log(getValue(this.state, `latlng[${j}].lat`, ''),getValue(this.state, `latlng[${j}].lng`, ''),"------>loop location")
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          latLong1,
          latLong2
        );
      console.log(distance, '-------->distance');
      if (distance > 100) {
        console.log(
          'delivery agent location and destination not near (>100)---->',
          distance
        );
        this.getGoogleDirections(delivery_person_loc, this.state.dest);
        break;
      } else {
        i = j;
        // getValue(this.state, `latlng`, []).filter((item, index) => {
        //   if (index > j) {
        //     polylineArray.push(item);
        //   }
        // });
        // this.setState({
        //   latlng: polylineArray,
        //   deliveryAgentLocation: delivery_person_loc,
        //   defaultCenter: delivery_person_loc,
        // });
        console.log(
          'delivery agent location and destination near (<100) *** No Google API Call------>',
          distance
        );
        break;
      }
    }
    let polylineArray = [];
    console.log(i, '------------->');
    if (i) {
      getValue(this.state, `latlng`, []).filter((item, index) => {
        if (index >= i) {
          polylineArray.push(item);
        }
      });
      this.setState({
        latlng: polylineArray,
        deliveryAgentLocation: delivery_person_loc,
        defaultCenter: delivery_person_loc,
      });
    }
  };

  render() {
    console.log(this.state.latlng);
    return (
      <div>
        <GoogleMapExample
          containerElement={<div style={{ height: `400px`, width: '100%' }} />}
          mapElement={<div style={{ height: `100%` }} />}
          defaultCenter={this.state.defaultCenter}
          directions={this.state.directions}
          dest={this.state.dest}
          origin={this.state.origin}
          deliveryAgentLocation={this.state.deliveryAgentLocation}
          latlng={this.state.latlng}
          current_status={getValue(this.props, `current_status`, '')}
        />
      </div>
    );
  }
}

export default Map;

const GoogleMapExample = withGoogleMap((props) => (
  <GoogleMap
    defaultCenter={props.defaultCenter}
    defaultZoom={14}
    ref={(map) => {
      const bounds = new window.google.maps.LatLngBounds();
      props.latlng.map((x) => {
        bounds.extend(new window.google.maps.LatLng(x.lat, x.lng));
      });
      map && map.fitBounds(bounds);
      // props.onMapMounted(map);
    }}
    defaultOptions={{ styles, disableDefaultUI: true }}
  >
    {/* {props.directions != null && (
      <DirectionsRenderer
        directions={props.directions}
        options={{ suppressMarkers: true }}
      />
    )} */}
    <Polyline path={props.latlng} options={{ strokeColor: '#ee5a35 ' }} />

    <Marker position={props.dest} icon={require(`./images/homeLocation.svg`)} />
    {getValue(props, `current_status`, '') != 7 && (
      <Marker
        position={props.origin}
        icon={require(`./images/storeLocation.svg`)}
      />
    )}
    <Marker
      position={props.deliveryAgentLocation}
      icon={require(`./images/deliveryguy.svg`)}
    />

    {/* {this.state.waypts.map(waypt => (
      <Marker
        position={{ lat: waypt.location.lat, lng: waypt.location.lng }}
        icon={'http://maps.google.com/mapfiles/kml/paddle/blu-blank.png'}
        label={'W'}
      />
    ))} */}
  </GoogleMap>
));
