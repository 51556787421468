import { headerHttp } from '../_helpers';
import { http } from '../_helpers';

const postAddress = (payload) => {
    return headerHttp.post('/customers/addAddress', payload)
        .then(data => {
            return data ? data : '';
        })
}

const fetchMyAddress = (payload) => {
    return headerHttp.post('/customers/getMyAddress', payload)
        .then(data => {
            return data ? data : '';
        })
}

const fetchUserDetail = () => {
    return headerHttp.get('/customers/getMyProfile')
        .then(data => {
            return data ? data : '';
        })
}
const fetchTotalCartAmount = (payload) => {
    return headerHttp.post('/dashboard/apiCheckout', payload)
        .then(data => {
            return data ? data : '';
        })
}

const replaceCartItems = (payload) => {
    return headerHttp.post('/dashboard/getProductUpdate', payload)
        .then(data => {
            return data ? data : '';
        })
}

const applyCouponCode = (payload) => {
    return http.post('/dashboard/apiCheckout', payload)
        .then(data => {
            return data ? data : '';
        })
}
const placeOrder = (payload) => {
    return headerHttp.post('/dashboard/apiPlaceOrder', payload)
        .then(data => {
            return data ? data : '';
        })
}

const getOrderDetails = (orderId) => {
    return headerHttp.get(`/customers/getMyBill/${orderId}`)
        .then(data => {
            return data ? data : '';
        })
}

const getAllOrders = () => {
    return headerHttp.get(`/customers/getMyBills`)
        .then(data => {
            return data ? data : '';
        })
}
const getPaymentGateway = (branchId) => {
    return headerHttp.get(`/dashboard/availableGateWay/${branchId}`)
        .then(data => {
            return data ? data : '';
        })
}

const getAllWalletCreditDebit = (branchId) => {
    return headerHttp.get(`/customers/loadMyWalletTransactions?transaction_type=0&length=100&start=0`)
        .then(data => {
            return data ? data : '';
        })
}
const getSelectedTeam = () => {
    return headerHttp.get(`/campaign/getSelectedTeam/1`)
        .then(data => {
            return data ? data : '';
        })
}
const getMatchSchedule = () => {
    return headerHttp.get(`/campaign/getMatchSchedule/1`)
        .then(data => {
            return data ? data : '';
        })
}

const getTeamList = () => {
    return headerHttp.get(`/campaign/getTeamList/1`)
        .then(data => {
            return data ? data : '';
        })
}


const markTeamSelected = (team_id) => {
    const payload = {'team_id': team_id};
    return headerHttp.post(`/campaign/customerSelectTeam`,payload)
        .then(data => {
            return data ? data : '';
        })
}

const verifyOnlinePayment = (payload) => {
    return headerHttp.post('/dashboard/verifyOnlinePaymentApi', payload)
        .then(data => {
            return data ? data : '';
        })
}

const verifyPaymentStatus = (paymentId) => {
    return headerHttp.get(`/payments/requestPaymentStatus/${paymentId}`)
        .then(data => {
            return data ? data : '';
        })
}

const cancelPayment = (paymentId) => {
    return headerHttp.get(`/payments/requestPaymentCancellation/${paymentId}`)
        .then(data => {
            return data ? data : '';
        })
}

export const checkoutService = {
    fetchMyAddress,
    fetchUserDetail,
    fetchTotalCartAmount,
    postAddress,
    applyCouponCode,
    placeOrder,
    getOrderDetails,
    getAllOrders,
    getAllWalletCreditDebit,
    getMatchSchedule,
    getPaymentGateway,
    verifyOnlinePayment,
    verifyPaymentStatus,
    cancelPayment,
    getSelectedTeam,
    replaceCartItems,
    markTeamSelected,
    getTeamList,
}