import React, { Component } from 'react';
import AboutUsPage  from './media-html';
class Media extends Component {
    constructor(props){
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    
    render() {
        return(
            <AboutUsPage
            />
        )
    }
}
export default Media;
