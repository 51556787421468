import LandingPage from './_components/landing/landing'
import InnerPage from './_components/inner/inner'
import AboutUsPage from './_components/about-us/about-us';
import PageNotFound from './_components/page-not-found/page-not-found';
import CheckoutPage from './_components/checkout/checkout';
import PrivacyPage from './_components/privacy/privacy';
import TermsPage from './_components/terms/terms';
import ProductDetailsPage from './_components/product-details/product-details';
import TrackOrderPage from './_components/track-order/track-order';
import ProfilePage from './_components/profile/profile';
import OffersPage from './_components/offers/offers';
import HyderabadiBiryani from './_components/hyderabadi-biryani/hyderabadi-biryani';
import KolkataBiryani from './_components/kolkata-biryani/kolkata-biryani';
import LucknowiBiryani from './_components/lucknowi-biryani/lucknowi-biryani';
import Kebabs from './_components/kebabs/kebabs';
import RestaurantMenu from './_components/restaurant-menu/restaurant-menu';
import Media from './_components/media/media';
import Iplterms from "./_components/choose-team/iplterms";
import Chooseteam from "./_components/chooseteam/chooseteam";
import LiveKitchen from "./_components/live-kitchen/live-kitchen";
import Feedback from "./_components/feedback/index";
import LiveTrackOrder from "./_components/track-order-map/track-order";
import FeedbackSuccess from './_components/feedback/feedback-success';
import RefundPolicy from './_components/refund-policy/refund-policy';
import ShippingPolicy from './_components/shipping-policy/shipping-policy';

const routes = [
    { path: '/categories/:categoryName', exact: true, name: 'Product Details', component: InnerPage },
    { path: '/', exact: true, name: 'Home', component: LandingPage },
    { path: '/products', exact: true, name: 'restaurant-menu', component: InnerPage },
    { path: '/restaurant-menu1', exact: true, name: 'restaurant-menu1', component: RestaurantMenu },
    { path: '/about-us', exact: true, name: 'About Us', component: AboutUsPage },
    { path: '/media', exact: true, name: 'Media', component: Media },
    { path: '/refund-policy', exact: true, name: 'Refund Policy', component: RefundPolicy },
    { path: '/shipping-policy', exact: true, name: 'Shipping Policy', component: ShippingPolicy },
    { path: '/checkout', exact: true, name: 'Checkout', component: CheckoutPage },
    { path: '/privacy', exact: true, name: 'Privacy', component: PrivacyPage },
    { path: '/terms', exact: true, name: 'Terms', component: TermsPage },
    { path: '/products/:productName', exact: true, name: 'Product Details', component: ProductDetailsPage },
    { path: '/product-details', exact: true, name: 'Product Details', component: ProductDetailsPage },
    { path: '/track-order/:orderId', exact: true, name: 'TrackOrder', component: TrackOrderPage },
    { path: '/user_profile', exact: true, name: 'Profile', component: ProfilePage },
    { path: '/offers', exact: true, name: 'Offers', component: OffersPage },
    { path: '/hyderabadi-biryani', exact: true, name: 'Hyderabadi Biryani', component: HyderabadiBiryani },
    { path: '/kolkata-biryani', exact: true, name: 'KolkataBiryani', component: KolkataBiryani },
    { path: '/lucknowi-biryani', exact: true, name: 'Lucknowi Biryani', component: LucknowiBiryani },
    { path: '/kebabs', exact: true, name: 'Kebabs', component: Kebabs },
    { path: '/iplterms', exact: true, name: 'IPL Terms', component: Iplterms },
    { path: '/choose-team', exact: true, name: 'Choose Your Team', component: Chooseteam },
    { path: '/live-kitchen', exact: true, name: 'Live Kitchen', component: LiveKitchen },
    { path: '/feedback/:id', exact: true, name: 'Feedback', component: Feedback },
    { path: '/feedback-success', exact: true, name: 'Feedback', component: FeedbackSuccess },
    { path: '/live-track/:orderId', exact: true, name: 'Feedback', component: LiveTrackOrder },

    { path: '**', exact: true, name: 'Page Not Found', component: PageNotFound },

];

export default routes;
