/* eslint-disable no-undef */
import React, { Component } from 'react';
import { Router, Route, Switch, Redirect,BrowserRouter } from "react-router-dom";
import createHistory from 'history/createBrowserHistory'
import HomePage from './_components/home/home';
import PageNotFound from './_components/page-not-found/page-not-found'
import { connect } from 'react-redux';
import { history } from './_helpers';
import { alertActions } from './_actions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { googleAnalyticKey, facebookPixalKey, googleTagKeg } from "./env";
import ReactPixel from 'react-facebook-pixel';
// ReactGA.initialize(googleAnalyticKey);
// const history = createHistory()
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//     gtmId: 'GTM-KBMPK7Z',
//     dataLayerName: 'PageDataLayer'
// }

// TagManager.initialize(tagManagerArgs)
ReactGA.initialize(googleAnalyticKey);
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    // console.log(location.pathname)
});
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init(facebookPixalKey, options);
ReactPixel.pageView();
class App extends Component {

  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
      dispatch(alertActions.clear());
      // ReactGA.pageview(location.pathname + location.search);
      // console.log(location.pathname)
    });
  }

  render() {
    var checkNetConnection = navigator.onLine ? true : false;
    return (

      <div className="App">
        {!checkNetConnection && (
          <div className="no-internet">
            <div className="container-fluid">Please check your internet connection</div></div>
        )}
          <div className="routes">
            <ToastContainer
              position="bottom-left"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange={false}
              draggable
              pauseOnHover
            />  <BrowserRouter>

            <Switch>
              <Route path='/' component={HomePage}
              render={({ location }) => {
            window.gtag('config', googleTagKeg, {
              page_path: location.pathname + location.search,
            });
          }}
              />
              <Route path='/404' component={PageNotFound} />
              {/*<Redirect from='**' to='/404' />*/}
            </Switch>
            </BrowserRouter>

          </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}
export default connect(mapStateToProps)(App);
