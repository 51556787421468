import React from "react";
import { hydrate, render } from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import LoginReducer from "./_reducers/loginReducer";
import CartReducer from './_reducers/cartReducer';
import ReactPixel from 'react-facebook-pixel';
import { googleAnalyticKey, facebookPixalKey } from "./env";
// const composeEnhancers =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : null || compose;
const google = window.google;
const reducers = combineReducers({ LoginReducer: LoginReducer, CartReducer: CartReducer });
const persistConfig = {
  key: "potful",
  storage: storage,
  stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};
const pReducer = persistReducer(persistConfig, reducers);
const store = createStore(pReducer,(applyMiddleware(thunk)));
const persistor = persistStore(store);
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <PersistGate loading={"Please Wait"} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById("root")

  );
} else {
  render(
    <Provider store={store}>
      <PersistGate loading={"Please Wait"} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
}



serviceWorker.unregister();
