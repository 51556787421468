import React, { Component } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";

class LoginCheckout extends Component {
  LoginSchema = Yup.object().shape({
    phone: Yup.string()
      .min(10, "Phone number is not valid!")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .required("Phone number is not valid!")
  });
  handleSubmit = async (values, { setSubmitting }) => {
    await this.props.handleUserLogin(values)
    setSubmitting(false);
  };
  render() {
    return (
      <div className="login-holder">
        <div className="checkout-login-details">
          <p>Phone Login</p>
          <Formik
            validationSchema={this.LoginSchema}
            initialValues={{ phone: "" }}
            onSubmit={this.handleSubmit}
          >
            {({ onSubmit, isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <Field
                    name="phone"
                    type="text"
                    className="form-control"
                    placeholder="10 Digit Phone Number"
                  />
                  <p className="text-danger fs-12"><ErrorMessage name="phone" /></p>
                </div>
                <button
                  disabled={isSubmitting}
                  className="d-none otp-modal"
                  data-toggle="modal"
                  data-target="#otp-modal"
                >
                </button>
                <div className="form-group">
                  <button
                    type="submit"
                    onClick={onSubmit}
                    disabled={isSubmitting}
                    className="btn btn-brand btn-block btn-standard w-100"
                  >
                    Login
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="social-login">
            {/* <p>Social Login</p> */}
            {/* <ul>
              <li>
                <button className="btn-nothing facebook">Facebook</button>
              </li>
              <li>
                <button className="btn-nothing google">Google</button>
              </li>
            </ul> */}
            <div className="clearfix" />
            <p className="noaccount">
              Don't have an account?{" "}
              <button
                onClick={e => this.props.signup()}
                className="btn-nothing checkout-signup-trigger"
              >
                Signup
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginCheckout;
