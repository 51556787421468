import React, {Component} from "react";
import CheckoutPage from "./checkout-html";
import $ from "jquery";
import moment from "moment";
import {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import Geocode from "react-geocode";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {authService} from "../../_services/auth.service";
import {checkoutService} from "../../_services/checkout.service";
import {homeService} from "../../_services/home.service";
import {otpVerify, userLogin, userSignup} from "../../_reducers/loginReducer";
import {AddToCart, SetBranchAddress} from "../../_reducers/cartReducer";
import ReactGA from "react-ga4";
import {googleAnalyticKey} from "../../env";
import {checkAllCartItemInStock, checkHourExistInBetweenTime, isArray} from "../../utils/util";
import debounce from "../../utils/debounce";
import inside from "point-in-polygon";
import * as constant from "../../constants/constant";
import {Event, MyEvent} from '../../_actions/trackEvent';
import ReactModal from "react-modal";
import Iframe from "react-iframe";
import {menuService} from "../../_services";
import {getValue} from "../../utils/lodash";

// ReactGA.plugin.require('ec');
Geocode.setApiKey("AIzaSyBWZ8pckCiE-Qp5ka0FRveEGI8l8vfIt2E");
ReactGA.initialize(googleAnalyticKey);

class Checkout extends Component {
    constructor(props) {
        super(props);
        window.scrollTo({top: 0, behavior: "smooth"});
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenModal1 = this.handleOpenModal1.bind(this);
        this.handleCloseModal1 = this.handleCloseModal1.bind(this);
        this.handleApplyCoupon = this.handleApplyCoupon.bind(this);
        this.handleCouponSelect = this.handleCouponSelect.bind(this);

    }

    state = {
        mobileNo: "",
        chosenLat: 12.961,
        chosenLng: 77.6387,
        addressList: [],
        typeOfAddress: 1,
        address_apartment: "",
        address_area: "",
        address_city: "",
        address_pincode: "",
        address_state: "",
        address_landmark: "",
        coupon_id: "",
        selAddressId: "",
        couponCode: "",
        valid_address_apartment: false,
        valid_address_area: false,
        valid_address_city: false,
        valid_address_pincode: false,
        valid_address_state: false,
        accessToken: "",
        coupon_text: "",
        totalCartData: {},
        couponList: [],
        isLoading: false,
        polygonCoordinates: [],
        isExistInPolygon: false,
        deliveryDate: null,
        startServingHour: null,
        endServingHour: null,
        selectedPaymentType: 5,
        use_wallet: false,
        isOtpSend: false,
        userDetails: {},
        orderDetails: {},
        categories: [],
        currentBranchInfo: {},
        isDateRequired: false,
        isSubmitClicked: false,
        isValidCouponCode: false,
        razorPayMentId: "",
        payment_portal_id: 5,
        isAddAddressClick: false,
        verifyingPayment: false,
        isAddressLoading: false,
        showModal: false,
        showModal1: false,
        addressType: [
            {key: 1, value: "Home"},
            {key: 2, value: "Office"},
            {key: 3, value: "Other"}
        ]
    };

    UNSAFE_componentWillMount() {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = false;
        document.body.appendChild(script);
    }

    componentDidMount() {
        this.setState({accessToken: this.props.accessToken});
        if (this.props.accessToken) {
            this.getUserAddress();
        }
        this.getCurrentBranchInfo();
        this.getBranchCategory();
        this.getTotalCardAmountData(1);
        this.getCurrentBranchLatLng();
        if (!isArray(this.props.cartData)) {
            this.props.history.push("/");
        }
        this.analyseGoogleAnalytics();
        this.getCouponListItems();
        // this.getBranchListData()
    }


    getBranchLocation = async (lat, lng, address) => {
        this.checkPointExistInPolygonNew([lat, lng], address)
    }
    getCouponListItems = async () => {
        this.setState({isLoading: true});
        let response = await homeService.getCouponList(this.props.branchId);
        if (response) {
            this.setState({
                couponList: response.data, isLoading: false
            });
        } else {
            this.setState({isLoading: false});
        }
    }

    handleOpenModal() {
        this.setState({showModal: true});
    }

    handleOpenModal1() {
        this.setState({showModal1: true});
    }

    handleCloseModal() {
        this.setState({showModal: false});
    }

    handleCloseModal1() {
        this.setState({showModal1: false});
    }

    analyseGoogleAnalytics = () => {
        ReactGA.ga("send", "pageview", `/checkout`);
    };

    getCurrentBranchLatLng = () => {
        let branchCategory = this.props.branchCategory;
        let selectedBranch = branchCategory.find(
            branch => branch.branch_id === getValue(this.props, `branchId`, '')
        );
        let servePolygon = [];
        if (
            selectedBranch &&
            selectedBranch.branch_serving_area &&
            isArray(selectedBranch.branch_serving_area.serving_area)
        ) {
            servePolygon = selectedBranch.branch_serving_area.serving_area.map(
                area => {
                    return {lat: Number(area.lat), lng: Number(area.lng)};
                }
            );
            this.setState({polygonCoordinates: servePolygon});
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.getCurrentBranchInfo();
        if (nextProps.accessToken !== this.state.accessToken) {
            this.setState({accessToken: nextProps.accessToken});
            setTimeout(() => {
                this.getUserAddress();
            }, 1500);
        }
    }

    getUserAddress = async () => {
        this.setState({isAddressLoading: true});
        let payload = {branch_id: this.props.branchId};
        let response = await checkoutService.fetchMyAddress(payload);
        if (response.error === "" && isArray(response.data)) {
            response.data.reverse();
            this.getUserDetails();
            // this.getBranchLocation(getValue(response, `data[${0}].lat`, ''), getValue(response, `data[${0}].lng`, ''), getValue(response, `data[${0}].google_address`, ''))
            this.setState({
                addressList: response.data,
                isAddressLoading: false,
                // chosenLat: Number(defaultLatLng[0]),
                // chosenLng: Number(defaultLatLng[1]),
                // addressByMap: response.data[0].google_address,
                selAddressId: response.data[0].address_id
            });
        } else {
            this.setState({isAddressLoading: false});
        }
    };

    getUserDetails = async () => {
        let response = await checkoutService.fetchUserDetail();
        if (response.data && response.error === "") {
            this.setState({userDetails: response.data});
        }
    };

    getTotalCardAmountData = async isCouponApplied => {
        // ReactGA.ga("send", "pageview", `/order_details`);
        let payload = {coupon_text: this.state.coupon_text};
        let cartData = this.props.cartData;
        let itemsObject = {};
        for (let index = 0; index < cartData.length; index++) {
            itemsObject[cartData[index].product_price_id] =
                cartData[index].productCount;
        }
        payload.items = itemsObject;
        payload.use_wallet = this.state.use_wallet;
        // console.log(payload);

        let response = await checkoutService.fetchTotalCartAmount(payload);
        if (response.data.total) {
            let servingHours = response.data.serving_hours;
            let startServingHour = servingHours.split(",")[0];
            let endServingHour = servingHours.split(",")[1];
            this.setState({
                totalCartData: response.data,
                startServingHour: startServingHour,
                endServingHour: endServingHour,
                coupon_id: response.data.coupon_id
            });
            if (isCouponApplied === 2 && !response.data.coupon)
                toast.error(constant.WRONG_COUPON_CODE);
            else if (isCouponApplied === 2 && response.data.coupon)
                toast.success(constant.COUPON_APPLIED);
            else if (response.error !== "") {
                toast.error(response.error);
            }
        }
    };

    handleUserLogin = async mobileNo => {
        ReactGA.ga("send", "pageview", `/login`);
        const data = {phone: Number(mobileNo.phone)};
        let response = await authService.postOtpLogin(data);
        this.setState({mobileNo: mobileNo.phone});
        if (
            response &&
            response.data.customer_phone !== "" &&
            response.data.customer_phone !== undefined
        ) {
            toast.success(constant.OTP_SENT);
            this.setState({isOtpSend: true});
        } else {
            let errorMessage = response.errors || response.error_message;
            toast.error(errorMessage);
        }
    };

    handleUserSignup = async values => {
        ReactGA.ga("send", "pageview", `/logout`);
        values = {
            ...values,
            phone: Number(values.phone),
            gender: 1,
            name: values.name,
            email: values.email
        };
        let response = await authService.postUserSignup(values);
        this.setState({mobileNo: values.phone});
        if (
            response &&
            response.data.customer_phone !== "" &&
            response.data.customer_phone !== undefined
        ) {
            this.setState({isOtpSend: true});
            toast.success(constant.OTP_SENT);
        } else {
            let errorMessage = response.errors || response.error_message;
            toast.error(errorMessage);
        }
    };
    handleOtpVerification = async data => {

        let payload = {
            phone: this.state.mobileNo,
            otp_text: Number(data.otp),
            channel: 2
        };
        let response = await authService.postOtpVerify(payload);
        if (
            response &&
            response.data.accessToken !== "" &&
            response.data.accessToken !== undefined
        ) {
            this.props.otpVerify(response.data);
            this.setState({isOtpSend: false});
            toast.success(constant.LOGIN_MESSAGE);
            Event("Explore", "Login Success", "Login Success")
        } else {
            Event("Explore", "Login Failed", "Login Failed")
            let errorMessage = response.errors || response.error_message;
            toast.error(errorMessage);
        }
    };

    deliverNow() {
        $(".deliver-now-trigger").addClass("active");
        $(".deliver-later-trigger").removeClass("active");
        $(".deliver-later").addClass("d-none");
        $(".deliver-now").removeClass("d-none");
    }

    deliverLater() {
        $(".deliver-later-trigger").addClass("active");
        $(".deliver-now-trigger").removeClass("active");
        $(".deliver-now").addClass("d-none");
        $(".deliver-later").removeClass("d-none");
    }

    login() {
        $(".checkout-login-trigger").addClass("active");
        $(".checkout-signup-trigger").removeClass("active");
        $(".signup-holder").addClass("d-none");
        $(".login-holder").removeClass("d-none");
    }

    signup() {
        $(".checkout-signup-trigger").addClass("active");
        $(".checkout-login-trigger").removeClass("active");
        $(".login-holder").addClass("d-none");
        $(".signup-holder").removeClass("d-none");
    }

    handleAddToCart = async product => {
        let cartData = [...this.props.cartData];
        let findExistProduct = cartData.findIndex(
            cart => cart.product_price_id === product.product_price_id
        );
        if (findExistProduct !== -1) {
            cartData[findExistProduct].productCount =
                cartData[findExistProduct].productCount + 1;
            await this.props.addToCart(cartData);
            this.getTotalCardAmountData(1);
        } else {
            product.productCount = 1;
            let updatedCartData = cartData.concat([product]);
            await this.props.addToCart(updatedCartData);
            this.getTotalCardAmountData(1);
        }
    };

    handleDecrementProductCount = async product => {
        let cartData = [...this.props.cartData];
        let findExistProduct = cartData.findIndex(
            cart => cart.product_price_id === product.product_price_id
        );
        if (findExistProduct !== -1) {
            let productCount = cartData[findExistProduct].productCount;
            if (productCount > 1) {
                cartData[findExistProduct].productCount =
                    cartData[findExistProduct].productCount - 1;
                await this.props.addToCart(cartData);
                this.getTotalCardAmountData(1);
            } else {
                cartData = cartData.filter(
                    cart => cart.product_price_id !== product.product_price_id
                );
                if (cartData.length <= 0) {
                    this.props.history.push("/");
                }
                await this.props.addToCart(cartData);
                this.getTotalCardAmountData(1);
            }
        }
    };


    handleAddressChange = (event, key) => {

        this.setState({[key]: event.target.value});
        if (key === "address_apartment")
            this.setState({valid_address_apartment: event.target.value.length >= 4});
        else if (key === "address_area")
            this.setState({valid_address_area: event.target.value.length >= 10});
        else if (key === "address_city")
            this.setState({valid_address_city: event.target.value.length >= 4});
        else if (key === "address_pincode") {
            if (!Number(event.target.value)) {
                this.setState({address_pincode: ""});
                event.target.value="";

            }
            if (event.target.value.length > 6) {
                event.target.value = event.target.value.substr(0, 6);
                this.setState({address_pincode: event.target.value});
            }

            this.setState({valid_address_pincode: event.target.value.length === 6});

        } else if (key === "address_state")
            this.setState({valid_address_state: event.target.value.length > 3});

    };
    handleAddressType = e => {
        this.setState({typeOfAddress: e.target.value});
    };

    handleCreateAddress = async () => {
        if (this.state.valid_address_apartment && this.state.valid_address_area && this.state.valid_address_pincode && this.state.valid_address_city && this.state.valid_address_state) {
            var finalAddress=this.state.address_apartment + " " + this.state.address_area + " " + this.state.address_city + " " + this.state.address_pincode + " " + this.state.address_state;

            // if (this.state.address_landmark.length > 0)
            //     finalAddress+="\nLandmark : "+this.state.address_landmark;
            //

            let payload = {
                address_text: finalAddress,
                address_type: this.state.typeOfAddress,
                branch_id: getValue(this.props, `branchId`, ''),
                google_address: "",
                latlng: this.state.chosenLat + ',' + this.state.chosenLng,
                landmark: this.state.landmark
            };
            if (getValue(payload, `branch_id`, '')) {
                let response = await checkoutService.postAddress(payload);
                if (response.error === "" && response.data.address_id !== "") {
                    this.getUserAddress();
                    this.setState({
                        address_apartment: "",
                        address_area: "",
                        address_city: "",
                        address_pincode: "",
                        address_state: "",
                        valid_address_apartment: false,
                        valid_address_area: false,
                        valid_address_pincode: false,
                        valid_address_city: false,
                        valid_address_state: false,
                        isMapAddressEnable: false,
                        isAddAddressClick: false
                    });
                    toast.success(constant.ADD_ADDRESS);
                } else {
                    let errorMessage = response.errors || response.error_message;
                    toast.error(errorMessage);
                }
            }
        }
    };


    handleDeliveryAddressSelection = (addressId, address) => {
        // this.getBranchLocation(address.lat, address.lng, address)
        this.setState({selAddressId: addressId});
    };

    handleCouponChange = event => {
        this.setState({
            coupon_text: event.target.value,
            isValidCouponCode: event.target.value.length > 4 ? true : false
        });
    };

    handleCouponSelect = (value) => {
        this.setState({
            coupon_text: value,
            isValidCouponCode: value.length > 4 ? true : false
        }, () => {
            this.getTotalCardAmountData(2);
        });
    };

    handleApplyCoupon = () => {
        // ReactGA.ga("send", "pageview", `/apply_coupon`);
        Event("E-Commarce", "Coupon Applied", "Coupon Applied")
        this.getTotalCardAmountData(2);
    };

    handleRemoveCoupon = async () => {
        // ReactGA.ga("send", "pageview", `/remove_coupon`);
        Event("E-Commarce", "Coupon Removed", "Coupon Removed")
        await this.setState({coupon_text: "", coupon_id: ""});
        this.getTotalCardAmountData(1);
    };


    handleOrder = async () => {
        ReactGA.ga("send", "pageview", `/order_click`);
        this.setState({isSubmitClicked: true});
        let getALlProductOfBranch = await this.getALlProductOfBranch();
        let checkAllCartDataInStock = checkAllCartItemInStock(
            getALlProductOfBranch,
            this.props.cartData
        );
        let currentServing =
            checkHourExistInBetweenTime(this.state.currentBranchInfo, "Single") !==
            "Pre Order"
                ? true
                : false;
        currentServing = currentServing || this.state.deliveryDate != null;
        if (checkAllCartDataInStock && currentServing) {
            let addressList = [...this.state.addressList];
            if (this.state.selAddressId) {
                let selectedBranch = addressList.find(
                    address => address.branch_id == getValue(this.props, `branchId`, '')
                );
                if (
                    selectedBranch &&
                    selectedBranch.branch_id == getValue(this.props, `branchId`, '')
                ) {
                    let deliveryDate = this.state.deliveryDate;
                    let payload = {};
                    if (deliveryDate == null) {
                        payload = {
                            coupon_text: this.state.coupon_text,
                            address_id: this.state.selAddressId,
                            branch: getValue(this.props, `branchId`, ''),
                            order_source: 8,
                            pay_type: this.state.selectedPaymentType,
                            serve_type: "",
                            use_wallet: this.state.use_wallet,
                            coupon_id: this.state.coupon_id ? this.state.coupon_id : ""
                        };
                    } else {
                        payload = {
                            coupon_text: this.state.coupon_text,
                            address_id: this.state.selAddressId,
                            branch: getValue(this.props, `branchId`, ''),
                            order_source: 8,
                            pay_type: Number(this.state.selectedPaymentType),
                            serve_type: "",
                            timeslot: moment(deliveryDate).format("YYYY-MM-DD HH:mm:ss"),
                            use_wallet: this.state.use_wallet,
                            coupon_id: this.state.coupon_id ? this.state.coupon_id : ""
                        };
                    }
                    let cartData = this.props.cartData;
                    let itemsObject = {};
                    for (let index = 0; index < cartData.length; index++) {
                        itemsObject[cartData[index].product_price_id] =
                            cartData[index].productCount;
                    }
                    payload.items = itemsObject;
                    if (getValue(payload, `branch`)) {
                        let response = await checkoutService.placeOrder(payload);
                        if (
                            response.error === "" &&
                            ((response.data.order_details &&
                                    response.data.order_details.bill_id) ||
                                response.data.order_id)
                        ) {
                            this.setState({isSubmitClicked: false});
                            let dataOnCart = [];
                            this.setState({orderDetails: {...response.data.order_details}});
                            if (this.state.selectedPaymentType === 1) {
                                this.props.addToCart(dataOnCart);
                                toast.success(constant.ORDER_SUCCESSFUL);
                                if (this.state.selectedPaymentType === 1) {
                                    this.props.history.push(
                                        `/live-track/${response.data.order_details.bill_id}`
                                    );
                                }
                            } else {

                                this.verifyOnlinePayment();
                            }
                        } else {
                            this.setState({isSubmitClicked: false});
                            toast.error(response.errors);
                        }
                    }
                } else {
                    this.setState({isSubmitClicked: false});
                    toast.error(constant.SELECT_OTHER_BRNCH);
                }
            } else {
                this.setState({isSubmitClicked: false});
                toast.success(constant.SELECT_ADDRESS);
            }
        } else {
            this.setState({
                categories: getALlProductOfBranch,
                isDateRequired: true,
                isSubmitClicked: false
            });
        }
    };

    handleAddAddress = () => {
        // this.handleCurrentLocation();
        this.setState({
            isMapAddressEnable: true
        });
    };

    handleChange = address => {
        this.setState({typedAddress: address});
    };

    handleOrderDateChange = (date, startServingHours, preServing) => {
        let hours = moment(date).hour();
        if (moment(date).format("DD MMM YYYY") === moment().format("DD MMM YYYY")) {
            let newDate = new Date(date.setDate(date.getDate()));
            let addedOneDay = new Date(new Date().setDate(new Date().getDate() + 1));
            this.setState({
                deliveryDate: preServing
                    ? addedOneDay.setHours(hours, 0)
                    : !hours
                        ? newDate.setHours(startServingHours)
                        : newDate,
                isDateRequired: false,
                nextDayOrder: false
            });
        } else {
            this.setState({
                deliveryDate: hours
                    ? date.setHours(date.getHours())
                    : date.setHours(startServingHours),
                isDateRequired: false,
                nextDayOrder: true
            });
        }
    };
    checkPointExistInPolygon = (latLang, address) => {
        let allBranches = [...this.props.branchCategory];
        // allBranches = allBranches.find(
        //     branch => branch.branch_id === this.props.branchId
        // );
        let foundBranch = 0;
        for (let i = 0; i < allBranches.length; i++) {

            let branch = allBranches[i];
            if (isArray(branch && branch.polygonArea)) {
                let isExistInside = inside(latLang, branch.polygonArea);
                if (isExistInside)
                    foundBranch = branch.branch_id;
            }

        }
        // console.log(foundBranch)
        if (foundBranch === 0)
            return false;
        else if (foundBranch === this.props.branchId) {
            return true;
        } else {
            // alert("Your cart might get updated");
            this.handleOpenModal();
            let cartData = this.props.cartData;
            let itemsObject = {};
            for (let index = 0; index < cartData.length; index++) {
                itemsObject[cartData[index].product_price_id] =
                    cartData[index].productCount;
            }

            return this.handleCartUpdate(itemsObject, foundBranch, address);
        }

        // if (isArray(allBranches && allBranches.polygonArea)) {
        //     let isExistInside = inside(latLang, allBranches.polygonArea);
        //     return isExistInside;
        // } else {
        //     return false;
        // }
    };

    checkPointExistInPolygonNew = (latLang, address) => {

        let allBranches = [...this.props.branchCategory];
        let foundBranch = 0;
        for (let i = 0; i < allBranches.length; i++) {

            let branch = allBranches[i];
            if (isArray(branch && branch.polygonArea)) {
                let isExistInside = inside(latLang, branch.polygonArea);
                if (isExistInside)
                    foundBranch = branch.branch_id;
            }

        }
        if (foundBranch === 0)
            return false;
        else if (foundBranch === this.props.branchId) {
            return true;
        } else {
            // alert("Your cart might get updated");
            this.handleOpenModal();
            let cartData = this.props.cartData;
            let itemsObject = {};
            for (let index = 0; index < cartData.length; index++) {
                itemsObject[cartData[index].product_price_id] =
                    cartData[index].productCount;
            }

            return this.handleCartUpdateNew(itemsObject, foundBranch, address);
        }
    };

    handleCartUpdateNew = async (itemsObject, branch_id, address) => {
        let payload = {};
        payload.branch_id = branch_id;
        payload.product_list = itemsObject;
        let response = await checkoutService.replaceCartItems(payload);
        if (response && getValue(response, `data.length`, 0) > 0) {
            await this.props.addToCart(response.data);

            // await this.props.updateBranch(branch_id,address);
            // this.getUserAddress();
            // this.forceUpdate();
            // this.getTotalCardAmountData(1);

            if (response.error !== "") {
                toast.error(getValue(response, `error`))
            }
        }
        return true;

    };

    handleCartUpdate = async (itemsObject, branch_id, address) => {
        let payload = {};
        payload.branch_id = branch_id;
        payload.product_list = itemsObject;
        let response = await checkoutService.replaceCartItems(payload);
        if (response && getValue(response, `data.length`, 0) > 0) {
            await this.props.addToCart(response.data);

            await this.props.updateBranch(branch_id, address);
            this.getUserAddress();
            this.forceUpdate();
            this.getTotalCardAmountData(1);

            if (response.error !== "") {
                toast.error(getValue(response, `error`))
            }
        }
        return true;

    };


    handleSelectPayment = value => {
        this.setState({selectedPaymentType: value});
        if (value === 5) {
            this.getAllPaymentGateways();
        }
    };

    getAllPaymentGateways = async () => {
        let response = await checkoutService.getPaymentGateway(this.props.branchId);
        if (response && response.error === "") {
            this.setState({
                razorPayMentId: response.data.MERCHANT_DETAILS,
                payment_portal_id: response.data.payment_portal_id
            });
        }
    };

    verifyOnlinePayment = () => {
        let orderDetails = {...this.state.orderDetails};
        let userDetails = {...this.state.userDetails};
        const baseUrl = window.location.origin;
        let options = {
            key: orderDetails.key,
            amount: Number(orderDetails.bill_total), // 2000 paise = INR 20, amount in paisa
            name: userDetails.customer_first_name + ' ' + userDetails.customer_second_name,
            description: "Purchase Description",
            currency: "INR",
            order_id: orderDetails.order_id,
            payment_capture: 0,
            // image: baseUrl + `${userDetails.image}`,
            handler: async response => {
                this.setState({verifyingPayment: true});
                console.log(response);
                let payload = {
                    ...response,
                    pay_mode: this.state.payment_portal_id,
                    shopping_order_id: orderDetails.bill_id,
                    bill_id: orderDetails.bill_id
                };
                console.log("Verify payload", payload);

                let paymentVerifyRes = await checkoutService.verifyOnlinePayment(
                    payload
                );
                console.log("Verify online payment list", paymentVerifyRes);
                if (paymentVerifyRes && paymentVerifyRes.data.status === "success") {
                    toast.success(constant.ORDER_SUCCESSFUL);
                    let count = 0;
                    if (count <= 15) {
                        const timeOut = setInterval(async () => {
                            const paymentStatus = await checkoutService.verifyPaymentStatus(
                                orderDetails.bill_id
                            );
                            count++;
                            if (count == 16) {
                                this.setState({verifyingPayment: false});
                                clearTimeout(timeOut);
                                const paymentStatus = await checkoutService.cancelPayment(
                                    orderDetails.bill_id
                                );
                                if (
                                    paymentStatus &&
                                    paymentStatus.data &&
                                    paymentStatus.data.status == "success"
                                ) {
                                    toast.error(constant.PAYMENT_FAIL);
                                } else {
                                    toast.error(paymentStatus.errors);
                                }
                            }
                            if (
                                paymentStatus &&
                                paymentStatus.data &&
                                Number(paymentStatus.data.bill_status) >= 3 &&
                                Number(paymentStatus.data.bill_status) <= 8
                            ) {
                                this.setState({verifyingPayment: false});
                                clearTimeout(timeOut);
                                let dataOnCart = [];
                                this.props.addToCart(dataOnCart);
                                this.props.history.push(
                                    `/live-track/${this.state.orderDetails.bill_id}`
                                );
                            } else if (
                                paymentStatus &&
                                paymentStatus.data &&
                                Number(paymentStatus.data.bill_status) == 11
                            ) {
                                clearTimeout(timeOut);
                                toast.error(paymentStatus.errors);
                            }
                        }, 600);
                    }
                } else {
                    this.setState({verifyingPayment: false});
                    let errorMessage = response.errors || response.error_message;
                    toast.error(errorMessage);
                }
            },
            prefill: {
                phone: userDetails.customer_phone,
                name: userDetails.customer_first_name + ' ' + userDetails.customer_second_name,
                email: userDetails.customer_email
            },
            notes: {
                address: this.state.selAddressId
            },
            theme: {
                "color": "#F37254"
            }
        };
        let rzp = new window.Razorpay(options);
        rzp.open();
    };

    handleWalletCheck = async () => {
        ReactGA.ga("send", "pageview", `/wallet_credits`);
        await this.setState({use_wallet: !this.state.use_wallet});
        this.getTotalCardAmountData(1);
    };

    getALlProductOfBranch = async () => {
        let branchId = this.props.branchId;
        if (branchId) {
            let response = await homeService.getBranchLisById(branchId);
            if (response.data && response.data.length) {
                return response.data;
            } else {
                let categories = [];
                return categories;
            }
        } else {
            let categories = [];
            return categories;
        }
    };

    getBranchCategory = async () => {
        let branchId = this.props.branchId;
        if (branchId) {
            let response = await homeService.getBranchLisById(branchId);
            if (response.data && response.data.length) {
                this.setState({categories: response.data});
            } else {
                this.setState({categories: []});
            }
        } else {
            this.setState({isClickScroll: false});
        }
    };

    getCurrentBranchInfo = () => {
        let branchCategory = this.props.branchCategory;
        if (isArray(branchCategory)) {
            let currentBranchInfo = branchCategory.find(
                branch => branch.branch_id === this.props.branchId
            );
            currentBranchInfo &&
            this.setState({currentBranchInfo: currentBranchInfo});
        }
    };

    render() {
        const {isLoggedIn, accessToken, cartData} = this.props;
        return (
            <div>
                <CheckoutPage
                    {...this.state}
                    isLoggedIn={isLoggedIn}
                    cartData={cartData}
                    couponList={this.state.couponList}
                    accessToken={accessToken}
                    deliverNow={e => this.deliverNow()}
                    deliverLater={e => this.deliverLater()}
                    handleUserLogin={this.handleUserLogin}
                    handleUserSignup={this.handleUserSignup}
                    login={e => this.login()}
                    signup={e => this.signup()}
                    handleOtpVerification={this.handleOtpVerification}
                    handleAddToCart={this.handleAddToCart}
                    handleDecrementProductCount={this.handleDecrementProductCount}
                    handleAddressChange={this.handleAddressChange}
                    handleAddressType={this.handleAddressType}
                    handleCreateAddress={this.handleCreateAddress}
                    handleDeliveryAddressSelection={this.handleDeliveryAddressSelection}
                    handleCouponChange={this.handleCouponChange}
                    handleCouponSelect={this.handleCouponSelect}
                    handleApplyCoupon={this.handleApplyCoupon}
                    handleOrder={this.handleOrder}
                    handleCartUpdate={this.handleCartUpdate}
                    handleChange={this.handleChange}
                    handleOrderDateChange={this.handleOrderDateChange}
                    handleAddAddress={this.handleAddAddress}
                    handleSelectPayment={this.handleSelectPayment}
                    handleWalletCheck={this.handleWalletCheck}
                    handleRemoveCoupon={this.handleRemoveCoupon}
                />
                <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                    className="pre-modal"
                    id="two"
                >
                    <div>
                        <div className="cart_update">
                            <img src="images/cart_update.svg" alt=""/>
                            <p>Cart items might update</p>
                        </div>

                    </div>
                    <button onClick={this.handleCloseModal} className="awesome btn btn-warning mt-2 mid">Close</button>
                </ReactModal>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        accessToken: state.LoginReducer.accessToken,
        isLoggedIn: state.LoginReducer.isLoggedIn,
        cartData: state.CartReducer.cartProducts,
        branchId: state.CartReducer.branchId,
        branchCategory: state.CartReducer.branchCategory,
        customer_id: state.CartReducer.customer_id
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loginUser: mobileNo => dispatch(userLogin(mobileNo)),
        userSignup: values => dispatch(userSignup(values)),
        otpVerify: data => dispatch(otpVerify(data)),
        addToCart: products => dispatch(AddToCart(products)),
        updateBranch: (branchId, address) => dispatch(SetBranchAddress(branchId, address))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
