import React, { Component } from 'react';
import HyderabadiBiryaniPage  from './hyderabadi-biryani-html'; 
class HyderabadiBiryani extends Component {
    constructor(props){ 
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
        return(
            <HyderabadiBiryaniPage 
            />
        )
    }
}
export default HyderabadiBiryani;
