import {headerHttp, http} from "../_helpers";

const getBranchLisById = (branchId) => {
    return http.get(`/products/loadMenu/${branchId}?version=2.0`).then(data => {
        return data ? data : '';
    });
};

const getAddressByLatLng = (latitude, longitude) => {
    return fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBmDZBIEZBlxakNtiagEOXg41mr51dGwCE`
    )
        .then(response => {
            return response.json();
        }).then(result => {
            return result;
        }).catch(error => {
            console.log(error);
        });
};
const getBanners = (branchId) => {
    return http.get(`/manage_branch/loadBanners/${branchId}?version=2.0`)
        .then(data => {
            return data;
        });
}
const getCouponList = (branchId) => {
    return http.get(`/manage_branch/getCouponList/${branchId}?version=2.0`)
        .then(data => {
            return data;
        });
}

const deleteUserAddress = (addressId) => {
    return headerHttp.get(`/customers/deleteMyAddress/${addressId}?version=2.0`)
        .then(data => {
            return data;
        });
}

export const homeService = {
    getBranchLisById,
    getAddressByLatLng,
    deleteUserAddress,
    getBanners,
    getCouponList
};
