import moment from "moment";
/*global google*/
export const isArray = data => {
  return data && Array.isArray(data) && data.length > 0 ? true : false;
};

export const countTotalItemsOnCart = cartData => {
  let totalItems = 0;
  if (isArray(cartData)) {
    for (let index = 0; index < cartData.length; index++) {
      totalItems = totalItems + Number(cartData[index].productCount);
    }
    return totalItems;
  } else {
    return totalItems
  }
};

export const checkProductExistOnCart = (product, cartData) => {
  let productCount =
    isArray(cartData) &&
    product &&
    product.product_price_id &&
    cartData.find(cart => cart.product_price_id === product.product_price_id);
  return productCount ? productCount.productCount : 0;
};

export const countQuantity = (product, cartData) => {
  let productCount =
    isArray(cartData) &&
    product &&
    product.product_price_id !== undefined &&
    cartData.find(cart => cart.product_price_id === product.product_price_id);
  return productCount ? productCount.productCount : 1;
};

export const checkItemInStock = (productList, productOnCart) => {
  if (productOnCart && productOnCart.category_id) {
    let productOnCategory = productList.find(
      product => product.category_id === productOnCart.category_id
    );
    let getCardAddedProduct =
      productOnCategory &&
      productOnCategory.products.find(
        product => product.product_id === productOnCart.product_id
      );
    let isProductInStock = getCardAddedProduct
      ? Number(getCardAddedProduct.stock_status) !== -1
        ? true
        : false
      : true;
    return isProductInStock ? null : "Out Of Stock";
  } else {
    return null;

  }
};

export const checkAllCartItemInStock = (productList, cartData) => {
  if (isArray(productList)) {
    let itemInStock = true;
    for (let index = 0; index < cartData.length; index++) {
      let productOnCategory = productList.find(
        product => product.category_id === cartData[index].category_id
      );
      let getCardAddedProduct =
        productOnCategory &&
        productOnCategory.products.find(
          product => product.product_id === cartData[index].product_id
        );
      let isProductInStock = getCardAddedProduct
        ? Number(getCardAddedProduct.stock_status) !== -1
          ? true
          : false
        : true;
      itemInStock = itemInStock && isProductInStock;
    }
    return itemInStock;
  } else {
    return false;
  }
};

export const checkHourExistInBetweenTime = (currentBranchInfo, option) => {
  if (currentBranchInfo && currentBranchInfo.branch_status) {
    let branch_status = currentBranchInfo.branch_status;
    const servingHours = currentBranchInfo.branch_working_hrs;
    // const servingHours = "23:00,24:00";
    let startServingHour = servingHours.split(",")[0];
    let endServingHour = servingHours.split(",")[1];
    let startServing = startServingHour ? startServingHour.split(":") : null;
    let startServingHours = startServing ? startServing[0] : 0;
    let startServingMinute = startServing ? startServing[1] : 0;
    let endServing = endServingHour ? endServingHour.split(":") : null;
    let endServingHours = startServing ? endServing[0] - 1 : 0;
    let endServingMinute = startServing ? +endServing[1] : 0;

    let startServingTime =
      Number(startServingHours) * 60 + Number(startServingMinute);
    let endServingTime =
      Number(endServingHours) * 60 + Number(endServingMinute);
    let currentMinuteHour = moment(new Date()).hour() * 60 + moment().minute();
    let preOrder = "";
    if (option === "Single") {
      preOrder =
        currentMinuteHour >= startServingTime &&
        currentMinuteHour <= endServingTime;
      preOrder = preOrder && branch_status ? "Add To Cart" : "Pre Order";
    } else {
      preOrder =
        currentMinuteHour >= startServingTime &&
        currentMinuteHour <= endServingTime;
      preOrder = preOrder && branch_status ? "Option" : "Pre Order";
    }
    return preOrder;
  } else {
    return "Add To Cart";
  }
};
