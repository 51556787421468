import React from "react";
import { NavLink } from "react-router-dom";
import { config } from "../../../env";
import {
  isArray,
  checkProductExistOnCart,
  checkItemInStock,
  checkAllCartItemInStock,
  checkHourExistInBetweenTime
} from "../../../utils/util";
import { Event, Conversion, AddFB, InitiateFB } from '../../../_actions/trackEvent';
class cartComponent extends React.Component {
  state = {
    cartRelatedCat: []
  };
  componentDidMount() {
    this.showSimilarFoods(this.props.cartRelatedCat, this.props.cartData);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.showSimilarFoods(nextProps.cartRelatedCat, nextProps.cartData);
  }
  totalCartAmount = cartData => {
    let totalCartValue = 0;
    for (let index = 0; index < cartData.length; index++) {
      totalCartValue =
        totalCartValue +
        Number(cartData[index].product_price) * cartData[index].productCount;
    }
    return totalCartValue;
  };
  checkDuplicateProductId = (productId, cartData) => {
    let isAvailable = cartData.findIndex(data => data.product_id === productId);
    return isAvailable === -1 ? true : false;
  };
  showSimilarFoods = (cartRelatedCat, cartData) => {
    cartRelatedCat = isArray(cartRelatedCat) ? cartRelatedCat : [];
    cartData = isArray(cartData) ? cartData : [];
    let newCartRelatedCat = cartRelatedCat.filter(data =>
      this.checkDuplicateProductId(data.product_id, cartData)
    );
    this.setState({ cartRelatedCat: newCartRelatedCat });
  };
  render() {
    const { cartData, currentPage, categories,currentBranchInfo } = this.props;
    const { cartRelatedCat } = this.state;
    return (
        <>
        <div className="cart-background" onClick={e => this.props.closeCart()}>
        </div>
      <section className="cart-side">
        <div className="cart-header">
          <div className="float-right">
            <button
              onClick={e => this.props.closeCart()}
              className="btn-nothing goaway-cart"
            >
              <i className="fa fa-times-circle mr-1" />
              Close
            </button>
          </div>
          <p>
            <span>
              Total{" "}
              <strong>
                {isArray(cartData) ? this.totalCartAmount(cartData) : 0}
              </strong>
              {/* <sup className="currency">&#8377;</sup> */}
            </span>
          </p>
          <div className="clearfix" />
        </div>

        <div className="cart-items">
          {isArray(cartData) ? (
            cartData.map((product, index) => (
              <div key={product.product_id + index} className="cart-item">
                <h2>
                  {/*{product.is_veg === "1" ? (*/}
                  {/*  <img*/}
                  {/*    src={`${config.urlPrefix}/images/veg.svg`}*/}
                  {/*    className="mt--2px mr-1"*/}
                  {/*    alt=""*/}
                  {/*  />*/}
                  {/*) : (*/}
                  {/*  <img*/}
                  {/*    src={`${config.urlPrefix}/images/non_veg.svg`}*/}
                  {/*    className="mt--2px mr-1"*/}
                  {/*    alt=""*/}
                  {/*  />*/}
                  {/*)}*/}
                  {product.product_name}
                </h2>
                <div className="product_price">
                  <div className="product_price_item float-left">
                    <p>
                      {/* <strike>129</strike>{" "} */}
                      <strong>
                        {Math.ceil(product.product_price)}
                        {/* <sup className="currency">&#8377;</sup> */}
                      </strong>{" "}
                      {/* <span className="badge badge-success">15% off</span> */}
                    </p>
                  </div>
                  <div className="add-cart float-right">
                    <p className="btn btn-brand px-0">
                      <button
                        onClick={() =>
                          this.props.handleDecrementProductCount(product) + Event("E-Commerce","Removed from cart", "Removed from cart")
                        }
                        className="btn-nothing minus"
                      >
                        -
                      </button>
                      <span>{checkProductExistOnCart(product, cartData)}</span>
                      <button
                        onClick={() => this.props.handleAddToCart(product) + Event("E-Commerce", "Add to cart", "Add to Cart") + + AddFB()}
                        className="btn-nothing plus"
                      >
                        +
                      </button>
                    </p>
                  </div>
                  <div className="clearfix" />
                  {checkItemInStock(categories, product)}
                </div>
              </div>
            ))
          ) : (
            <div className="cart-item cart-no-item text-center border-0">
              <div>
                <img className="img-fluid height-100" src={`${config.urlPrefix}/images/rim/fav.png`} alt="" />
              </div>
              <h5>No items in cart</h5>
              <p>Add some items to cart to checkout</p>
              <NavLink
                  className="btn btn-brand py-2 btn-standard"
                  to="/products"
                  onClick={e => this.props.closeCart()}
                  >
                Explore Products
              </NavLink>
            </div>
          )}
          <div className="cart-checkout">
            {isArray(cartData) ? (
              checkAllCartItemInStock(categories, cartData) ? (
                <NavLink
                  to="/checkout"
                  className="btn btn-brand btn-block w-100"
                  onClick={()=> Event("E-Commerce", "Initiate checkout ", "Initiate checkout") + InitiateFB() }
                >
                  Checkout
                </NavLink>
              ) : (
                <button
                  disabled="disabled"
                  className="btn btn-brand btn-block w-100"
                >
                  Checkout
                </button>
              )
            ) : (
              ""
            )}
          </div>
          {isArray(cartData) ? (
            <div>
              {currentPage !== "product-details" ? (
                <div className="cart-also">
                  <p className="cart-also-header">You might also like</p>
                  {isArray(cartRelatedCat) &&
                    cartRelatedCat.map(data => (
                      <div key={data.product_id} className="cart-also-item">
                        <div className="cart-also-img">
                          <img
                            src={data.image_url}
                            alt=""
                          />
                        </div>
                        <div className="cart-also-info">
                          <h2>
                            {/*{data.is_veg === "1" ? (*/}
                            {/*  <img*/}
                            {/*    src={`${config.urlPrefix}/images/veg.svg`}*/}
                            {/*    className="mt--2px mr-1"*/}
                            {/*    alt=""*/}
                            {/*  />*/}
                            {/*) : (*/}
                            {/*  <img*/}
                            {/*    src={`${config.urlPrefix}/images/non_veg.svg`}*/}
                            {/*    className="mt--2px mr-1"*/}
                            {/*    alt=""*/}
                            {/*  />*/}
                            {/*)}*/}
                            {data.product_name}
                          </h2>
                          <div className="product_price">
                            <div className="product_price_item float-left">
                              <p>
                                {isArray(data.serving_size) &&
                                  data.serving_size[0] &&
                                  Math.ceil(data.serving_size[0].product_price)}
                                {/* <sup className="currency">&#8377;</sup> */}
                              </p>
                            </div>

                            <div className="add-cart float-right">
                              {checkProductExistOnCart(
                                data.serving_size[0],
                                cartData
                              ) ? (
                                <p className="btn btn-brand px-0">
                                  <button
                                    onClick={() =>
                                      this.props.handleDecrementProductCount(
                                        data.serving_size[0]
                                      ) + Event("E-Commerce", "Removed from cart", "Removed from cart")
                                    }
                                    className="btn-nothing minus"
                                  >
                                    -
                                  </button>
                                  <span>
                                    {checkProductExistOnCart(
                                      data.serving_size[0],
                                      cartData
                                    )}
                                  </span>
                                  <button
                                    onClick={() =>
                                      this.props.handleCartAddToCart(
                                        data.serving_size[0],
                                        data
                                      ) + Event("E-Commerce", "Add to cart", "Add to cart")
                                    }
                                    className="btn-nothing plus"
                                  >
                                    +
                                  </button>
                                </p>
                              ) : (
                                <p className="btn btn-brand px-2">
                                  <button
                                    onClick={() =>
                                      this.props.handleCartAddToCart(
                                        data.serving_size[0],
                                        data
                                      ) + Event("E-Commerce","Add to cart", "Add to cart") + AddFB()
                                    }
                                    className="btn-nothing plus"
                                  >
                                   {checkHourExistInBetweenTime(currentBranchInfo,'Single')}
                                  </button>
                                </p>
                              )}
                            </div>
                            <div className="clearfix" />
                            {checkItemInStock(categories, data.serving_size[0])}
                          </div>
                        </div>
                        {/* </NavLink> */}
                        <div className="clearfix" />
                      </div>
                    ))}
                </div>
              ) : (
                <div className="cart-also">
                  <p className="cart-also-header">You might also like</p>
                  {isArray(cartRelatedCat) &&
                    cartRelatedCat.map(data => (
                      isArray(data.serving_size) && Number(data.stock_status) !== -1 &&
                      <div key={data.product_id} className="cart-also-item">
                        <div className="cart-also-img">
                          <img
                            src={`${config.productImgUrl}/${
                              data.product_id
                            }.jpg`}
                            alt=""
                          />
                        </div>
                        <div className="cart-also-info">
                          <h2>
                            {data.is_veg ? (
                              <img
                                src={`${config.urlPrefix}/images/veg.svg`}
                                className="mt--2px mr-1"
                                alt=""
                              />
                            ) : (
                              <img
                                src={`${config.urlPrefix}/images/non_veg.svg`}
                                className="mt--2px mr-1"
                                alt=""
                              />
                            )}
                            {data.product_name}
                          </h2>
                          <div className="product_price">
                            <div className="product_price_item float-left">
                              <p>
                                <strong>
                                  {isArray(data.serving_size) &&
                                    data.serving_size[0] &&
                                    Math.ceil(
                                      data.serving_size[0].product_price
                                    )}
                                  {/* <sup className="currency">&#8377;</sup> */}
                                </strong>
                              </p>
                            </div>
                            <div className="add-cart float-right">
                              {checkProductExistOnCart(
                                data.serving_size[0],
                                cartData
                              ) ? (
                                <p className="btn btn-brand px-0">
                                  <button
                                    onClick={() =>
                                      this.props.handleDecrementProductCount(
                                        data.serving_size[0]
                                      ) + Event("E-Commerce","Removed from cart", "Removed from cart")
                                    }
                                    className="btn-nothing minus"
                                  >
                                    -
                                  </button>
                                  <span>
                                    {checkProductExistOnCart(
                                      data.serving_size[0],
                                      data
                                    )}
                                  </span>
                                  <button
                                    onClick={() =>
                                      this.props.handleCartAddToCart(
                                        data.serving_size[0],
                                        data
                                      ) + Event("E-Commerce", "Add to cart", "Add to Cart") + AddFB()
                                    }
                                    className="btn-nothing plus"
                                  >
                                    +
                                  </button>
                                </p>
                              ) : (
                                <p className="btn btn-brand px-2">
                                  <button
                                    onClick={() =>
                                      this.props.handleCartAddToCart(
                                        data.serving_size[0],
                                        data
                                      ) + Event("E-Commerce","Add to cart", "Add to Cart") + AddFB()
                                    }
                                    className="btn-nothing plus"
                                  >
                                   {checkHourExistInBetweenTime(currentBranchInfo,'Single')}
                                  </button>
                                </p>
                              )}
                            </div>
                            <div className="clearfix" />
                            {checkItemInStock(categories, data.serving_size[0])}
                          </div>
                        </div>
                        <div className="clearfix" />
                      </div>
                    ))}
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
        </>
    );
  }
}

export default cartComponent;
