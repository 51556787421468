import React, { Component } from "react";
import * as Yup from "yup";
import { Form, Field, Formik, ErrorMessage } from "formik";
import ReactGA from "react-ga4";
import { googleAnalyticKey } from "../../../env";
ReactGA.initialize(googleAnalyticKey);

class SignupModal extends Component {
  state={
    disableSignUp:false
  }
  SignupValidation = Yup.object().shape({
    phone: Yup.string()
      .min(10, "Phone number is not valid!")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .required("Required"),
    name: Yup.string()
      .min(3, "Too small")
      .required("Full name is required"),
    email: Yup.string()
      .email()
      .required("Email field required")
  });

  analyseGoogleAnalytics = () => {
    ReactGA.ga("send", "pageview", `/login`);
  };

  handleSubmit = async (values, { setSubmitting }) => {
    this.analyseGoogleAnalytics();
    this.setState({disableSignUp:true});
    setTimeout(()=>{ this.setState({disableSignUp:false})},1500);
    await this.props.handleUserSignup(values);
    setSubmitting(false);
  };
  render() {
    return (
      <div
        className="modal fade login-modal"
        id="signup-modal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close myclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="checkout-login-details">
                <p className="modal-head">Signup</p>
                {/* <div className="social-login">
                  <ul>
                    <li>
                      <button className="btn-nothing facebook">Facebook</button>
                    </li>
                    <li>
                      <button className="btn-nothing google">Google</button>
                    </li>
                  </ul>
                  <div className="clearfix" />
                </div> */}
                <Formik
                  validationSchema={this.SignupValidation}
                  initialValues={{ name: "", phone: "", email: "" }}
                  onSubmit={this.handleSubmit}
                >
                  {({ isSubmitting, onSubmit }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <Field
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Full Name"
                        />
                        <p className="text-danger fs-12"><ErrorMessage name="name" /></p>
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Phone Number</label>
                        <Field
                          name="phone"
                          id="phone"
                          type="text"
                          className="form-control"
                          placeholder="10 Digit Phone Number"
                        />
                        <p className="text-danger fs-12"><ErrorMessage name="phone" /></p>
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                          name="email"
                          id="email"
                          type="text"
                          className="form-control"
                          placeholder="Email ID"
                        />
                        <p className="text-danger fs-12"><ErrorMessage name="email" /></p>
                      </div>
                      {/*<div className="form-group">*/}
                      {/*  <label htmlFor="password">Password</label>*/}
                      {/*  <Field*/}
                      {/*      name="password"*/}
                      {/*      id="password"*/}
                      {/*      type="password"*/}
                      {/*      className="form-control"*/}
                      {/*      placeholder="Password"*/}
                      {/*  />*/}
                      {/*  <p className="text-danger fs-12"><ErrorMessage name="password" /></p>*/}
                      {/*</div>*/}
                      {/*<div className="form-group">*/}
                      {/*  <label htmlFor="re-password">Repeat Password</label>*/}
                      {/*  <Field*/}
                      {/*      name="confirm_password"*/}
                      {/*      type="password"*/}
                      {/*      id="re-password"*/}
                      {/*      className="form-control"*/}
                      {/*      placeholder="Repeat Password"*/}
                      {/*  />*/}
                      {/*  <p className="text-danger fs-12"><ErrorMessage name="re-password" /></p>*/}
                      {/*</div>*/}
                      <div className="form-group">
                        <button
                          disabled={isSubmitting || this.state.disableSignUp}
                          className="d-none otp-modal"
                          data-toggle="modal"
                          data-target="#otp-modal"
                        />
                        <button
                          className="btn btn-brand btn-block btn-standard "
                          onClick={onSubmit}
                          disabled={isSubmitting || this.state.disableSignUp}
                          type="submit"
                          aria-label="Close"
                        >
                          Signup
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>

                <p className="noaccount">
                  Already have an account?{" "}
                  <button
                    className="btn-nothing"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#login-modal"
                  >
                    Login
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignupModal;
