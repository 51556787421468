import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
    isArray,
    checkProductExistOnCart,
    checkHourExistInBetweenTime
} from "../../../utils/util";
import { config } from "../../../env";
// import LazyLoad from "react-lazyload";
import { AddToCart } from "../../../_reducers/cartReducer";
import { connect } from "react-redux";

class FoodItemCardList extends Component {
    constructor(props) {
        super(props);
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    handleAddToCart = (product, productDetails) => {
        if (product !== undefined) {
            let cartData = [...this.props.cartData];
            let findExistProduct = cartData.findIndex(
                cart => cart.product_price_id === product.product_price_id
            );
            if (findExistProduct !== -1) {
                cartData[findExistProduct].productCount =
                    cartData[findExistProduct].productCount + 1;
                this.props.addToCart(cartData);
                this.forceUpdate();
            } else {
                product.productCount = 1;
                //adding product details inside the different serving sizes
                product.product_name = productDetails.product_name;
                product.product_desc = productDetails.product_desc;
                product.is_veg = productDetails.is_veg;
                product.category_id = productDetails.category_id;
                let updatedCartData = cartData.concat([product]);
                this.props.addToCart(updatedCartData);
                this.forceUpdate();
            }
        }
        this.forceUpdate();
    };

    handleDecrementProductCount = product => {
        let cartData = [...this.props.cartData];
        let findExistProduct = cartData.findIndex(
            cart => cart.product_price_id === product.product_price_id
        );
        if (findExistProduct !== -1) {
            let productCount = cartData[findExistProduct].productCount;
            if (productCount > 1) {
                cartData[findExistProduct].productCount =
                    cartData[findExistProduct].productCount - 1;
                this.props.addToCart(cartData);
                this.forceUpdate();
            } else {
                cartData = cartData.filter(
                    cart => cart.product_price_id !== product.product_price_id
                );
                this.props.addToCart(cartData);
                this.forceUpdate();
            }
        }
        this.forceUpdate();
    };
    handleSetCategoryAndProductId=(categoryId,productId)=>{
        localStorage.setItem('categoryId',categoryId);
        localStorage.setItem('productId',productId);
    }
    render() {
        const { products, cartData, currentBranchInfo } = this.props;
        return (
            <section>
                <div className="products">
                    <div className="container">
                        <div className="row">
                            {products.length &&
                            products.map(product => {
                                return (
                                    isArray(product.serving_size) && (
                                        <div
                                            key={product.category_id + product.product_id}
                                            className="col-lg-4"
                                        >
                                            {/* <LazyLoad> */}
                                            {product.serving_size.length >= 2 ? (
                                                <NavLink
                                                    className="text-dark"
                                                    onClick={()=>this.handleSetCategoryAndProductId(product.category_id,product.product_id)}
                                                    to={`/products/${(product.product_name)&&product.product_name.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-').split(' ').join('-').toLowerCase()}/`}
                                                >
                                                    <div className="product-item">
                                                        <div className="product-img">
                                                            {config.productImgUrl ? (
                                                                <img
                                                                    src={`${config.productImgUrl}/${product.product_id}.jpg`}
                                                                    alt={product.product_name}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={`${config.urlPrefix}/images/no-picture.png`}
                                                                    alt={product.product_name}
                                                                />
                                                            )}
                                                            {/* <div className="product-label">Bestseller</div> */}
                                                            <div className="product-disc">
                                                                <p>{product.product_desc}</p>
                                                            </div>
                                                        </div>
                                                        <div className="product_details">
                                                            {product.is_veg === "1" ? (
                                                                <h2>
                                                                    <img
                                                                        src={`${config.urlPrefix}/images/veg.svg`}
                                                                        className="mt--2px mr-1"
                                                                        alt="Veg"
                                                                    />
                                                                    {product.product_name}
                                                                    {/* <span className="ml-1"><img src="/images/chilli.svg" height="14" alt="Chilli" />
                                      <img src="/images/chilli.svg" height="14" alt="Chilli" />
                                      <img src="/images/chilli.svg" height="14" alt="Chilli" /></span> */}
                                                                </h2>
                                                            ) : (
                                                                <h2>
                                                                    <img
                                                                        src={`${config.urlPrefix}/images/non_veg.svg`}
                                                                        className="mt--2px mr-1"
                                                                        alt="Non Veg"
                                                                    />
                                                                    {product.product_name}
                                                                    {/* <span className="ml-1"><img src="/images/chilli.svg" height="14" alt="Chilli" />
                                        <img src="/images/chilli.svg" height="14" alt="Chilli" />
                                        <img src="/images/chilli.svg" height="14" alt="Chilli" /></span> */}
                                                                </h2>
                                                            )}
                                                        </div>
                                                        <div className="product_price">
                                                            <div className="product_price_item float-left">
                                                                <p>
                                                                    <strong>
                                                                        {isArray(product.serving_size) &&
                                                                        Math.ceil(
                                                                            product.serving_size[0].product_price
                                                                        )}
                                                                        {/* <sup className="currency">&#8377;</sup> */}
                                                                    </strong>{" "}
                                                                </p>
                                                            </div>
                                                            <div className="add-cart float-right">
                                                                {product.serving_size.length === 1 ? (
                                                                    checkProductExistOnCart(
                                                                        product.serving_size[0],
                                                                        cartData
                                                                    ) !== 0 ? (
                                                                        Number(product.stock_status) !== -1 ? (
                                                                            <p className="btn btn-brand px-0">
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.handleDecrementProductCount(
                                                                                            product.serving_size[0]
                                                                                        )
                                                                                    }
                                                                                    className="btn-nothing minus"
                                                                                >
                                                                                    -
                                                                                </button>
                                                                                <span>
                                            {checkProductExistOnCart(
                                                product.serving_size[0],
                                                cartData
                                            )}
                                          </span>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.handleAddToCart(
                                                                                            product.serving_size[0],
                                                                                            product
                                                                                        )
                                                                                    }
                                                                                    className="btn-nothing plus"
                                                                                >
                                                                                    +
                                                                                </button>
                                                                            </p>
                                                                        ) : (
                                                                            <p className="">
                                                                                <button className="btn-brand btn out-of-stock fw-400 py-1">
                                                                                    Out Of Stock
                                                                                </button>
                                                                            </p>
                                                                        )
                                                                    ) : (
                                                                        <p className="">
                                                                            {Number(product.stock_status) !== -1 ? (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        this.handleAddToCart(
                                                                                            product.serving_size[0],
                                                                                            product
                                                                                        )
                                                                                    }
                                                                                    className="btn btn-brand add-to-cart py-1"
                                                                                >
                                                                                    {checkHourExistInBetweenTime(
                                                                                        currentBranchInfo,
                                                                                        "Single"
                                                                                    )}
                                                                                </button>
                                                                            ) : (
                                                                                <button className="btn-brand btn out-of-stock fw-400 py-1">
                                                                                    Out Of Stock
                                                                                </button>
                                                                            )}
                                                                        </p>
                                                                    )
                                                                ) : (
                                                                    <div className="add-cart float-right">
                                                                        <button
                                                                            className={
                                                                                Number(product.stock_status) === -1
                                                                                    ? "btn btn-brand py-1 out-of-stock"
                                                                                    : "btn btn-brand py-1"
                                                                            }
                                                                        >
                                                                            {Number(product.stock_status) === -1
                                                                                ? "Out Of Stock"
                                                                                : checkHourExistInBetweenTime(
                                                                                    currentBranchInfo,
                                                                                    "Options"
                                                                                )}
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="clearfix" />
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            ) : (
                                                <div className="product-item">
                                                    <NavLink
                                                        className="text-dark"
                                                        onClick={()=>this.handleSetCategoryAndProductId(product.category_id,product.product_id)}
                                                        to={`/products/${(product.product_name)&&product.product_name.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-').split(' ').join('-').toLowerCase()}/`}
                                                    >
                                                        <div className="product-img">
                                                            <img
                                                                src={`${config.productImgUrl}/${product.product_id}.jpg`}
                                                                alt={product.product_name}
                                                            />
                                                            {/* <div className="product-label">Bestseller</div> */}
                                                            <div className="product-disc">
                                                                <p>{product.product_desc}</p>
                                                            </div>
                                                        </div>
                                                        <div className="product_details">
                                                            {product.is_veg === "1" ? (
                                                                <h2>
                                                                    <img
                                                                        src={`${config.urlPrefix}/images/veg.svg`}
                                                                        className="mt--2px mr-1"
                                                                        alt="Veg"
                                                                    />
                                                                    {product.product_name}
                                                                    {/* <span className="ml-1"><img src="/images/chilli.svg" height="14" alt="Chilli" />
                                        <img src="/images/chilli.svg" height="14" alt="Chilli" />
                                        <img src="/images/chilli.svg" height="14" alt="Chilli" /></span> */}
                                                                </h2>
                                                            ) : (
                                                                <h2>
                                                                    <img
                                                                        src={`${config.urlPrefix}/images/non_veg.svg`}
                                                                        className="mt--2px mr-1"
                                                                        alt="Non Veg"
                                                                    />
                                                                    {product.product_name}
                                                                    {/* <span className="ml-1"><img src="/images/chilli.svg" height="14" alt="Chilli" />
                                        <img src="/images/chilli.svg" height="14" alt="Chilli" />
                                        <img src="/images/chilli.svg" height="14" alt="Chilli" /></span> */}
                                                                </h2>
                                                            )}
                                                        </div>
                                                    </NavLink>
                                                    <div className="product_price">
                                                        <div className="product_price_item float-left">
                                                            <p>
                                                                <strong>
                                                                    {isArray(product.serving_size) &&
                                                                    Math.ceil(
                                                                        product.serving_size[0].product_price
                                                                    )}
                                                                    {/* <sup className="currency">&#8377;</sup> */}
                                                                </strong>
                                                            </p>
                                                        </div>
                                                        <div className="add-cart float-right">
                                                            {product.serving_size.length === 1 ? (
                                                                checkProductExistOnCart(
                                                                    product.serving_size[0],
                                                                    cartData
                                                                ) !== 0 ? (
                                                                    Number(product.stock_status) !== -1 ? (
                                                                        <p className="btn btn-brand px-0">
                                                                            <button
                                                                                onClick={() =>
                                                                                    this.handleDecrementProductCount(
                                                                                        product.serving_size[0]
                                                                                    )
                                                                                }
                                                                                className="btn-nothing minus"
                                                                            >
                                                                                -
                                                                            </button>
                                                                            <span>
                                          {checkProductExistOnCart(
                                              product.serving_size[0],
                                              cartData
                                          )}
                                        </span>
                                                                            <button
                                                                                onClick={() =>
                                                                                    this.handleAddToCart(
                                                                                        product.serving_size[0],
                                                                                        product
                                                                                    )
                                                                                }
                                                                                className="btn-nothing plus"
                                                                            >
                                                                                +
                                                                            </button>
                                                                        </p>
                                                                    ) : (
                                                                        <p className="">
                                                                            {" "}
                                                                            <button className="btn-brand btn out-of-stock fw-400 py-1">
                                                                                Out Of Stock
                                                                            </button>
                                                                        </p>
                                                                    )
                                                                ) : (
                                                                    <p className="">
                                                                        {Number(product.stock_status) !== -1 ? (
                                                                            <button
                                                                                onClick={() =>
                                                                                    this.handleAddToCart(
                                                                                        product.serving_size[0],
                                                                                        product
                                                                                    )
                                                                                }
                                                                                className="btn btn-brand add-to-cart py-1"
                                                                            >
                                                                                {checkHourExistInBetweenTime(
                                                                                    currentBranchInfo,
                                                                                    "Single"
                                                                                )}
                                                                            </button>
                                                                        ) : (
                                                                            <button className="btn-brand btn out-of-stock fw-400 py-1">
                                                                                Out Of Stock
                                                                            </button>
                                                                        )}
                                                                    </p>
                                                                )
                                                            ) : (
                                                                <div className="add-cart float-right">
                                                                    <button
                                                                        className={
                                                                            Number(product.stock_status) === -1
                                                                                ? "btn btn-brand py-1 out-of-stock"
                                                                                : "btn btn-brand py-1"
                                                                        }
                                                                    >
                                                                        {Number(product.stock_status) === -1
                                                                            ? "Out Of Stock"
                                                                            : checkHourExistInBetweenTime(
                                                                                currentBranchInfo,
                                                                                "Option"
                                                                            )}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="clearfix" />
                                                    </div>
                                                </div>
                                            )}
                                            {/* </LazyLoad> */}
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        cartData: state.CartReducer.cartProducts
    };
};
const mapDispatchToProps = dispatch => {
    return {
        addToCart: products => dispatch(AddToCart(products))
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FoodItemCardList);
