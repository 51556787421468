import React, { Component } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import { googleAnalyticKey } from "../../../env";

ReactGA.initialize(googleAnalyticKey);

class SignupCheckout extends Component {
  SignupValidation = Yup.object().shape({
    phone: Yup.string()
      .min(10, "Phone number is not valid!")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .required("Required"),
    name: Yup.string()
      .min(5, "Too small")
      .required("Full name is required"),
    email: Yup.string()
      .email()
      .required("Invalid email address")
  });
  handleSubmit = async (values, { setSubmitting }) => {
    this.analyseGoogleAnalytics();
    await this.props.handleUserSignup(values);
    setSubmitting(false);
  };

  analyseGoogleAnalytics = () => {
    ReactGA.ga("send", "pageview", `/signup`);
  };

  render() {
    return (
      <div className="signup-holder d-none">
        <div className="checkout-login-details">
          <p>Phone signup</p>
          <Formik
            validationSchema={this.SignupValidation}
            initialValues={{ name: "", phone: "", email: "" }}
            onSubmit={this.handleSubmit}
          >
            {({ onSubmit, isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <Field
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                  />
                  <p className="text-danger fs-12">
                    <ErrorMessage name="name" />
                  </p>
                </div>
                <div className="form-group">
                  <Field
                    name="phone"
                    type="text"
                    className="form-control"
                    placeholder="10 Digit Phone Number"
                  />
                  <p className="text-danger fs-12">
                    <ErrorMessage name="phone" />
                  </p>
                </div>
                <div className="form-group">
                  <Field
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email ID"
                  />
                  <p className="text-danger fs-12">
                    <ErrorMessage name="email" />
                  </p>
                </div>
                <button
                  disabled={isSubmitting}
                  className="d-none otp-modal"
                  data-toggle="modal"
                  data-target="#otp-modal"
                ></button>
                <div className="form-group">
                  <button
                    onClick={onSubmit}
                    disabled={isSubmitting}
                    className="btn btn-brand btn-block btn-standard w-100"
                  >
                    Signup
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="social-login">
            {/* <p>Social Signup</p> */}
            {/* <ul>
              <li>
                <button className="btn-nothing facebook">Facebook</button>
              </li>
              <li>
                <button className="btn-nothing google">Google</button>
              </li>
            </ul> */}
            <div className="clearfix" />
            <p className="noaccount">
              Already Have an account?{" "}
              <button
                onClick={e => this.props.login()}
                className="btn-nothing checkout-signup-trigger"
              >
                Login
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default SignupCheckout;
