import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import {config} from "../../env";
import {Helmet} from "react-helmet";

const ShippingPolicyPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Shipping Policy - Retro in Metro</title>
                <meta name="description"
                      content="We, the Retro In Metro team, are trying to bring back the golden heritage of a long forgotten
                        natural way of consuming millets in the form of RetroSip® millet malts that blends with our current
                        metro lifestyle. #RetroFeelingInMetroLifestyle"/>
            </Helmet>
            <section className="mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h1 className="title">
                                Shipping policy
                            </h1>
                            <hr/>
                            <p>Free shipping on all orders above Rs. 200 within India.</p>
                            <p>The orders are shipped within 3-4 working days from the date it is received. Shipping details will be shared on the registered mobile number and Email ID (if shared).</p>
                            <p>Cancellation cannot be requested once an order is shipped out from our warehouse.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
            <ModalsPage/>
        </div>
    )
}
export default ShippingPolicyPage;
