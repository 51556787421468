import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import { config } from "../../env";
import { Helmet } from "react-helmet";
const AboutUsPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Live Kitchen - Potful</title>
                <meta name="description" content="Potful provides online biryani delivery and is dedicated to bringing the authentic taste of biryani at your doorstep."/>
            </Helmet>
            <section className="mt-5">
                <div className="category_header mb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="heading"><span>Live Kitchen (11AM to 11PM)</span></h2>
                                <p className="text-center mb-4">
                                How would you know that the food you ordered is safe for you and your family? 

Seeing is believing! 

Now you can watch your favourite food that you ordered being made in our kitchen - LIVE. At Potful, we strive for the highest food safety standards and safety measures to delight our customers, not just with authentic fresh food, but also with the safest food. 

Sit back, relax and relish your Potful of pure love.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <iframe width="100%" height="500px" src="https://www.youtube.com/embed/live_stream?channel=UCkdVvtymWfClCOvoZblHBig"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                                        {/* <iframe width="100%" height="500px" src="https://www.youtube.com/embed/M5DxwNfnOeU"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ModalsPage />
        </div>
    )
}
export default AboutUsPage;
