import React, { Component } from "react";
import RestaurantMenuPage from './restaurant-menu-html';
class RestaurantMenu extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  render() {
    return (
      <RestaurantMenuPage
      />
    );
  }
}
export default RestaurantMenu;
