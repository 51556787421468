import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";

const LucknowiBiryaniPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Order Authentic Lucknowi Biryani</title>
                <meta name="description"
                      content="Order online your choice of authentic Lucknowi Biryani now. Potful delivery in the very same biryani pot in which it is cooked. Lucknowi biryani is more pleasant to the stomach and has a soothing aroma."/>
            </Helmet>
            <section className="mt-5">
                <div className="category_header mb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="heading mb-4"><span>Lucknowi Biryani</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="terms-p">
                <div className="container">
                    <div className="row">
                        <div className="offset-md-1 col-md-10">
                            <p><strong>Awadhi cuisine</strong> is a cuisine native to the city of Lucknow, which is the capital of the state
                            of Uttar Pradesh in Northern India. It is very closely related to Bhojpuri cuisine of it
                            neighbouring region, Bhojpur. The cooking patterns of Lucknow are similar to those of
                            Central Asia, the Middle East, and Northern India with the cuisine comprising both
                            vegetarian and non-vegetarian dishes. The Awadh region has been greatly influenced by Mughal
                            cooking techniques, and the cuisine of Lucknow bears similarities to those of Central Asia,
                                Kashmir, Punjab and Hyderabad. The city is also known for its Nawabi foods.</p>

                            <p><NavLink className="text-dark no-hover" to="/restaurant-menu/claypot-lucknowi-mutton-biryani"><strong>Lucknowi Mutton Biryani</strong></NavLink> is a very popular North Indian recipe. It is different from the
                            basic biryanis as it comes straight from the Nawabs and is the soul of every party. In
                            Lucknow, the last nawab, Wajed Ali Shah was the greatest inspiration behind the spread of
                            Awadhi biryani. Lucknowi biryani uses milder spices. Hyderabad does not use any mitha ittar,
                            whereas Lucknow uses ittar generously in biryani. It also uses cashew nut paste, saffron,
                            curd, star anise and mace powder that give it an amazing flavour. It is made by cooking meat
                            in ghee with warm aromatic spices and then layered with meat curry or marinade, then sealed
                                and cooked over low heat until done.</p>
                            <section>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4">
                                            <NavLink
                                                className="text-dark no-hover"
                                                to="/restaurant-menu/claypot-lucknowi-mutton-biryani/"
                                            >
                                                <div className="product-item">
                                                    <div className="product-img">
                                                        <img
                                                            src={`https://img.restro.app/frontend/images/products/8.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="product_details">
                                                        <h2 className="pl-2 pb-1 text-center">
                                                            Lucknowi Mutton biryani
                                                        </h2>
                                                    </div>
                                                    <div className="product_price px-4 pb-0">
                                                        <div className="product_price_item float-left">
                                                            {/*<p><small className="op-less">BLOG POST</small> </p>*/}
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <NavLink className="text-dark no-hover" to="/restaurant-menu/claypot-lucknowi-chicken-biryani/">
                                                <div className="product-item">
                                                    <div className="product-img">
                                                        <img
                                                            src={`https://img.restro.app/frontend/images/products/7.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="product_details">
                                                        <h2 className="pl-2 pb-1 text-center">Lucknowi Chicken biryani</h2>
                                                    </div>
                                                    <div className="product_price px-4 pb-0">
                                                        <div className="product_price_item float-left">
                                                            {/*<p><small className="op-less">BLOG POST</small> </p>*/}
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        {/*<div className="col-lg-4 mt-4">*/}
                                        {/*<NavLink className="text-dark no-hover" to="/restaurant-menu/claypot-hyderabadi-mix-veg-biryani/">*/}
                                        {/*<div className="product-item">*/}
                                        {/*<div className="product-img">*/}
                                        {/*<img*/}
                                        {/*src={`https://img.restro.app/frontend/images/products/3.jpg`}*/}
                                        {/*alt=""*/}
                                        {/*/>*/}
                                        {/*</div>*/}
                                        {/*<div className="product_details">*/}
                                        {/*<h2 className="pl-2 pb-1 text-center">*/}
                                        {/*Vegetarian Calcutta Biryani*/}
                                        {/*</h2>*/}
                                        {/*</div>*/}
                                        {/*<div className="product_price px-4 pb-0">*/}
                                        {/*<div className="product_price_item float-left">*/}
                                        {/*/!*<p><small className="op-less">BLOG POST</small> </p>*!/*/}
                                        {/*</div>*/}
                                        {/*<div className="clearfix"></div>*/}
                                        {/*</div>*/}
                                        {/*</div>*/}
                                        {/*</NavLink>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </section>

                            <p>For this biryani, the process of marinating is very important. A longer marination process
                            results in enriched taste of the biryani. It is also important to soak the rice in water for
                            sometime before cooking. This ensures that the rice grains turn out well. In Lucknowi
                            biryani, the meat is cooked on slow fire till it is almost done. This biryani can also be
                                made using chicken and fish, but it tastes best with goat meat. The <NavLink className="text-dark no-hover" to="/products/claypot-lucknowi-mix-veg-biryani"><strong>vegetarian version</strong></NavLink> is
                            called tehri. It is a royal dish of the famous Awadh Nawabs and is ideally made on special
                                occasions and festivals.</p>

                            <h2>Pakki Biryani Cooking Method</h2>
                            <p>Lucknowi is a kind of pakki biryani which entails the rice and mutton or chicken to be
                            cooked 80% separately, and then to be cooked together using the dum cooking method. It does
                            not require any green chilli, turmeric, red chilli powder, coriander or mint leaves. It
                            simply requires lots of yogurt and saffron. Saffron is one of the costliest herbs in the
                            world. And in times of the Nawabs, only the Mughal rajas and sultans could afford it. Now,
                            saffron, a luxury product, is among the most expensive spices of Asia. Fortunately, a mere
                            three or four thread would add an unprecedented flavour to your Biryani. Mildly roasted on a
                            pan, then dissolved in milk, it helps to bring a subtle fascinating flavour in the biryani
                            and a unique range of colour which we call 'Kesaria'. Lucknowi biryani also requires a lot
                            of fried onion. Rose and screwpine essence augment the personality and aura of Lucknowi
                            biryani. Super sleek and fine textured basmati rice is the ornament of biryani. Garam masala
                            which include green and black cardamom, mace, nutmeg, cloves and cinnamon also add to its
                            flavour. Cumin and coriander seeds powdered are also there. Large pieces of mutton are used
                            in the biryani. Raw papaya paste is used to marinate the mutton. Typically, around four to
                            five hours of marination is required. Mitha ittar is another characteristic of Lucknowi
                                biryani.</p>
                            <p>The best <NavLink className="text-dark no-hover" to="/products/claypot-lucknowi-mutton-biryani"><strong>Lucknowi biryani</strong></NavLink> can now be enjoyed in the comforts of your house thanks to Potful
                                Biryani. For online home delivery options, check out our <NavLink className="text-dark no-hover" to="/products"><strong>Menu</strong></NavLink></p>
                            {/*<p>*/}
                            {/*<strong>Awadhi cuisine</strong> is a cuisine native to the city of Lucknow, which is the*/}
                            {/*capital of the state of Uttar Pradesh in Northern India. It is very closely related*/}
                            {/*to Bhojpuri cuisine of it*/}
                            {/*neighboring region, Bhojpur.*/}
                            {/*The cooking patterns of Lucknow are similar to those of Central Asia, the Middle East, and Northern India with the cuisine*/}
                            {/*comprising both vegetarian and*/}
                            {/*non-vegetarian dishes. The Awadh region has been greatly influenced*/}
                            {/*by Mughal cooking techniques,*/}
                            {/*and the cuisine of Lucknow bears similarities to those of Central Asia, Kashmir, Punjab and Hyderabad. The city is also*/}
                            {/*known for its Nawabi foods.*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*<br/>*/}
                            {/*Lucknowi mutton biryani is a very popular North Indian recipe. In Lucknow, it is*/}
                            {/*believed that biryani is not just one singular dish, but it is a combination of*/}
                            {/*Biryani, Tari(special gravy which is meant to be*/}
                            {/*eaten with biryani) and Raita. While the Hyderabadi biryani is rich in spices, Lucknowi*/}
                            {/*biryani is more pleasant to the stomach and has a soothing aroma. It is different from*/}
                            {/*the basic biryanis as it comes straight from the Nawabs and is the best biryani for any*/}
                            {/*party. In Lucknow, the last nawab, Wajed Ali Shahwas the greatest inspiration behind the*/}
                            {/*spread of the Awadhi biryani. Lucknowi biryani uses milder spices. It also uses mitha*/}
                            {/*attar, cashewnut paste, saffron, curd, star anise and mace powder generously that gives*/}
                            {/*the biriyani its amazing flavour. It is made by cooking meat/ vegetables in ghee with*/}
                            {/*warm aromatic spices and then layered with meat curry or marinade, then sealed and*/}
                            {/*cooked in a biryani clay pot over low heat until done.*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*At Potful, we follow the authentic recipes from the past and the Lucknowi mutton*/}
                            {/*biryani, Lucknowi chicken biryani and Lucknowi mix veg biryani are cooked on slow fire*/}
                            {/*till it is almost done. For this biryani, the process of marination is very important*/}
                            {/*and results in enriched taste of the dish. The vegetarian version is called tehri. It is a royal dish of the*/}
                            {/*famous Awadh Nawabs and is ideally made on special occasions and festivals.*/}
                            {/*</p>*/}
                            {/*<h6>The Potful Deliciousness</h6>*/}
                            {/*<p>*/}
                            {/*The distinct feature of the Potful Lucknowi biryani is the use of mutton, chicken and*/}
                            {/*vegetables cooked in a dum style which means that gravy and rice are sealed in a biryani*/}
                            {/*clay pot and cooked over slow flame, which makes the meat pieces or*/}
                            {/*vegetables juicy and aromatic. This process gives Potful Lucknowi biryani variations its*/}
                            {/*authentic and distinct taste. Lucknowi is a kind of biryani which entails the rice and*/}
                            {/*mutton, chicken or vegetables to be cooked 80% separately and then to be cooked together*/}
                            {/*using the dum cooking method. Super sleek and fine textured basmati rice is the ornament*/}
                            {/*of Potful Lucknowi biryani. Garam masalas which include various homegrown Indian spices*/}
                            {/*also add to the flavour. Large pieces of mutton or chicken are used in the biryani,*/}
                            {/*which typically requires around four to five hours of marination. Mitha attar is another*/}
                            {/*characteristic of Lucknowi biryani.*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*When it comes to which is the best version of the Potful Lucknowi biryani, we would say*/}
                            {/*Lucknowi mutton biryani is the winner. Goat meat provides for the best combination of*/}
                            {/*the flavours of the Awadhi biryani and this is the all-time favourite among food lovers.*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*Although biryani outlets are a dime a dozen all around Bangalore, places which inculcate*/}
                            {/*the traditional cooking methods and bring you the authentic biryani flavours are rare to*/}
                            {/*find. Potful is dedicated to this task, food delivery and maintains the original cooking*/}
                            {/*styles invented by the Nawabs in early 17<sup>th</sup> Century.*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*Delivered in the very same biryani pot in which it is cooked, this mouth-watering treat*/}
                            {/*is now just a click away for all food lovers in Bangalore. Order online your choice of*/}
                            {/*authentic Potful Lucknowi clay pot biryani now.*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*Served with salan and raita, Potful biryanis needs no more convincing. All delicious*/}
                            {/*Potful biriyani variations can now be ordered and home delivered through our app. Don't*/}
                            {/*forget to use the coupon code 'POTLUC' during check out to avail extra 20% off on all*/}
                            {/*orders!*/}
                            {/*</p>*/}
                            {/*<p>*/}
                            {/*Lucknow at your fingertips! Get link to download the app NOW!*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
            <ModalsPage/>
        </div>
    )
}
export default LucknowiBiryaniPage;
