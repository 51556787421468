 import ReactGA from 'react-ga';
 import ReactPixel from 'react-facebook-pixel';
//  import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//     gtmId: 'GTM-KBMPK7Z'
// }

// TagManager.initialize(tagManagerArgs)
//  import { googleAnalyticKey } from "../env";
//  ReactGA.initialize(googleAnalyticKey);
 /**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
//  export const initGA = (googleAnalyticKey) => {           
//     ReactGA.initialize(googleAnalyticKey); 
//  }
// ReactGA.plugin.require('localHitSender', { path: '/log', debug: true });
 export const Event = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  //   ReactPixel.track('CustomeEvent', {
  //     category: category,
  //     action: action,
  //     label: label
  // });
  };
 
export const MyEvent = (currency, value) => {
  ReactPixel.track('Purchase', {
    currency: currency,
    value: value
  });
};

export const AddFB = () => {
  ReactPixel.track('AddToCart')
}
export const InitiateFB = () => {
  ReactPixel.track('InitiateCheckout')
}
export const ViewContentFB = () => {
  ReactPixel.track('ViewContent')
}
// export const Conversion = (transation_id, value, label) => {
//   const tagManagerArgs = {
//     gtmId: 'GTM-KBMPK7Z',
//     dataLayer: {
//         transation_id: transation_id,
//         value: value,
//         lable: label
//     },
//     dataLayerName: 'PageDataLayer'
// }
// TagManager.dataLayer(tagManagerArgs)
// alert(dataLayerName)
// console.log(transation_id, value, label)
// }
