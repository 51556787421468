import React, { Component, useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';
import { getValue } from '../../utils/lodash';
import {
  orderDetails,
  orderRatingDetails,
  submitFeedback,
} from '../../_services/feedback.service';
import { config } from '../../env';

const Feedback = (props) => {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  const [orderRatingInfo, setOrderRatingInfo] = useState({});
  const [feedback_text, setFeedback_text] = useState('');
  const [orderRequest, setOrderRequest] = useState({
    rating: 0,
    feedback: [],
  });
  const [serviceRequest, setServiceRequest] = useState({
    rating: 0,
    feedback: [],
  });
  const [productRequest, setProductRequest] = useState({});

  const [request, setRequest] = useState({
    order: {
      rating: 5,
      feedback: [1, 2],
    },
    products: [
      {
        product_id: 2,
        rating: 5,
        feedback: [1],
      },
    ],
    service: {
      rating: 5,
      feedback: [1, 2],
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getOrderInfo();
    getOrderRatingInfo();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getOrderInfo = async () => {
    try {
      setIsLoading(true);
      let resp = await orderDetails(getValue(props, `match.params.id`, ''));
      if (resp) {
        setIsLoading(false);
        setOrderInfo(getValue(resp, `data`, {}));
        setProductRequest(
          getValue(resp, `data.bill_products`, []).map((item) => ({
            product_id: item.product_id,
            product_name: item.product_name,
            rating: 0,
            feedback: [],
          }))
        );
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getOrderRatingInfo = async () => {
    try {
      let resp = await orderRatingDetails();
      if (resp) {
        setOrderRatingInfo(getValue(resp, `data`, {}));
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    let payload = {
      order: orderRequest.feedback,
      service: serviceRequest.feedback,
      feedback_text: feedback_text,
      products: productRequest.map((item) => ({
        // rating: item.rating,
        product_id: item.product_id,
        feedback: item.feedback,
      })),
    };
    if (
      getValue(orderRequest, `feedback.length`, 0) > 0 &&
      getValue(serviceRequest, `feedback.length`, 0)
    ) {
      setSubmitLoading(true);
      try {
        let resp = await submitFeedback(
          payload,
          getValue(props, `match.params.id`, '')
        );
        if (getValue(resp, `data.status`, '') === 'success') {
          toast.success('Submitted successfully');
          props.history.push('/feedback-success');
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
          toast.error(getValue(resp, `errors`));
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    } else {
      toast.error('Order and Service Feedback Required');
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const changeRating = (rating, value) => {
    if (value === 'order') {
      setOrderRequest({
        ...orderRequest,
        rating: rating,
        feedback: [
          getValue(orderRatingInfo, `order[${parseInt(rating)}][${0}].id`, ''),
        ],
      });
    } else {
      setServiceRequest({
        ...serviceRequest,
        rating: rating,
        feedback: [
          getValue(
            orderRatingInfo,
            `service[${parseInt(rating)}][${0}].id`,
            ''
          ),
        ],
      });
    }
  };

  const changeProduct = (rating, i) => {
    setProductRequest(
      productRequest.map((x, index) => {
        if (index !== i) return x;
        return {
          ...x,
          rating: rating,
          feedback: [
            getValue(
              orderRatingInfo,
              `product[${parseInt(rating)}][${0}].id`,
              ''
            ),
          ],
        };
      })
    );
  };

  const changeRatingFeedback = (id, status) => {
    if (status === 'order') {
      let array = getValue(orderRequest, `feedback`, []).includes(id);
      if (array) {
        let feedback = getValue(orderRequest, `feedback`, []).filter(
          (item) => item !== id
        );
        setOrderRequest({
          ...orderRequest,
          feedback: feedback,
        });
      } else {
        getValue(orderRequest, `feedback`, []).push(id);
        setOrderRequest({
          ...orderRequest,
        });
      }
    } else {
      let array = getValue(serviceRequest, `feedback`, []).includes(id);
      if (array) {
        let feedback = getValue(serviceRequest, `feedback`, []).filter(
          (item) => item !== id
        );
        setServiceRequest({
          ...serviceRequest,
          feedback: feedback,
        });
      } else {
        getValue(serviceRequest, `feedback`, []).push(id);
        setServiceRequest({
          ...serviceRequest,
        });
      }
    }
  };

  const changeProducteedback = (prodIndex, ratingIndex, feedbackId) => {
    let array = getValue(
      productRequest,
      `[${prodIndex}].feedback`,
      []
    ).includes(feedbackId);
    if (array) {
      let feedbacks = getValue(
        productRequest,
        `[${prodIndex}].feedback`,
        []
      ).filter((item) => item !== feedbackId);

      // console.log(feedbacks);
      setProductRequest(
        productRequest.map((x, index) => {
          if (index !== prodIndex) return x;
          return {
            ...x,
            feedback: feedbacks,
          };
        })
      );
    } else {
      getValue(productRequest, `[${prodIndex}].feedback`, []).push(feedbackId);
      setProductRequest([...productRequest]);
    }
  };
  // console.log(orderRatingInfo);
  //   console.log(orderInfo);
  //   console.log(productRequest);
  //   console.log(getValue(orderRequest, `feedback`, []));
  return (
    <div className="feedback-page">
      <div className="container">
        {isLoading ? (
          <p className="text-center">Please wait....</p>
        ) : (
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="feedback-header">
                <div className="feedback-logo">
                  <img
                    className="img-fluid"
                    src={`${config.urlPrefix}/images/rim/logo.png`}
                    alt=""
                  />
                </div>
                <h4>Rate Your Order</h4>
                <p>
                  Thank you for ordering
                </p>
              </div>
            </div>
            <div className="col-md-6 offset-md-3">
              <div className="card">
                <div className="card-body pt-4 pb-2">
                  <div className="feedback-item">
                    <div>
                      <h5 className="mb-3">How would you rate our Product</h5>
                      <StarRatings
                        rating={getValue(orderRequest, `rating`, 0)}
                        changeRating={(e) => changeRating(e, 'order')}
                        starRatedColor="#f39c12"
                        numberOfStars={5}
                        starDimension="30px"
                        name="rating"
                      />
                      <div className="mb-4 mt-4">
                        {getValue(orderRequest, `rating`, '') !== 0 &&
                          getValue(
                            orderRatingInfo,
                            `order[${getValue(orderRequest, `rating`, 0)}]`,
                            []
                          ).map((item) => {
                            return (
                              <span
                                style={{ cursor: 'pointer' }}
                                className={`badge ${
                                  getValue(
                                    orderRequest,
                                    `feedback`,
                                    []
                                  ).includes(getValue(item, `id`, ''))
                                    ? `badge-success border-success`
                                    : `badge-light border-secondary`
                                } p-2 mr-1`}
                                onClick={() =>
                                  changeRatingFeedback(
                                    getValue(item, `id`, ''),
                                    'order'
                                  )
                                }
                              >
                                {getValue(item, `feedback_text`, '')}
                              </span>
                            );
                          })}
                        <hr />
                      </div>
                    </div>
                    <div>
                      <div className="feedback-item">
                        <h5 className="mb-3">How would you rate our Service</h5>
                        <StarRatings
                          rating={getValue(serviceRequest, `rating`, 0)}
                          starRatedColor="#f39c12"
                          changeRating={(e) => changeRating(e, 'service')}
                          numberOfStars={5}
                          starDimension="30px"
                          name="rating"
                        />
                        <div className="mb-4 mt-4">
                          {getValue(serviceRequest, `rating`, '') !== 0 &&
                            getValue(
                              orderRatingInfo,
                              `service[${getValue(
                                serviceRequest,
                                `rating`,
                                0
                              )}]`,
                              []
                            ).map((item) => {
                              return (
                                <span
                                  style={{ cursor: 'pointer' }}
                                  className={`badge ${
                                    getValue(
                                      serviceRequest,
                                      `feedback`,
                                      []
                                    ).includes(getValue(item, `id`, ''))
                                      ? `badge-success border-success`
                                      : `badge-light border-secondary`
                                  } p-2 mr-1`}
                                  onClick={() =>
                                    changeRatingFeedback(
                                      getValue(item, `id`, ''),
                                      'service'
                                    )
                                  }
                                >
                                  {getValue(item, `feedback_text`, '')}
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="card mt-3">
                  <div className="card-body">
                    <h5 className="mb-3">
                      Rate your food{' '}
                      <span className="opacity-50">- Optional</span>
                    </h5>
                    <hr />
                    {getValue(productRequest, `length`, 0) > 0 &&
                      productRequest.map((item, prodIndex) => {
                        return (
                          <div>
                            <h5 className="mb-3">
                              {getValue(item, `product_name`, '')}
                            </h5>
                            <StarRatings
                              rating={getValue(item, `rating`, 0)}
                              starRatedColor="#f39c12"
                              numberOfStars={5}
                              changeRating={(e) => changeProduct(e, prodIndex)}
                              starDimension="30px"
                              name="rating"
                            />
                            <div className="mb-4 mt-4">
                              {getValue(item, `rating`, 0) !== 0 &&
                                getValue(
                                  orderRatingInfo,
                                  `product[${getValue(item, `rating`, 0)}]`,
                                  []
                                ).map((items, ratingIndex) => {
                                  console.log(items);
                                  return (
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      className={`badge ${
                                        getValue(
                                          productRequest,
                                          `[${prodIndex}].feedback`,
                                          []
                                        ).includes(getValue(items, `id`, ''))
                                          ? `badge-success border-success`
                                          : `badge-light border-secondary`
                                      } p-2 mr-1`}
                                      onClick={() =>
                                        changeProducteedback(
                                          prodIndex,
                                          ratingIndex,
                                          getValue(items, `id`, '')
                                        )
                                      }
                                    >
                                      {getValue(items, `feedback_text`, '')}
                                    </span>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                    <div>
                      <hr />
                      <h5 className="mb-3">
                        What do you think can be improved?
                      </h5>
                      <textarea
                        className="form-control"
                        cols="10"
                        rows="5"
                        placeholder="Your Feedback"
                        value={feedback_text}
                        onChange={(e) => setFeedback_text(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 mb-4 pb-4">
                {submitLoading ? (
                  <button className="btn btn-success">Please wait...</button>
                ) : (
                  <button
                    className="btn btn-warning pt-2 pb-2 pl-3 pr-3 rounded"
                    onClick={handleSubmit}
                  >
                    Submit Feedback
                  </button>
                )}
              </div>
              <div className="slogun">
                <p>The Retro feeling In Metro lifestyle</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Feedback;
