import React from 'react';
import { NavLink } from "react-router-dom";
import { config } from "../../../env";

const profileHeader = (props) => {
  return (
    <header className="header">
      <div className="main_header checkoutcss">
        <div className="container-fluid">
          <div className="header-left">
            <div className="header-logo">
              <NavLink to="/">
                <img src={`${config.urlPrefix}/images/rim/logo.svg`} alt="" />
              </NavLink>
            </div>`
          </div>
          <div className="header-right">
            <button onClick={props.handleLogout} className="btn btn-secondary btn-back">
              Logout
            </button>
          </div>
        </div>
      </div>
    </header >
  )
}

export default profileHeader;
