import React,{Component} from "react";
import {
  isArray,
  checkProductExistOnCart,
  checkHourExistInBetweenTime
} from "../../../utils/util";
import LazyLoad from "react-lazyload";
import {config} from "../../../env";
import { connect } from 'react-redux';
import { AddToCart, SetBranchAddress } from "../../../_reducers/cartReducer";
import { Event, AddFB } from '../../../_actions/trackEvent';

class ProductSize extends Component {
  UNSAFE_componentWillReceiveProps(nextProps){
    this.forceUpdate();
  }
  handleAddToCart = async(product, productDetails) => {
    let cartData = [...this.props.cartData];
    let findExistProduct = cartData.findIndex(
      cart => cart.product_price_id === product.product_price_id
    );
    if (findExistProduct !== -1) {
      cartData[findExistProduct].productCount =
        cartData[findExistProduct].productCount + 1;
      this.props.addToCart(cartData);
      this.forceUpdate();
    } else {
      product.productCount = 1;
      //adding product details inside the different serving sizes
      product.product_name = productDetails.product_name;
      product.product_desc = productDetails.product_desc;
      product.is_veg = productDetails.is_veg;
      product.category_id = productDetails.category_id;
      let updatedCartData = cartData.concat([product]);
     await this.props.addToCart(updatedCartData);
     this.forceUpdate();
    }
    this.forceUpdate();
  };

  handleDecrementProductCount = async product => {
    let cartData = [...this.props.cartData];
    let findExistProduct = cartData.findIndex(
      cart => cart.product_price_id === product.product_price_id
    );
    if (findExistProduct !== -1) {
      let productCount = cartData[findExistProduct].productCount;
      if (productCount > 1) {
        cartData[findExistProduct].productCount =
          cartData[findExistProduct].productCount - 1;
        this.props.addToCart(cartData);
        this.forceUpdate();
      } else {
        cartData = cartData.filter(
          cart => cart.product_price_id !== product.product_price_id
        );
       await this.props.addToCart(cartData);
       this.forceUpdate();
      }
    }
    this.forceUpdate();
  };
  handleIncrementCount=()=>{
    this.setState({count:this.state.count+1})
  }

  render(){
  const { productSizes, productDetails, cartData, currentBranchInfo } = this.props;
  return (
    isArray(productSizes) &&
    productSizes.map((productSize, index) => (
      <React.Fragment
        key={ Math.random()}
      >
        <LazyLoad offset={400}>
          <div className="product-size">
            <div className="row">
              {/*<div className="col-3 col-sm-2">*/}
              {/*  {productSize.serving_size_name === "Medium" ||*/}
              {/*    productSize.serving_size_name === "Regular" ? (*/}
              {/*      <img className="img-fluid" src={productSize.image_url} alt="" />*/}
              {/*    ) : (*/}
              {/*      <img className="img-fluid" src={productSize.image_url} alt="" />*/}
              {/*    )}*/}
              {/*</div>*/}
              <div className="col-3 col-sm-4">
                <p className="mt-2">{productSize.serving_size_name}</p>
                <h2>
                  <strong>
                    {/*&#8377;{Math.ceil(productSize.product_price)} {" "}*/}
                        <strong>
                           {productSize.markup_price > 0 ? (
                               <>
                               <span className="opacity-50">
                                 <s>&#8377;{Math.floor(productSize.markup_price)}</s>
                                 </span>
                                 {" "} &#8377;{Math.floor(productSize.product_price)}
                               </>
                           ) : (
                               <>
                                 &#8377;{Math.floor(productSize.product_price)}
                               </>
                           )}
                        </strong>
                    {productSize.markup_price > 0 ? (
                        <>
                          <div className="badge badge-success">
                            {
                              Math.floor(
                                  ((productSize.markup_price -
                                      productSize.product_price)/productSize.markup_price)*100
                              )
                            }%
                          </div>
                        </>
                    ) : (
                        ""
                    )}
                    {/* <sup className="currency">&#8377;</sup> */}
                  </strong>{" "}
                </h2>
              </div>
              <div className="col-8">
                <div className="text-left">
                  {checkProductExistOnCart(productSize, cartData) >= 1 ? (
                    <p className="btn btn-brand px-0 mt-3">
                      <button
                        onClick={() =>
                         this.handleDecrementProductCount(
                            productSize,
                            productDetails
                          ) + Event("E-Commerce", "Removed from cart", "Removed from cart")
                        }
                        className="btn-nothing minus"
                      >
                        -
                      </button>
                      <span>
                        {checkProductExistOnCart(productSize, cartData)}
                      </span>
                      <button
                        onClick={() =>
                          this.handleAddToCart(productSize, productDetails) + Event("E-Commerce", "Add to cart", "Add to cart") + AddFB()
                        }
                        className="btn-nothing plus"
                      >
                        +
                      </button>
                    </p>
                  ) : (

                    <p className="mt-3">
                      {Number(productDetails.serving_size_status) !== -1 && Number(productDetails.stock_status) !== -1 ? (
                        <button
                          onClick={() =>
                            this.handleAddToCart(productSize, productDetails) +
                            Event("E-Commerce", "Add to cart", "Add to cart") + AddFB()
                          }
                          className="btn btn-brand add-to-cart py-1 fw-400"
                        >
                          {checkHourExistInBetweenTime(
                            currentBranchInfo,
                            "Single"
                          )}
                        </button>
                      ) : (
                        <button className="btn-brand btn out-of-stock fw-400 py-1">
                          Out Of Stock
                        </button>
                          )}
                      </p>
                    )}
                </div>
              </div>

            </div>
          </div>
        </LazyLoad>
      </React.Fragment>
    ))
  );
                      }
};

const mapStateToProps = state => {
  return {
    cartData: state.CartReducer.cartProducts,
    branchId: state.CartReducer.branchId,
    branchCategory: state.CartReducer.branchCategory
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addToCart: products => dispatch(AddToCart(products)),
    setBranchIdAddress: (branchId, address) =>
      dispatch(SetBranchAddress(branchId, address))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSize);
