import React, { Component } from "react";
import LandingPage from "./landing-html";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import { googleAnalyticKey } from '../../env';
ReactGA.initialize(googleAnalyticKey)
class Landing extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  componentDidMount(){
    this.analyseGoogleAnalytics();
  }
  analyseGoogleAnalytics = () => {
    ReactGA.ga(
      "send",
      "pageview",
      `/`
    );
  };
  render() {
    const { branchId, address } = this.props;
    return <LandingPage branchId={branchId} address={address} />;
  }
}

const mapStateToProps = state => {
  return {
    branchId: state.CartReducer.branchId,
    address: state.CartReducer.address
  };
};

export default connect(mapStateToProps)(Landing);
