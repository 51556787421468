import { commonService } from '../_services';   
import { userConstants } from '../_constants' 

export const commonActions = {
    listProducts,
    findLocation,
    listBanner,
};

function listProducts() {
    return dispatch => { 
        commonService.listProducts() 
        .then(            
            menu =>  dispatch(success(menu)),
            error => dispatch(failure(error.toString()))
        );
    };
    function success(menu) { return { type: userConstants.GETMENU_SUCCESS, menu } }
    function failure(error) { return { type: userConstants.GETMENU_FAILURE, error } }
}
function listBanner() {
    return dispatch => { 
        commonService.listBanner() 
        .then(            
            banner =>  dispatch(success(banner)),
            error => dispatch(failure(error.toString()))
        );
    };
    function success(banner) { return { type: userConstants.GETBANNER_SUCCES, banner } }
    function failure(error) { return { type: userConstants.GETBANNER_FAILURE, error } }
}
function findLocation(lat,long){  
    return dispatch => { commonService.findLocation(lat,long) 
        .then(            
            data =>  {
                if(data!=null){
                    dispatch(success(data[0].formatted_address))
                }
            }, 
        );   
    };
    function success(address) { return { type: userConstants.GETADDRESS_SUCCES, address } }
}