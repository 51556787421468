import React,{ Component } from "react";
import {
  isArray,
  checkProductExistOnCart,
  checkHourExistInBetweenTime
} from "../../../utils/util";
import { config } from "../../../env";
import LazyLoad from "react-lazyload";
import { AddToCart, SetBranchAddress } from "../../../_reducers/cartReducer";
import { connect } from 'react-redux';

class SimilarFoodCard extends Component {
  UNSAFE_componentWillReceiveProps(nextProps){
    this.forceUpdate();
  }
  handleAddToCart = async(product, productDetails) => {
    let cartData = [...this.props.cartData];
    let findExistProduct = cartData.findIndex(
      cart => cart.product_price_id === product.product_price_id
    );
    if (findExistProduct !== -1) {
      cartData[findExistProduct].productCount =
        cartData[findExistProduct].productCount + 1;
      this.props.addToCart(cartData);
      this.forceUpdate();
    } else {
      product.productCount = 1;
      //adding product details inside the different serving sizes
      product.product_name = productDetails.product_name;
      product.product_desc = productDetails.product_desc;
      product.is_veg = productDetails.is_veg;
      product.category_id = productDetails.category_id;
      let updatedCartData = cartData.concat([product]);
     await this.props.addToCart(updatedCartData);
     this.forceUpdate();
    }
    this.forceUpdate();
  };

  handleDecrementProductCount = async product => {
    let cartData = [...this.props.cartData];
    let findExistProduct = cartData.findIndex(
      cart => cart.product_price_id === product.product_price_id
    );
    if (findExistProduct !== -1) {
      let productCount = cartData[findExistProduct].productCount;
      if (productCount > 1) {
        cartData[findExistProduct].productCount =
          cartData[findExistProduct].productCount - 1;
        this.props.addToCart(cartData);
        this.forceUpdate();
      } else {
        cartData = cartData.filter(
          cart => cart.product_price_id !== product.product_price_id
        );
       await this.props.addToCart(cartData);
       this.forceUpdate();
      }
    }
    this.forceUpdate();
  };
  handleIncrementCount=()=>{
    this.setState({count:this.state.count+1})
  }
  render(){
  const {
    food,
    cartData,
    // handleAddToCart,
    currentBranchInfo,
    // handleDecrementProductCount
  } = this.props;
  return (
    <div className="col-lg-12">
      <div className="mini-product-item">
        <div className="mini-product-img left-p-img">
          <LazyLoad>
            <img
              src={food.product_image_url}
              alt=""
            />
          </LazyLoad>
        </div>
        <div className="right-p-img">
          <div className="mini-product-info">
            <h2>
              {food.is_veg === "1" ? (
                <img src={`${config.urlPrefix}/images/veg.svg`} className="mt--2px mr-1" alt="" />
              ) : (
                  <img
                    src={`${config.urlPrefix}/images/non_veg.svg`}
                    className="mt--2px mr-1"
                    alt=""
                  />
                )}
              {food.product_name ? food.product_name : ""}
            </h2>
          </div>
          <div className="mini-product-price">
            <div className="product_price">
              <div className="product_price_item float-left">
                <p>
                  {/* <strike>129</strike>{" "} */}
                  <strong>
                    {food &&
                      Math.ceil(food.product_price)}
                    {/* <sup className="currency">&#8377;</sup> */}
                  </strong>{" "}
                  {/* <span className="badge badge-success">15% off</span> */}
                </p>
              </div>
              {food.length <= 1 ? (
                <div className="add-cart float-right">
                  {checkProductExistOnCart(food, cartData) !==
                    0 ? (
                      <p className="btn btn-brand px-0">
                        <button onClick={() =>
                          this.handleDecrementProductCount(
                            food,
                            food
                          )
                        } className="btn-nothing"> Added</button>
                        {/* <button
                            onClick={() =>
                              handleDecrementProductCount(
                                food,
                                food
                              )
                            }
                            className="btn-nothing minus"
                          >
                            -
                      </button>
                          <span>
                            {checkProductExistOnCart(
                              food,
                              cartData
                            )}
                          </span>
                          <button
                            onClick={() =>
                              handleAddToCart(food, food)
                            }
                            className="btn-nothing plus"
                          >
                            +
                      </button> */}
                      </p>
                    ) : (
                      <p className="btn btn-brand">
                        {Number(food.stock_status) !== -1 ? (
                          <button
                            onClick={() =>
                              this.handleAddToCart(food, food)
                            }
                            className="btn-nothing plus"
                          >
                            {checkHourExistInBetweenTime(
                              currentBranchInfo,
                              "Single"
                            )}
                          </button>
                        ) : (
                            <button className="btn-nothing out-of-stock">
                              Out Of Stock
                        </button>
                          )}
                      </p>
                    )}
                </div>
              ) : (
                  <div className="add-cart float-right">
                    {checkProductExistOnCart(food, cartData) !==
                      0 ? (
                        <p className="btn btn-brand px-0">
                          <button onClick={() =>
                            this.handleDecrementProductCount(
                              food,
                              food
                            )
                          } className="btn-nothing"> Added</button>
                          {/* <button
                              onClick={() =>
                                handleDecrementProductCount(
                                  food,
                                  food
                                )
                              }
                              className="btn-nothing minus"
                            >
                              -
                      </button>
                            <span>
                              {checkProductExistOnCart(
                                food,
                                cartData
                              )}
                            </span>
                            <button
                              disabled={
                                Number(food.stock_status) === -1 ? true : false
                              }
                              onClick={() =>
                                handleAddToCart(food, food)
                              }
                              className="btn-nothing plus"
                            >
                              +
                      </button> */}
                        </p>
                      ) : (
                        <p className="btn btn-brand">
                          {Number(food.stock_status) !== -1 ? (
                            <button
                              onClick={() =>
                                this.handleAddToCart(food, food)
                              }
                              className="btn-nothing plus"
                            >
                              {checkHourExistInBetweenTime(
                                currentBranchInfo,
                                "Single"
                              )}
                            </button>
                          ) : (
                              <button className="btn-nothing out-of-stock">
                                Out Of Stock
                        </button>
                            )}
                        </p>
                      )}
                  </div>
                )}
              <div className="clearfix" />
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
                          }
};

const mapStateToProps = state => {
  return {
    cartData: state.CartReducer.cartProducts,
    branchId: state.CartReducer.branchId,
    branchCategory: state.CartReducer.branchCategory
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addToCart: products => dispatch(AddToCart(products)),
    setBranchIdAddress: (branchId, address) =>
      dispatch(SetBranchAddress(branchId, address))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimilarFoodCard);

