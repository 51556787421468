import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../_common/header";
import { isArray } from "../../utils/util";
import {Helmet} from 'react-helmet';
const TrackOrderPage = props => {
  const { totalCartData } = props;
  const showDeliveredInTimeOrNot = totalCartData => {
    if (isArray(totalCartData.bill_status)) {
      const statusArrayLength = totalCartData.bill_status.length - 1;
      const orderStatus =
        totalCartData.bill_status[statusArrayLength].bill_status;
      if (orderStatus == 9 || orderStatus == 10 || orderStatus == 8) {
        return false;
      } else if (orderStatus == 3) {
        return "Expected delivery time";
      } else {
        return "Your order will be delivered in";
      }
    }
  };
  const getOrderStatusMessage = totalCartData => {
    if (isArray(totalCartData.bill_status)) {
      const statusArrayLength = totalCartData.bill_status.length - 1;
      const orderStatus =
        totalCartData.bill_status[statusArrayLength].bill_status;
      const assignedTo =
        totalCartData.bill_status[statusArrayLength].assigned_to;
      if (orderStatus == 6 || orderStatus == 7) {
        return `Your Order is on its way and will be delivered by ${assignedTo.name} ${assignedTo.phone} - Retro in Metro`;
      } else if (orderStatus == 3) {
        return "Thank you for placing the order - Retro in Metro";
      } else if (orderStatus == 4) {
        return "Your order is accepted and is being processed - Retro in Metro";
      } else if (orderStatus == 5) {
        return "your order is ready to ship - Retro in Metro";
      } else if (orderStatus == 9 || orderStatus == 10) {
        return `Sorry, your order has been cancelled - Retro in Metro`;
      } else if (orderStatus == 8) {
        return "Your order is delivered successfully - Retro in Metro";
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>Track your order</title>
        <meta name="description"
              content="We, the Retro In Metro team, are trying to bring back the golden heritage of a long forgotten
          natural way of consuming millets in the form of RetroSip® millet malts that blends with our current metro
          lifestyle. #RetroFeelingInMetroLifestyle"/>
    </Helmet>
      <Header
        page="main"
        headerclassName="main"
        removeLocation="true"
        respcart="false"
        removeRespLocation="d-none"
      />

      <section className="trackorder">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
              <div className="track-holder mb-3">
                <div className="track-details">
                  {showDeliveredInTimeOrNot(totalCartData) && (
                    <div className="order-time">
                      <p>{showDeliveredInTimeOrNot(totalCartData)}</p>
                      <h2>{totalCartData.exp_del_date}</h2>
                    </div>
                  )}
                  <div className="order-status">
                    <p className="text-center">
                      <span className="badge-secondary badge">
                        Order Status
                      </span>
                    </p>
                    <h2>
                      {isArray(totalCartData.bill_status) &&
                      totalCartData.bill_status.length >= 1
                        ? totalCartData.bill_status[
                            totalCartData.bill_status.length - 1
                          ]["bill_status_name"]
                        : totalCartData.bill_status_name &&
                          totalCartData.bill_status_name}
                    </h2>
                    <p>{getOrderStatusMessage(totalCartData)}</p>
                  </div>
                </div>
                <div className="order-highlight">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4>{totalCartData.customer_first_name}</h4>
                      <p>+91 {totalCartData.customer_phone}</p>
                      <p>
                       {totalCartData.customer_address &&
                          totalCartData.customer_address.address_text}{" "} {totalCartData.customer_address &&
                          totalCartData.customer_address.google_address}
                      </p>
                    </div>
                    <div className="col-lg-12">
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <th>
                              <strong>Order #</strong>
                            </th>
                            <td className="text-right">
                              #{totalCartData.bill_id}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <strong>Payment Status</strong>
                            </th>
                            <td className="text-right">
                              {totalCartData.payment_mode_value}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <strong>Order Place Time</strong>
                            </th>
                            <td className="text-right">
                              {totalCartData.date_time}
                            </td>
                          </tr>
                          {/* <tr>
                              <th>
                                  <strong>Order Delivery Time</strong>
                              </th>
                              <td className="text-right">
                                  {totalCartData.exp_del_date}
                              </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="bill-items">
                  <table className="table mb-0">
                    <tbody>
                      <tr>
                        <th>Item</th>
                        <th>Qty</th>
                        <th className="text-right">Price</th>
                      </tr>
                      {isArray(totalCartData.bill_products) &&
                        totalCartData.bill_products.map(data => (
                          <tr key={data.product_price_id}>
                            <td>{data.product_name}</td>
                            <td>{data.qty}x</td>
                            <td className="text-right">
                              {data.product_price * data.qty}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          <strong>Sub-Total</strong>
                        </td>
                        <td />
                        <td className="text-right">
                          {Math.round(totalCartData.bill_subtotal)}
                        </td>
                      </tr>

                      {totalCartData.bill_discount_value > 0 && (
                        <tr>
                          <td>Coupon ({totalCartData.bill_coupon})</td>
                          <td></td>
                          <td className="text-right">
                            - {Math.round(totalCartData.bill_discount_value)}
                          </td>
                        </tr>
                      )}

                      {totalCartData.wallet_amount &&
                        totalCartData.wallet_amount > 0 && (
                          <tr>
                            <td>Credits</td>
                            <td></td>
                            <td className="text-right">
                              - {totalCartData.wallet_amount}
                            </td>
                          </tr>
                        )}
                      <tr>
                        <td>Delivery Charge</td>
                        <td></td>
                        <td className="text-right">
                          {Math.round(totalCartData.delivery_charge_value)}
                        </td>
                      </tr>
                      <tr>
                        <td>Packaging Charge</td>
                        <td></td>
                        <td className="text-right">
                          {Math.round(totalCartData.packing_charge_value)}
                        </td>
                      </tr>

                      {isArray(totalCartData.charges) &&
                        totalCartData.charges.map((charge, index) => (
                          <tr key={index}>
                            <td>{charge.name}</td>
                            <td className="text-right">{Math.round(charge.value)}</td>
                          </tr>
                        ))}
                      {/*<tr>*/}
                      {/*<td>Packing Charge</td>*/}
                      {/*<td />*/}
                      {/*<td className="text-right">*/}
                      {/*{totalCartData.packing_charge_value}*/}
                      {/*</td>*/}
                      {/*</tr>*/}
                      {/*amount: 7.475*/}
                      {/*applied_on: "Food"*/}
                      {/*tax_type_name: "CGST"*/}
                      {/*tax_value: "2.50"*/}
                      {isArray(totalCartData.bill_taxes) &&
                        totalCartData.bill_taxes.map(tax => (
                          <tr>
                            <td>{tax.tax_type_name} ({tax.tax_value})</td>
                            <td></td>
                            <td className="text-right">
                              {Number(tax.amount).toFixed(1)}
                            </td>
                          </tr>
                        ))}

                      <tr>
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td />
                        <td className="text-right">
                          <strong>{Math.round(totalCartData.bill_total)}</strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="mb-3 text-center">
                <NavLink to="/products" className="btn btn-secondary back-tomenu">
                  Back to Menu
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default TrackOrderPage;
