import React from "react";
import {config} from "../../../env";

const profileCard = props => {
  const { userDetail } = props;
  return (
    <div className="profile-brif">
      <div className="profile-pic-holder">
        <div className="profile-pic">
          <img src={`${config.urlPrefix}/images/rim/logo.svg`} alt="" />
        </div>
      </div>
      <p>Name</p>
      <h5>
        {userDetail.customer_first_name && userDetail.customer_first_name}
      </h5>
      <p>Phone</p>
      <h5>+91 {userDetail.customer_phone && userDetail.customer_phone}</h5>
      <p>Email</p>
      <h5>{userDetail.customer_email && userDetail.customer_email}</h5>
{/*
      <p>Potful Credit Balance</p>
      <h5>
        {userDetail.customer_wallet_balance &&
          userDetail.customer_wallet_balance}
      </h5> */}

      {/* <div className="mx-4">
        <button className="btn btn-block py-2" onClick={props.handleLogout}>
          Logout
        </button>
      </div> */}
    </div>
  );
};

export default profileCard;
