import React, { Component } from 'react';
import KolkataBiryaniPage  from './kolkata-biryani-html'; 
class KolkataBiryani extends Component {
    constructor(props){ 
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
        return(
            <KolkataBiryaniPage 
            />
        )
    }
}
export default KolkataBiryani;