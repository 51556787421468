import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router";
import * as ActionMessage from "../../../constants/constant";
import {config} from "../../../env";
import {HiMenuAlt3} from "react-icons/hi";
import {BsFillCartFill} from "react-icons/bs"
import {countTotalItemsOnCart, isArray} from "../../../utils/util";

class TopHeadercard extends Component {
    handleOnLocationClick = () => {
        this.props.history.push("/");
    };

    render() {
        const {
            page,
            categories,
            categoryId,
            isClickScroll
        } = this.props;
        // const page = this.props.page;
        const branchId = this.props.branchId;
        const isLoggedIn = this.props.isLoggedIn;
        const checkoutpageclass = this.props.checkoutpageclass;
        const removeLocation = this.props.removeLocation;
        const currentPage =
            this.props.location.pathname &&
            this.props.location.pathname.split("/")[1];
        const {userName} = this.props;
        return (
            <div className={"main_header " + checkoutpageclass}>
                <div className="container-fluid">
                    <div className="header-left">
                        <div className="header-logo">
                            <NavLink to="/">
                                <img src={`${config.urlPrefix}/images/rim/logo.svg`} alt=""/>
                            </NavLink>
                        </div>
                        {page === "details" || removeLocation === "true" ? (
                            ""
                        ) : (
                            <div className="header-location d-none d-md-inline-block">
                                <div className="locality">
                                    <p>
                                        <span>The Retro feeling In Metro lifestyle</span>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="header-right">
                        <ul className="links">
                            <li>

                            </li>
                            {page === "details" ? (
                                <>
                                </>
                            ) : (
                                <>
                                    <li className="d-none d-sm-inline-block">
                                        {isLoggedIn ? (
                                            <button
                                                type="button"
                                                className="btn-nothing"
                                                onClick={this.props.handleProfileClick}
                                            >
                                                <span>{userName}</span>
                                            </button>
                                        ) : (
                                            <button
                                                className="btn-nothing"
                                                type="button"
                                                data-toggle="modal"
                                                data-target="#login-modal"
                                            >
                                                Login
                                            </button>
                                        )}
                                    </li>
                                </>
                            )}

                            {page === "products" || page === "details" ? (
                                <>
                                </>
                            ) : (
                                <li className="shoplink">
                                    <a href="/products">
                                      <span className="shop-text">
                                        Shop
                                      </span>
                                    </a>
                                </li>
                            )}


                            <li className="cart">
                                <button
                                    onClick={e => this.props.openCart(e)}
                                    className="btn-nothing"
                                >
                                    <BsFillCartFill size={24}/>
                                    <span className="cart-number">
                                        {isArray(this.props.cardData)
                                            ? countTotalItemsOnCart(this.props.cardData)
                                            : 0}
                                    </span>
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={e => this.props.opeMenu()}
                                    className="d-inline-block btn-nothing menu cursor-pointer"
                                    id="menu"
                                >
                                    <HiMenuAlt3 size={20}/>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="clearfix"/>
                </div>
            </div>
        );
    }
}

export default withRouter(TopHeadercard);
