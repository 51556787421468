import React, { Component } from "react";
import ProfilePage from "./profile-html";
import $ from "jquery";
import { connect } from "react-redux";
import { checkoutService } from "../../_services/checkout.service";
import { isArray } from "../../utils/util";
import { toast } from "react-toastify";
import { logout } from '../../_reducers/loginReducer';
import { homeService } from "../../_services";
import * as messages from '../../constants/constant';
import ReactGA from "react-ga4";
import { googleAnalyticKey } from '../../env';
ReactGA.initialize(googleAnalyticKey)
class Profile extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      addressId: "",
      addressList: [],
      userDetail: {},
      totalCartData:{},
      isWalletCRLoading:true,
      walletHistory:[],
      matchSchedule:[],
      team_name:{},
      selectedTeam: {}
    };
  }

  componentDidMount() {
    if(this.props.accessToken){
      this.getUserAddress();
      this.fetchUserDetail();
      this.getAllOrders();
      this.getMatchSchedule();
      this.getSelectedTeam()
    }else{
      this.props.logout();
      this.props.history.push('/');
    }
    this.analyseGoogleAnalytics();
  }
  analyseGoogleAnalytics = () => {
    ReactGA.ga(
      "send",
      "pageview",
      `/user_profile`
    );
  };
  getAllOrders=async()=>{
    let response = await checkoutService.getAllOrders();
    if(response.error === '' && isArray(response.data)){
        this.setState({orderHistory:response.data});
    }else{
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
      this.setState({orderHistory:[]})
    }
  }

  fetchUserDetail = async () => {
    let response = await checkoutService.fetchUserDetail();
    if (response.data && response.error === "") {
      this.setState({ userDetail: response.data });
    } else {
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
      await this.props.logout();
      this.props.history.push('/');
    }
  };
  getUserAddress = async () => {
    let payload = { branch_id: this.props.branchId };
    let response = await checkoutService.fetchMyAddress(payload);
    if (response.error === "" && isArray(response.data)) {
      this.setState({
        addressList: response.data
      });
    }
  };

  handleSelectAddress = addressId => {
    this.setState({ addressId: addressId });
  };

  handleDeleteAddress = async(addressId)=>{
    ReactGA.ga(
      "send",
      "pageview",
      `/delete_address`
    );
    let response = await homeService.deleteUserAddress(addressId);
    if(response.error === "" && response.data.status===1){
      this.getUserAddress();
      toast.error(messages.ADDRESS_DELETED);
    }else{
       toast.error(response.errors);
    }
  }

  handleLogout = ()=>{
    ReactGA.ga(
      "send",
      "pageview",
      `/logout`
    );
    this.props.logout();
    this.props.history.push('/');
  }

  handleOrderDetails = async (orderId) => {
    let response = await checkoutService.getOrderDetails(orderId);
    if (response.error === "" && response.data.bill_id) {
      this.setState({ totalCartData: response.data });
    } else {
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
    }
  };

  getAllWalletCreditDebit = async()=>{
    this.setState({isWalletCRLoading:true})
    let response = await checkoutService.getAllWalletCreditDebit();
    if (response.error === "" && Number(response.data.iTotalRecords)>=0) {
      this.setState({ walletHistory: response.data.aaData,isWalletCRLoading:false });
    }else{
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
      this.setState({isWalletCRLoading:false,walletHistory:[]})
    }
  }
  getSelectedTeam = async()=>{
    let response = await checkoutService.getSelectedTeam();
    if (response.error === "" && response.data) {
      this.setState({ selectedTeam: response.data});
    }else{
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
    }
  }
   getMatchSchedule = async()=>{
    this.setState({matchSchedule:[]})
    let response = await checkoutService.getMatchSchedule();
    if (response.error === "" && isArray(response.data)) {
      this.setState({ matchSchedule: response.data});
    }else{
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
      this.setState({matchSchedule:[]})
    }
  }

  opeMenu() {
    $(".resp-nav").removeClass("away");
  }
  closeMenu() {
    $(".resp-nav").addClass("away");
  }
  order() {
    $(".profile-order-trigger").addClass("active");
    $(".profile-address-trigger").removeClass("active");
    $(".profile-details").removeClass("d-none");
    $(".address-display").addClass("d-none");
  }
  address() {
    $(".profile-order-trigger").removeClass("active");
    $(".profile-address-trigger").addClass("active");
    $(".profile-details").addClass("d-none");
    $(".address-display").removeClass("d-none");
  }
  render() {
    return (
      <ProfilePage
        {...this.state}
        opeMenu={e => this.opeMenu()}
        closeMenu={e => this.closeMenu()}
        order={e => this.order()}
        address={e => this.address()}
        handleSelectAddress={this.handleSelectAddress}
        handleDeleteAddress={this.handleDeleteAddress}
        handleOrderDetails={this.handleOrderDetails}
        handleLogout={this.handleLogout}
        getAllWalletCreditDebit={this.getAllWalletCreditDebit}
        getMatchSchedule={this.getMatchSchedule}
        selectedTeam={this.state.selectedTeam}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    branchId: state.CartReducer.branchId,
    address: state.CartReducer.address,
    isLoggedIn: state.LoginReducer.isLoggedIn,
    accessToken:state.LoginReducer.accessToken
  };
};

const mapDispatchToProps=dispatch=>{
  return{
    logout: data => dispatch(logout(data))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Profile);
