import React, { Component } from 'react';
import { homeService } from '../../_services';
import { config } from '../../env';
import { isArray } from '../../utils/util';
import Loader from '../_common/loader';
import LazyLoad from 'react-lazyload';
import {NavLink} from "react-router-dom";
import ReactModal from "react-modal";
class Banner extends Component {
    constructor () {
        super();
        this.state = {
            showModal: false,
            showModalTwo: false,
            coupons: [],
            isLoading:true
        };

        this.handleOpenModalTwo = this.handleOpenModalTwo.bind(this);
        this.handleCloseModalTwo = this.handleCloseModalTwo.bind(this);
    }
    state = {
        banners: [],
        isLoading:true
    }
    componentDidMount() {
        this.getCouponList();
        this.getBanners();
    }
    getCouponList = async () => {
        this.setState({isLoading:true});
        let response = await homeService.getCouponList(this.props.branchId?this.props.branchId: 3);
        // console.log(response.data, 'cpup')
        if (response) {
            this.setState({ coupons: response.data,isLoading:false });
        }else{
            this.setState({isLoading:false});
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.branchId !==this.props.branchId) {
            this.getOnPropsChangeBanners(nextProps.branchId);
        } else if (nextProps.branchId === "") {
            this.getBanners();
        }
    }
    getBanners = async () => {
        this.setState({isLoading:true});
        let response = await homeService.getBanners(this.props.branchId?this.props.branchId: 3);
        if (response.data) {
            this.setState({ banners: response.data,isLoading:false });
        }else{
            this.setState({isLoading:false});
        }
    }
    getOnPropsChangeBanners = async (branchId) => {
        this.setState({isLoading:true});
        let response = await homeService.getBanners(branchId||3);
        if (response.data) {
            this.setState({ banners: response.data,isLoading:false });
        }else{
            this.setState({isLoading:false});
        }
    }
    handleOpenModalTwo () {
        this.setState({ showModalTwo: true });
    }
    handleCloseModalTwo () {
        this.setState({ showModalTwo: false });
    }
    render() {
        // const {banners} = this.state;
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 padding-0">
                            <div className="gif-holder">
                                <div className="gif-slider">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 padding-0">
                            <div className="banner-highlight ">
                                <p className="mb-0 text-center">Get free shipping on all orders above Rs. 200</p>
                            </div>
                        </div>
                    </div>
                </div>

            {/*<section className="slider">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-12">*/}
            {/*                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">*/}
            {/*                    <div className="carousel-inner">*/}
            {/*                        /!*<div key="1" className="carousel-item active" id="1">*!/*/}
            {/*                        /!*        <img className="d-block w-100 border-bottom-radius" src="/images/new-banners/holi-web.jpg"*!/*/}
            {/*                        /!*             alt="First slide" />*!/*/}
            {/*                        /!*</div>*!/*/}
            {/*                        <div key="2" className="carousel-item active" id="2">*/}
            {/*                            <NavLink to="/categories/claypot-biryanis">*/}
            {/*                            <img className="d-block w-100 border-bottom-radius" src="/images/new-banners/new1.jpg"*/}
            {/*                                 alt="First slide" />*/}
            {/*                            </NavLink>*/}
            {/*                        </div>*/}
            {/*                        <div key="3" className="carousel-item" id="3">*/}
            {/*                            <NavLink to="/categories/claypot-biryanis">*/}
            {/*                            <img className="d-block w-100 border-bottom-radius" src="/images/new-banners/new2.jpg"*/}
            {/*                                 alt="First slide" />*/}
            {/*                            </NavLink>*/}
            {/*                        </div>*/}
            {/*                        <div key="4" className="carousel-item" id="4">*/}
            {/*                            <NavLink to="/categories/claypot-biryanis">*/}
            {/*                            <img className="d-block w-100 border-bottom-radius" src="/images/new-banners/new3.jpg"*/}
            {/*                                 alt="First slide" />*/}
            {/*                            </NavLink>*/}
            {/*                        </div>*/}
            {/*                        <div key="5" className="carousel-item" id="5">*/}
            {/*                            <NavLink to="/categories/royal-feast-combo">*/}
            {/*                            <img className="d-block w-100 border-bottom-radius" src="/images/new-banners/new4.jpg"*/}
            {/*                                 alt="First slide" />*/}
            {/*                            </NavLink>*/}
            {/*                        </div>*/}
            {/*                        <div key="6" className="carousel-item" id="6">*/}
            {/*                            <NavLink to="/categories/claypot-biryanis">*/}
            {/*                            <img className="d-block w-100 border-bottom-radius"*/}
            {/*                                 src="/images/new-banners/new5.jpg"*/}
            {/*                                 alt="First slide" />*/}
            {/*                            </NavLink>*/}
            {/*                        </div>*/}
            {/*                        <div key="7" className="carousel-item" id="7">*/}
            {/*                            <NavLink to="/categories/claypot-biryanis">*/}
            {/*                            <img*/}
            {/*                                // onClick={this.handleOpenModalTwo}*/}
            {/*                                 className="d-block w-100 border-bottom-radius cursor-pointer"*/}
            {/*                                 src="/images/new-banners/new6.jpg"*/}
            {/*                                 alt="First slide" />*/}
            {/*                            </NavLink>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">*/}
            {/*                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>*/}
            {/*                        <span className="sr-only">Previous</span>*/}
            {/*                    </a>*/}
            {/*                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">*/}
            {/*                        <span className="carousel-control-next-icon" aria-hidden="true"></span>*/}
            {/*                        <span className="sr-only">Next</span>*/}
            {/*                    </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*    <ReactModal*/}
            {/*        isOpen={this.state.showModalTwo}*/}
            {/*        contentLabel="Minimal Modal Example"*/}
            {/*        className="pre-modal coupon-pop"*/}
            {/*        id="three"*/}
            {/*    >*/}
            {/*        <div className="available-coupons">*/}
            {/*            <h5 className="coupon-heading">Coupons</h5>*/}
            {/*            <p className="coupon-description">Use below coupons while checking out to avail discounts</p>*/}
            {/*            <ul className="nav">*/}
            {/*                {this.state.coupons.map((item) => (*/}
            {/*                    <li>*/}
            {/*                        <h2 className="text-uppercase">{item.coupon_text}</h2>*/}
            {/*                        <p>{item.description}</p>*/}
            {/*                    </li>*/}
            {/*                ))*/}
            {/*                }*/}
            {/*            </ul>*/}

            {/*        </div>*/}
            {/*        <button onClick={this.handleCloseModalTwo} className="awesome btn btn-warning mt-2 mid">Close</button>*/}
            {/*    </ReactModal>*/}
            {/*    <section className="slider">*/}
            {/*        <div className="container">*/}
            {/*            <div className="row">*/}
            {/*                <div className="col-lg-12">*/}
            {/*                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">*/}
            {/*                        <div className="carousel-inner">*/}
            {/*                            <div key="1" className="carousel-item active" id="1">*/}
            {/*                                <img className="d-block w-100 border-bottom-radius" src="/images/teams/new2.jpg"*/}
            {/*                                     alt="First slide" />*/}
            {/*                            </div>*/}
            {/*                            <div key="1" className="carousel-item" id="1">*/}
            {/*                                <img className="d-block w-100 border-bottom-radius" src="/images/teams/new1.jpg"*/}
            {/*                                     alt="First slide" />*/}
            {/*                            </div>*/}
            {/*                            <div key="1" className="carousel-item " id="1">*/}
            {/*                                <img className="d-block w-100 border-bottom-radius" src="/images/teams/pbl-banner-2.jpg"*/}
            {/*                                     alt="First slide" />*/}
            {/*                            </div>*/}
            {/*                            <div key="1" className="carousel-item " id="1">*/}
            {/*                                <img className="d-block w-100 border-bottom-radius" src="/images/teams/pbl-banner-4.jpg"*/}
            {/*                                     alt="First slide" />*/}
            {/*                            </div>*/}

            {/*                        </div>*/}
            {/*                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">*/}
            {/*                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>*/}
            {/*                            <span className="sr-only">Previous</span>*/}
            {/*                        </a>*/}
            {/*                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">*/}
            {/*                            <span className="carousel-control-next-icon" aria-hidden="true"></span>*/}
            {/*                            <span className="sr-only">Next</span>*/}
            {/*                        </a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </section>*/}
            </div>
        )
    }
}
export default Banner;
