const baseUrl = 'https://api-001.restro.app';
const imgUrl = 'https://img.restro.app';

// development
// const baseUrl = 'https://api.restro360.com';
// const streamURL = 'https://stream.restro360.com'


export const config = {
    apiUrl:baseUrl,
    imageUrl:`${imgUrl}/frontend/images/categories`,
     // streamURL: 'https://stream.restro.app/customers',
     streamURL: 'https://stream.restro360.com/customers',

    // productImgUrl:`${baseUrl}/frontend/images/products`,
    productImgUrl:`${imgUrl}/frontend/images/products`,
    // bannerImages:`${baseUrl}/frontend/images/banners`,
    bannerImages:`${imgUrl}/frontend/images/banners`,
    serveImgURL:`${imgUrl}/frontend/images/`,
    // urlPrefix:'/websites/potful'
    urlPrefix: ''
}
// with keys
export const googleAnalyticKey="G-EESH3P42KC";
export const googleTagKeg="";
export const facebookPixalKey="";

// export const googleTagKeg="GTM-PBXCCPP";
// export const googleAnalyticKey="UA-52609510-1"; // test aponomy
// export const facebookPixalKey="295053228364379";

export const map_key = 'AIzaSyBWZ8pckCiE-Qp5ka0FRveEGI8l8vfIt2E'

// ${config.apiUrl}
