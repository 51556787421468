import React from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import { googleAnalyticKey } from "../../../env";

ReactGA.initialize(googleAnalyticKey);

class OtpVerifyModal extends React.Component {
  OtpSchema = Yup.object().shape({
    otp: Yup.string()
      .min(6, "Please enter 6 Digit OTP")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Please enter 6 Digit OTP"
      )
      .required("Please enter 6 Digit OTP")
  });
  handleSubmit = (values, { setSubmitting }) => {
    this.analyseGoogleAnalytics();
    this.props.handleOtpVerification(values);
    setSubmitting(false);
  };

  analyseGoogleAnalytics = () => {
    ReactGA.ga("send", "pageview", `/verify_otp`);
  };

  render() {
    return (
      <div
        className="modal fade login-modal"
        id="otp-modal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close myclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="checkout-login-details">
                <p className="modal-head">Verify Phone</p>
                <Formik
                  validationSchema={this.OtpSchema}
                  initialValues={{ otp: "" }}
                  onSubmit={this.handleSubmit}
                >
                  {({ isSubmitting, onSubmit }) => (
                    <Form>
                      <div className="form-group">
                        <Field
                          name="otp"
                          type="text"
                          className="form-control"
                          placeholder="Enter 6 Digit OTP"
                        />
                        <p className="text-danger fs-12"><ErrorMessage name="otp" /></p>
                      </div>
                      <div className="form-group">
                        <button
                          disabled={isSubmitting}
                          onClick={onSubmit}
                          className="btn btn-brand btn-block btn-standard"
                        >
                          Verify
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OtpVerifyModal;
