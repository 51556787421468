export const LOGIN_MESSAGE = "Login successful";
export const OTP_SENT = "OTP sent";
export const ADD_ADDRESS = "Address added";
export const SELECT_ADDRESS = "Please select delivery address";
export const ORDER_SUCCESSFUL = "Order placed";
export const SELECT_OTHER_BRNCH = "Please select other address";
export const WRONG_COUPON_CODE = "Invalid coupon code";
export const COUPON_APPLIED = "Coupon applied";
export const BRANCH_CLOSED = "Oops! We are not serving right now! But you can pre-order for later. ";
export const CHANGE_LOCATION = "Change Location";
export const ADDRESS_DELETED = "Address deleted successfully";
export const PAYMENT_FAIL = "Payment failed please try again";
export const PAYMENT_VERIFICATION = "Please do not refresh the page and wait while we are processing your payment";