import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { isArray } from "../../../utils/util";
import { Event } from '../../../_actions/trackEvent';
class ResponsiveMenu extends Component {

    render() {
        const { userName } = this.props;
        const { branchCategory, branchId, isLoggedIn } = this.props;
        const branchPhoneNumber = isArray(branchCategory) && branchCategory.find((branch) => branch.branch_id === branchId);
        return (
            <aside className="resp-nav navigation away" onClick={e => this.props.closeMenu()}>
                <div className="nav-holder">
                <p className="menu-header mb-0">
                    <span>Navigation</span>
                    <button onClick={e => this.props.closeMenu()} className="btn-nothing goaway float-right">
                        <i className="fa fa-times-circle"></i> Close
                  </button>
                    <b className="clearfix"></b>
                </p>
                <button className="btn-nothing change-location-menu nav-away" data-toggle="modal" data-target="#location-modal">
                    Change Location
          </button>
                <ul className="menu-nav">
                    {isLoggedIn ? (
                        <li className="login-in-navigation cursor-pointer">
                        <a
                            onClick={this.props.handleProfileClick}
                        >
                            {/*<img src={`${config.urlPrefix}/images/account.svg`} alt="User" />{" "}*/}
                            <span><i className="fa fa-user"></i> {userName}</span>
                        </a>
                        </li>
                    ) : (
                        <li className="login-in-navigation text-success cursor-pointer">
                        <a
                            data-toggle="modal"
                            data-target="#login-modal"
                        >
                            {/*<img src={`${config.urlPrefix}/images/account.svg`} alt="User" />{" "} */}
                            <span><i className="fa fa-user"></i> Login</span>
                        </a>
                        </li>
                    )}
                    <li><NavLink to="/"><i className="fa fa-home"></i> Home</NavLink></li>
                    {isLoggedIn &&
                        <li><NavLink to="/user_profile"><i className="fa fa-user-circle"></i> Profile</NavLink></li>
                    }
                    {/* <li><NavLink to="/offers"><i className="fa fa-gift"></i> Offers</NavLink></li> */}
                    <li><NavLink to="/about-us"><i className="fa fa-building"></i> About Us</NavLink></li>
                    <li><NavLink to="/terms"><i className="fa fa-pen-nib"></i> Terms and Conditions</NavLink></li>
                    {/* <li><NavLink to="/iplterms"><i className="fa fa-pen-nib"></i> PPL T20 Terms and Conditions</NavLink></li> */}
                    <li><NavLink to="/privacy"><i className="fa fa-user-secret"></i> Privacy Policy</NavLink></li>
                    <li><NavLink to="/refund-policy"><i className="fa fa-user-secret"></i> Refund Policy</NavLink></li>
                    <li><NavLink to="/shipping-policy"><i className="fa fa-user-secret"></i> Shipping Policy</NavLink></li>
                    {/*<li><NavLink to="/media"><i className="fa fa-user-secret"></i> Media</NavLink></li>*/}
                </ul>
                <div className="company-details">
                    <p className="mt-4 mb-0">Sales and Support</p>
                    {/*<a href={`tel:${branchPhoneNumber && branchPhoneNumber.branch_phone || "080-47095454"}`} className="phone"*/}
                    {/*onClick={()=> Event("Explore", branchPhoneNumber && branchPhoneNumber.branch_phone + " Phone Tapped", "Phone Tapped")}*/}
                    {/*>{branchPhoneNumber && branchPhoneNumber.branch_phone}</a>*/}
                    <a href={`tel:${branchPhoneNumber && branchPhoneNumber.branch_phone || "+91 81233 63876"}`} className="phone"
                    >+91 81233 63876</a>
                    <a href="mailto:care@retroinmetro.com?Subject=Customer%20Voice" className="email"
                    onClick={()=> Event("Explore", "Email Tapped", "Email Tapped")}
                    >care@retroinmetro.com</a>
                </div>
                {/*{!isLoggedIn &&*/}
                {/*    <div className="login-menu">*/}
                {/*        <button className="btn-nothing nav-away" data-toggle="modal" data-target="#login-modal">Login | Signup</button>*/}
                {/*    </div>*/}
                {/*}*/}
                </div>
            </aside>
        )
    }
}
const mapStateToProps = state => {
    return {
        cartData: state.CartReducer.cartProducts,
        branchId: state.CartReducer.branchId,
        address: state.CartReducer.address,
        isLoggedIn: state.LoginReducer.isLoggedIn,
        branchCategory: state.CartReducer.branchCategory
    };
};
export default connect(
    mapStateToProps)(ResponsiveMenu)
