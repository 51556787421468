import React from 'react';
import { isArray } from '../../../utils/util'
import Loader from '../../_common/loader';
const viewWalletTransaction = (props)=>{
  const {
    walletHistory,
    isWalletCRLoading
  } = props;
    return(
        <div
        className="modal fade"
        id="TransactionModal"
        role="dialog"
        aria-labelledby="TransactionModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            {isWalletCRLoading?<Loader />:
            <div className="modal-body p-4">
              <button
                type="button"
                className="close myclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="transaction">
                <p className="modal-head mt-1 border-bottom pb-3">Credit History</p>
                <div className="row mb-2">
                  <div className="col-md-8">
                    <h6 className="text-uppercase">Credit Brake UP</h6>
                  </div>
                  <div className="col-md-4 text-right">
                    <h6 className="text-uppercase"></h6>
                  </div>
                </div>
                <div className="modal-scroll">
                  {isArray(walletHistory) ? walletHistory.map((wallet)=>
                  <div className="row mb-1">
                    <div className="col-md-8">
                      <p className="mb-1"><b>Credits {Number(wallet.transaction_type)==1 && 'Added' || 'Used'}</b></p>
                      <p className="">
                      {wallet.transaction_date}</p>
                    </div>
                    <div className="col-md-4 text-right">
                      <p className="pt-2">{Number(wallet.transaction_type)==1?'+':"-"}{" "} {wallet.credits}</p>
                    </div>
                  </div>
                  ):
                  <div>No Record Found.</div>}
                  {/* <div className="row mb-1">
                    <div className="col-md-8">
                      <p className="mb-1"><b>#345678</b></p>
                      <p className="">8/09/2019</p>
                    </div>
                    <div className="col-md-4 text-right">
                      <p className="green pt-2">+30</p>
                    </div>
                  </div> */}
                </div>
                <div className="text-right mt-3">
                  <button className="btn" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    )
}

export default viewWalletTransaction;