import React, { Component } from 'react';
import RefundPolicyPage  from './refund-policy-html';
class RefundPolicy extends Component {
    constructor(props){
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
        return(
            <RefundPolicyPage
            />
        )
    }
}
export default RefundPolicy;
