import React from "react";
import { isArray } from "../../../utils/util";

const profileAddress = props => {
  // const { addressList, addressId } = props;
  const { addressList } = props;
  return (
    <div className="address-display d-none">
      <p className="subheader">Address</p>
      <div className="row">
        {isArray(addressList) &&
          addressList.map(address => (
            <div key={address.address_id} className="col-lg-12">
              <div className="address-item">
                <label for="1">
                  <h3>
                    <span className="float-left">
                      <i className="fa fa-home" />{" "}
                      {Number(address.address_type) === 1
                        ? "Home"
                        : Number(address.address_type) === 2
                          ? "Office"
                          : "Other"}
                    </span>{" "}
                    {/* <span className="float-right">
                      <input
                        onChange={() =>
                          props.handleSelectAddress(address.address_id)
                        }
                        checked={Number(addressId) === Number(address.address_id) ? true : false}
                        type="radio"
                        name="address"
                        value={address.address_id}
                        id="1"
                      />
                    </span> */}
                    <b className="clearfix" />
                  </h3>
                  <p>{address.address_text}</p>
                  <div className="text-left mt-3">
                    <button
                      onClick={()=>props.handleDeleteAddress(address.address_id)}
                      className="btn  btn-outline-danger"
                    >
                      Delete
                  </button>
                  </div>
                </label>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default profileAddress;
