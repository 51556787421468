
import { headerHttp, http } from '../_helpers';

export const orderDetails=(id)=> {
    return headerHttp.get(`/customers/getMyBill/${id}`)
    .then(data => {
        return data;
    });
}

export const orderRatingDetails=()=> {
    return headerHttp.get(`/feedback/fetchAvailableFeedback`)
    .then(data => {
        return data;
    });
}

export const submitFeedback=(payload,id)=> {
    return headerHttp.post(`/feedback/submitCustomerFeedback/${id}`,payload)
    .then(data => {
        return data;
    });
}

export const checkFeedback=()=> {
    return headerHttp.post(`/customers/checkIfFeedBackProvided`)
    .then(data => {
        return data;
    });
}


