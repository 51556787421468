import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isArray } from "../../utils/util";
import { config } from "../../env";
import LazyLoad from "react-lazyload";
import { homeService } from "../../_services/index";
import { Event } from '../../_actions/trackEvent';
import ReactGA from 'react-ga';
class Footer extends React.Component {
  state = {
    categories: []
  };
  componentDidMount() {
    if (!(this.props.categories && this.props.categories.length)) {
      this.getBranchCategory();
    }
  }

  getBranchCategory = async () => {
    let branchId = this.props.branchId;
    if (branchId) {
      let response = await homeService.getBranchLisById(branchId);
      if (response.data && response.data.length) {
        this.setState({ categories: response.data });
      } else {
        this.setState({ categories: [] });
      }
    } else {
      this.setState({ isClickScroll: false });
    }
  };
  handleChange = address => {
    this.setState({ address });
  };

  handleOnclickCategory = productName => {
    productName =
      productName &&
      productName
        // .replace(/[^a-zA-Z1-9 _]/g, "")
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-')
        .split(" ")
        .join("-")
        .toLowerCase();

    let currentLocation = this.props.history.location.pathname;
    currentLocation = currentLocation.split("/");
    if (!this.props.isMenu) {
      if (currentLocation[1] === "products") {
        this.props.history.push(`../categories/${productName}`);
      } else this.props.history.push(`./categories/${productName}`);
    } else this.props.history.push(`./${productName}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  render() {
    const { branchCategory, branchId } = this.props;
    const categories = this.props.categories || this.state.categories;
    const branchPhoneNumber =
      isArray(branchCategory) &&
      branchCategory.find(branch => branch.branch_id === branchId);
    return (
      <div>
        <footer className="mb-4 mb-md-0">
          <div className="copyrights">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="footer-logo">
                    <img className="img-fluid" src="/images/rim/logo.svg" alt=""/>
                  </div>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-3">
                  <h4>Links</h4>
                  <ul className="footer-nav">
                    <li>
                      <NavLink to="/about-us">
                        About us
                      </NavLink>
                    </li>
                    {/* <li><NavLink to="/offers" target="new"><span className="fa fa-gift"></span> Offers </NavLink></li> */}
                    <li>
                      <NavLink to="/privacy">
                        Privacy
                        Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/terms">
                        Terms and
                        Conditions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/refund-policy">
                        Refund and Returns Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/shipping-policy">
                        Shipping Policy
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <h4>Reach us</h4>
                  <ul className="footer-nav">
                    <li>
                      <a
                        href="mailto:care@retroinmetro.com?Subject=Customer%20Voice"
                        target="_top"
                      >
                         Write to us
                      </a>
                    </li>
                    {/*<li className="d-block d-sm-none">*/}
                    {/*  <a href="tel:+918123363876">*/}

                    {/*    Order by call*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                    <li className="d-block d-sm-block">
                      {/*<a href={`tel:${branchPhoneNumber && branchPhoneNumber.branch_phone}`}>*/}
                      {/*  {branchPhoneNumber && branchPhoneNumber.branch_phone}{" "}*/}
                      {/*</a>*/}
                      Order By Call <br/>
                      <a href={`tel:+918123363876`}>
                        +91 81233 63876
                      </a>
                    </li>
                    <li>
                      <NavLink to="/products">
                        View Products
                      </NavLink>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

            <div className="brand">
              {/*<script*/}
              {/*type="text/javascript"*/}
              {/*src="https://cdn.ywxi.net/js/1.js"*/}
              {/*async=""*/}
              {/*></script>*/}
              <p>Retro in Metro. &copy; 2022, All Rights Reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    branchId: state.CartReducer.branchId,
    branchCategory: state.CartReducer.branchCategory
  };
};
export default withRouter(connect(mapStateToProps)(Footer));
