import React, { Component } from 'react';
import {config} from "../../env";
import ReactModal from 'react-modal';
import Iframe from "react-iframe";
import {NavLink} from "react-router-dom";
import LazyLoad from 'react-lazyload';
import { homeService } from '../../_services';
class Cards extends Component {
    constructor () {
        super();
        this.state = {
            showModal: false,
            showModalTwo: false,
            coupons: [],
            isLoading:true
        };


        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenModalTwo = this.handleOpenModalTwo.bind(this);
        this.handleCloseModalTwo = this.handleCloseModalTwo.bind(this);
    }
    state = {
        banners: [],
        coupons: [],
        isLoading:true
    }
    componentDidMount() {
        this.getCouponList();
    }
    getCouponList = async () => {
        this.setState({isLoading:true});
        let response = await homeService.getCouponList(this.props.branchId?this.props.branchId: 3);
        // console.log(response.data, 'cpup')
        if (response) {
            this.setState({ coupons: response.data,isLoading:false });
        }else{
            this.setState({isLoading:false});
        }
    }
    getOnPropsChangeBanners = async (branchId) => {
        this.setState({isLoading:true});
        let response = await homeService.getBanners(branchId||3);
        if (response.data) {
            this.setState({ coupons: response.data,isLoading:false });
        }else{
            this.setState({isLoading:false});
        }
    }

    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false });
    }
    handleOpenModalTwo () {
        this.setState({ showModalTwo: true });
    }
    handleCloseModalTwo () {
        this.setState({ showModalTwo: false });
    }


    handleClickCategory = categoryId => {
        sessionStorage.setItem('categoryId', categoryId);
    };
    render() {
        return (

            <section className="small-banner new-small-banner mt-4">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-6 col-sm-6 col-md-3">
                            <NavLink to="/categories/claypot-biryanis">
                            <img className="border-bottom-all img-fluid small-bottom" src={`${config.urlPrefix}/images/new-mini-banners/1.jpg`} alt="" />
                            </NavLink>
                        </div>


                        <div className="col-6 col-sm-6 col-md-3 d-none">
                            <NavLink to="/categories/meal-for-1">
                            <img className="border-bottom-all img-fluid small-bottom" src={`${config.urlPrefix}/images/new-mini-banners/8.jpg`} alt="" />
                            </NavLink>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3">
                            <NavLink to="/live-kitchen">
                            <img className="border-bottom-all img-fluid small-bottom" src={`${config.urlPrefix}/images/new-mini-banners/7.jpg`} alt="" />
                            </NavLink>
                        </div>

                        <div className="col-6 col-sm-6 col-md-3">
                            <img onClick={this.handleOpenModal} className="border-bottom-all img-fluid small-bottom cursor-pointer" src={`${config.urlPrefix}/images/new-mini-banners/8.jpg`} alt="" />
                        </div>

                        <div className="col-6 col-sm-6 col-md-3">

                            <img onClick={this.handleOpenModalTwo}  className="border-bottom-all img-fluid small-bottom cursor-pointer" src={`${config.urlPrefix}/images/new-mini-banners/9.png`} alt="" />

                            </div>

                            </div>
                    {/*<ul className="scroll-banners">*/}
                        {/*<li>*/}
                            {/*<div className="scroll-banner-holder cursor-pointer" onClick={this.handleOpenModal}>*/}
                            {/*/!*<div className="scroll-banner-holder cursor-pointer">*!/*/}
                                {/*<img className="border-bottom-radius" src={`${config.urlPrefix}/images/new-mini-banners/1.jpg`} alt="" />*/}
                            {/*</div>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                            {/*<div className="scroll-banner-holder">*/}
                                {/*<img className="border-bottom-radius" src={`${config.urlPrefix}/images/new-mini-banners/2.jpg`} alt="" />*/}
                            {/*</div>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                            {/*<div className="scroll-banner-holder">*/}
                                {/*<img className="border-bottom-radius" src={`${config.urlPrefix}/images/new-mini-banners/3.jpg`} alt="" />*/}
                            {/*</div>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                            {/*<div className="border-bottom-radius" className="scroll-banner-holder">*/}
                                {/*<img src={`${config.urlPrefix}/images/new-mini-banners/4.jpg`} alt="" />*/}
                            {/*</div>*/}
                        {/*</li>*/}
                    {/*</ul>*/}
                </div>
                <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                    className="pre-modal blackmodal"
                    id="two"
                >
                    <div>
                        <Iframe
                            url="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fyoursdeliciously%2Fvideos%2F817291455347869%2F&show_text=0&width=267"
                            width="340px"
                            height="550px"
                            id="myId1"
                            className="myClassname"
                            display="initial"
                            position="relative"
                            scrolling="no"
                            frameBorder="1"
                            allowTransparency="true"
                            styles={{border: "0px"}}
                        />
                        {/*scrolling="no" frameBorder="1" allowTransparency="true" allowFullScreen="false"*/}
                    </div>
                    <button onClick={this.handleCloseModal} className="awesome btn btn-warning mt-2 mid">Close</button>
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showModalTwo}
                    contentLabel="Minimal Modal Example"
                    className="pre-modal coupon-pop"
                    id="three"
                >
                    <div className="available-coupons">
                        <h5 className="coupon-heading">Coupons</h5>
                        <p className="coupon-description">Use below coupons while checking out to avail discounts</p>
                        <ul className="nav">
                            {this.state.coupons.map((item) => (
                                <li>
                                    <h2 className="text-uppercase">{item.coupon_text}</h2>
                                    <p>{item.description}</p>
                                </li>
                            ))
                            }
                        </ul>

                    </div>
                    <button onClick={this.handleCloseModalTwo} className="awesome btn btn-warning mt-2 mid">Close</button>
                </ReactModal>
            </section>
        )
    }
}
export default Cards;
