import React, { Component } from 'react';
import TermsPage  from './chooseteam-html';
import { checkoutService } from "../../_services/checkout.service";
import {toast} from "react-toastify";
import {isArray} from "../../utils/util";


class Chooseteam extends React.Component {
    constructor(props) {
        super(props);

        this.handleTeamSelected = this.handleTeamSelected.bind(this);

        this.state = {
            teamList: [],
            selectedTeam: {},
            current_selected:0
        };
    }

    // handleChange(event) {
    //     this.setState({value: event.target.value});
    // }
    //
    // handleSubmit(event) {
    //     alert('Your Chosen Team ' + this.state.value);
    //     event.preventDefault();
    // }
    componentDidMount() {
        this.getSelectedTeam();
    }

    getTeamList = async () => {
        let response = await checkoutService.getTeamList();
        // console.log(response);
        if (response.data && response.error === "") {
            this.setState({ teamList: response.data});
        } else {
            let errorMessage = response.errors || response.error_message;
            toast.error(errorMessage);
        }
    };

    getSelectedTeam = async () => {
        let response = await checkoutService.getSelectedTeam();
        // console.log(response);
        if (response.data && response.error === "") {
            this.setState({ selectedTeam: response.data});
        } else {
            let errorMessage = response.errors || response.error_message;
            toast.error(errorMessage);
        }
        this.getTeamList();
    };

    updateSelectedTeam = async () => {

        if(this.state.current_selected===0){
            alert('Please choose a team');
            return;
        }
        let response = await checkoutService.markTeamSelected(this.state.current_selected);
        if (response.data && response.data.status==='success') {
            // console.log(response);
            this.props.history.push(`/user_profile`);
        } else {
            let errorMessage = response.errors || response.error_message;
            toast.error(errorMessage);
        }
    };

    handleTeamSelected(e) {
        let new_id=e.target.value;
        let teamSelected = this.state.selectedTeam;
        if(teamSelected['team_id']!==undefined && teamSelected['team_id']!=new_id)
            new_id=teamSelected['team_id'];
        this.setState({ current_selected: new_id});
    }

    render() {
        let teamList=this.state.teamList;
        let teamSelected = this.state.selectedTeam;
        let current_selected=this.state.current_selected;
        if(teamSelected['team_id']!==undefined)
            current_selected=teamSelected['team_id'];

        return (
            <section>
                <div className="container">
                    <div className="row">
                        <div className="offset-md-3"></div>
                        <div className="col-md-7">
                            <div className="team-dev">
                                <div className="PPL ">
                                    <h1 className="pt-4 pb-2">Potful<br/> Premier League</h1>
                                    <div className="ppl-text">
                                        <p>Choose your Team. When they win,
                                            you score 50 Runs. 1Run = 1Rupee</p>
                                    </div>
                                </div>
                            {/*<h3>Choose Your Team</h3>*/}
                            {/*<form>*/}
                                <div className="row">
                                    {isArray(teamList) &&
                                    teamList.map(eachTeam => (
                                        <div className="col-4 col-sm-4 text-center">
                                            <label htmlFor={`${eachTeam.id}`} className="cursor-pointer">
                                                <div className="team">
                                                    <div className="team-img"><img src={`${eachTeam.team_img}`} alt=""/></div>
                                                    <p>{eachTeam.team_name}</p>
                                                    <input name="team" id={`${eachTeam.id}`} type="radio" value={`${eachTeam.id}`} checked={current_selected === eachTeam.id}  onChange={this.handleTeamSelected} />
                                                </div>
                                            </label>
                                        </div>

                                    ))}
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-center mt-4 mb-4">
                                            <button className="btn btn-danger" type="submit" onClick={this.updateSelectedTeam}>Get Started</button>
                                        </div>
                                    </div>
                                </div>

                            {/*</form>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


export default Chooseteam;