import React from 'react';
import { config } from '../../env';
import { NavLink } from 'react-router-dom';

const FeedbackSuccess = (props) => {
  return (
    <div className="feedback-page">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="feedback-header">
              <div className="feedback-logo">
                <img
                  className="img-fluid"
                  src={`${config.urlPrefix}/images/logo.png`}
                  alt=""
                />
              </div>
              <h4>Thanks For Rating</h4>
              <p>
                Thank you for ordering & hope you had a delicious meal/ Please
                help us do better
              </p>
            </div>
            <NavLink to="/">Home</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeedbackSuccess;
