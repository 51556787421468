import React from 'react';
import moment from 'moment';
import { isArray } from '../../../utils/util';
import { NavLink } from 'react-router-dom';
import { getValue } from '../../../utils/lodash';

const orderHistory = (props) => {
  const { totalCartData, orderHistory, handleOrderDetails } = props;
  const deliveredAgo = (time) => {
    // let getMinute = time.split(" ")[0].slice(1);
    let checkPosNeg = time.split(' ')[0];
    let checkMinute = time.split(' ')[1];
    // let now = new Date();
    // const hours = checkPosNeg>=60?1:0;
    if (checkPosNeg > 0 || checkMinute !== 'MINS') {
      return time;
      // let newDate =
      //   checkPosNeg > 0
      //     ? moment(now)
      //         .add(hours, 'hours')
      //         .add(getMinute, "minutes")
      //         .toDate()
      //     : moment(now)
      //         .subtract(getMinute, "minutes")
      //         .toDate();
      // return moment(newDate).fromNow();
    } else return '';
  };

  const showDeliveredInTimeOrNot = (totalCartData) => {
    if (isArray(totalCartData.bill_status)) {
      const statusArrayLength = totalCartData.bill_status.length - 1;
      const orderStatus =
        totalCartData.bill_status[statusArrayLength].bill_status;
      if (orderStatus == 9 || orderStatus == 10 || orderStatus == 8) {
        return false;
      } else if (orderStatus == 3) {
        return 'Expected delivery time';
      } else {
        return 'Your order will be delivered in';
      }
    }
  };

  const getOrderStatusMessage = (totalCartData) => {
    if (isArray(totalCartData.bill_status)) {
      const statusArrayLength = totalCartData.bill_status.length - 1;
      const orderStatus =
        totalCartData.bill_status[statusArrayLength].bill_status;
      const assignedTo =
        totalCartData.bill_status[statusArrayLength].assigned_to;
      if (orderStatus == 6 || orderStatus == 7) {
        return `Your order is on the way and will be delivered by ${assignedTo.name} +91 ${assignedTo.phone}`;
      } else if (orderStatus == 3) {
        return 'Thank you for placing the order';
      } else if (orderStatus == 4) {
        return 'Your Order is being packed';
      } else if (orderStatus == 5) {
        return 'Order order is ready to Ship';
      } else if (orderStatus == 9 || orderStatus == 10) {
        return `Sorry, your order has been cancelled.`;
      } else if (orderStatus == 8) {
        return 'Your order has been delivered successfully';
      }
    }
  };
  return (
    <div className="profile-details">
      <div id="accordion">
        {isArray(orderHistory) &&
          orderHistory.map((history) => (
            <div key={history.bill_id} className="card">
              <div className="card-header" id={`headingOne${history.bill_id}`}>
                <h5 className="mb-0">
                  <button
                    onClick={() => handleOrderDetails(history.bill_id)}
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapseOne${history.bill_id}`}
                    aria-expanded="true"
                    aria-controls={`collapseOne${history.bill_id}`}
                  >
                    Order #{history.bill_id}
                    {getValue(history, `is_active`, false) && (
                      <span className="mr-3 ml-3">
                        <a href={`/live-track/${history.bill_id}`} target={'blank'} className="btn btn-outline-dark">Track my order</a>
                      </span>
                    )}
                    {/*{!getValue(history, `feedback`, false) && getValue(history, `current_status`,'') == 8 && (*/}
                    {/*  <span className="mr-3 ml-3">*/}
                    {/*    <a href={`/feedback/${history.bill_id}`} target={'blank'} className="btn btn-outline-dark">Rate your order</a>*/}
                    {/*  </span>*/}
                    {/*)}*/}
                  </button>

                </h5>

                  {isArray(totalCartData.bill_products) &&
                      totalCartData.bill_products.map((data) => (
                          <div className="row" key={data.product_price_id}>
                            <div className="col-md-2">
                              <img className="img-fluid" src={data.image_url} alt=""/>
                            </div>
                            <div className="col-md-12">{data.product_name}</div>
                            <div>{data.qty}x</div>
                            <div className="text-right">
                              {data.product_price * data.qty}
                            </div>
                          </div>
                      ))}
              </div>
              <div
                id={`collapseOne${history.bill_id}`}
                className={`collapse show1`}
                aria-labelledby={`headingOne${history.bill_id}`}
                data-parent={'#accordion'}
              >
                <div className="card-body">
                  <section className="trackorder">
                    <div className="container1">
                      <div className="row1">
                        <div className="">
                          <div className="track-holder">
                            <div className="track-details">
                              {totalCartData.current_status >= 6 ? (
                                  <>
                                    {showDeliveredInTimeOrNot(totalCartData) && (
                                        <div className="order-time">
                                          <p>
                                            {showDeliveredInTimeOrNot(totalCartData)}
                                          </p>
                                          <h2>
                                            {/*{totalCartData.exp_del_date &&*/}
                                            {/*  deliveredAgo(totalCartData.exp_del_date)}*/}
                                            {totalCartData.exp_del_date.split(",", 1)}
                                          </h2>
                                        </div>
                                    )}
                                  </>
                              ):(
                                  <>
                                  </>
                              )}


                              <div className="order-status">
                                <p className="text-center">
                                  <span className="badge-secondary badge">
                                    Order Status
                                  </span>
                                </p>
                                {/*<NavLink className={`float-right`} to={`/live-track/${totalCartData.bill_id}`}>Live Track</NavLink>*/}
                                <h2>
                                  {isArray(totalCartData.bill_status) &&
                                  totalCartData.bill_status.length >= 2
                                    ? totalCartData.bill_status[
                                        totalCartData.bill_status.length - 1
                                      ]['bill_status_name']
                                    : history.bill_status_name &&
                                      history.bill_status_name}
                                </h2>
                                <p>{getOrderStatusMessage(totalCartData)}</p>
                              </div>
                            </div>
                            <div className="order-highlight">
                              <div className="row">
                                <div className="col-lg-12">
                                  <h4>
                                    {totalCartData.customer_first_name &&
                                      totalCartData.customer_first_name}
                                  </h4>
                                  <p>
                                    +91{' '}
                                    {totalCartData.customer_phone &&
                                      totalCartData.customer_phone}
                                  </p>
                                  <p>
                                    {totalCartData.customer_address &&
                                      totalCartData.customer_address
                                        .address_text}{' '}
                                    {totalCartData.customer_address &&
                                      totalCartData.customer_address
                                        .google_address}
                                  </p>
                                </div>
                                <div className="col-lg-12">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <th>
                                          <strong>Order ID</strong>
                                        </th>
                                        <td className="text-right">
                                          #{totalCartData.bill_id}
                                          {/*{isArray(totalCartData.bill_status) &&*/}
                                          {/*totalCartData.bill_status[0].id}*/}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>
                                          <strong>Payment Mode</strong>
                                        </th>
                                        <td className="text-right">
                                          {totalCartData.payment_mode_value &&
                                            totalCartData.payment_mode_value}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>
                                          <strong>Ordered at</strong>
                                        </th>
                                        <td className="text-right">
                                          {totalCartData.date_time &&
                                            totalCartData.date_time}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="bill-items">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th>Item</th>
                                    <th>Qty</th>
                                    <th className="text-right">Price</th>
                                  </tr>
                                  {isArray(totalCartData.bill_products) &&
                                    totalCartData.bill_products.map((data) => (
                                      <tr key={data.product_price_id}>
                                        <td>{data.product_name}</td>
                                        <td>{data.qty}x</td>
                                        <td className="text-right">
                                          {data.product_price * data.qty}
                                        </td>
                                      </tr>
                                    ))}
                                  {/* <tfoot> */}
                                  <tr>
                                    <td>
                                      <strong>Sub-Total</strong>
                                    </td>
                                    <td />
                                    <td className="text-right">
                                      {Math.round(
                                        totalCartData.bill_subtotal
                                      ) &&
                                        Math.round(totalCartData.bill_subtotal)}
                                    </td>
                                  </tr>

                                  {totalCartData.bill_discount_value > 0 && (
                                    <tr>
                                      <td>Coupon</td>
                                      <td />
                                      <td className="text-right">
                                        -{' '}
                                        {Math.round(
                                          totalCartData.bill_discount_value
                                        )}
                                      </td>
                                    </tr>
                                  )}

                                  {totalCartData.wallet_amount &&
                                    totalCartData.wallet_amount > 0 && (
                                      <tr>
                                        <td>Credits</td>
                                        <td />
                                        <td className="text-right">
                                          -{' '}
                                          {Math.round(
                                            totalCartData.wallet_amount
                                          )}
                                        </td>
                                      </tr>
                                    )}

                                  {isArray(totalCartData.charges) &&
                                    totalCartData.charges.map(
                                      (charge, index) => (
                                        <tr key={index}>
                                          <td>{charge.name}</td>
                                          <td className="text-right">
                                            {charge.value}
                                          </td>
                                        </tr>
                                      )
                                    )}

                                  <tr>
                                    <td>Packing Charge</td>
                                    <td />
                                    <td className="text-right">
                                      {Math.round(
                                        totalCartData.packing_charge_value
                                      ) &&
                                        Math.round(
                                          totalCartData.packing_charge_value
                                        )}
                                    </td>
                                  </tr>
                                  {isArray(totalCartData.bill_taxes) &&
                                    totalCartData.bill_taxes.map(
                                      (tax, index) => (
                                        <tr key={index}>
                                          <td>
                                            {tax.tax_type_name} ({tax.tax_value}
                                            )
                                          </td>
                                          <td></td>
                                          <td className="text-right">
                                            {Number(tax.amount).toFixed(1)}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  <tr>
                                    <td>
                                      <strong>Total</strong>
                                    </td>
                                    <td />
                                    <td className="text-right">
                                      {Math.round(totalCartData.bill_total) &&
                                        Math.round(totalCartData.bill_total)}
                                    </td>
                                  </tr>
                                  {/* </tfoot> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default orderHistory;
