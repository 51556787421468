import React, { Component } from 'react';
import OffersPage from './offers-html';
import $ from 'jquery';
import { connect } from 'react-redux';
import { commonActions } from '../../_actions';
class Offers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: 0
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    componentDidMount() {
        this.props.dispatch(commonActions.listBanner());
    }
    opeMenu() {
        $(".resp-nav").removeClass("away");
    }
    closeMenu() {
        $(".resp-nav").addClass("away");
    }
    handleShow(i) {
        $('html, body').animate({
            scrollTop: $('#' + i).offset().top
        }, 1000)
    }
    render() {
        return (
            <OffersPage
                opeMenu={e => this.opeMenu()}
                closeMenu={e => this.closeMenu()}
                handleShow={e => this.handleShow(e)}
            />
        )
    }
}
function mapStateToProps(state) {
    const { menu, data } = state;
    return {
        menu,
        data
    };
}
export default connect(mapStateToProps)(Offers); 