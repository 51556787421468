import React from 'react';
const FilterContainer = (props) => {
    return (
        <div className="loader-css">
            {!props.payment?<div className="lds-ripple"><div></div><div></div></div>
            :
            <div >
                Please do not refresh the page and wait while we are processing your payment
                <div></div><div></div></div>
            }
        </div>
    )
}
export default FilterContainer;
