import React, { Component } from 'react';
import PrivacyPage  from './privacy-html'; 
class Privacy extends Component {
    constructor(props){ 
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
        return(
            <PrivacyPage 
            />
        )
    }
}
export default Privacy;