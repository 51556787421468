import React, {useState} from "react";

import {
    isArray,
    checkProductExistOnCart,
    checkItemInStock,
    checkHourExistInBetweenTime
} from "../../../utils/util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import addDays from "date-fns/addDays";
import add from "date-fns/add";
import {config} from "../../../env";
import Iframe from "react-iframe";
import ReactModal from "react-modal";
import {Event} from '../../../_actions/trackEvent';
import {toast} from "react-toastify";
import {homeService} from '../../../_services/home.service';

const CheckoutOrderCardDetail = props => {
    const [open, setOpen] = useState(false);

    const {
        cartData,
        couponList,
        totalCartData,
        isValidCouponCode,
        couponCode,
        currentBranchInfo,
        handleCouponChange,
        handleApplyCoupon,
        handleCouponSelect,
        startServingHour,
        endServingHour,
        categories,
        isDateRequired,
        handleRemoveCoupon,
        isLoggedIn,
        state,
        showModal,
        hideModal,
    } = props;
    let startServing = startServingHour ? startServingHour.split(":") : null;
    let startServingHours = add(new Date(), {
        minutes: totalCartData.branch_delivery_time
    }).getHours();
    let startServingMinute = startServing ? startServing[1] : 0;
    let endServing = endServingHour ? endServingHour.split(":") : null;
    let endServingHours = startServing ? endServing[0] - 1 : 0;
    let endServingMinute = startServing ? +endServing[1] : 0;

    let canDeliverLater = startServingHours <= endServingHours;

    let currentServing =
        checkHourExistInBetweenTime(props.currentBranchInfo, "Single") !==
        "Pre Order"
            ? true
            : false;
    var todayDate = new Date();
    var currentHourMinute = Number(
        todayDate.getHours() * 60 + todayDate.getMinutes()
    );
    var expectedDateOfDelivery=new Date();
    expectedDateOfDelivery.setTime(todayDate.getTime()+(totalCartData.branch_delivery_time*60*1000));

    let preServing = !(
        Number(Number(startServingHours) * 60 + Number(startServingMinute)) >
        currentHourMinute
    );

    const applyCoupon = (value)=>{
        handleCouponSelect(value);
    }

    return (

        <div className="col-lg-4 col-md-5">
            <div className="checkout-page-cart">
              {/*  <div className="checkout-page-header">*/}
              {/*      <div className="checkout-page-toggle">*/}
              {/*          <ul>*/}
              {/*              {checkHourExistInBetweenTime(currentBranchInfo, "Single") !==*/}
              {/*              "Pre Order" && (*/}
              {/*                  <li>*/}
              {/*                      <button*/}
              {/*                          onClick={e => props.deliverNow()}*/}
              {/*                          className="btn-nothing active deliver-now-trigger"*/}
              {/*                      >*/}
              {/*                          Deliver Now*/}
              {/*                      </button>*/}
              {/*                  </li>*/}
              {/*              )}*/}
              {/*              {checkHourExistInBetweenTime(currentBranchInfo, "Single") !==*/}
              {/*              "Pre Order" && canDeliverLater ? (*/}
              {/*                  <li>*/}
              {/*                      <button*/}
              {/*                          onClick={e => props.deliverLater()}*/}
              {/*                          className="btn-nothing deliver-later-trigger"*/}
              {/*                      >*/}
              {/*                          Deliver later*/}
              {/*                      </button>*/}
              {/*                  </li>*/}
              {/*              ) : (*/}
              {/*                  <li>*/}
              {/*                      <button*/}
              {/*                          onClick={e => props.deliverLater()}*/}
              {/*                          className="btn-nothing deliver-now-trigger"*/}
              {/*                      >*/}
              {/*                          Pre Order*/}
              {/*                      </button>*/}
              {/*                  </li>*/}
              {/*              )}*/}
              {/*          </ul>*/}
              {/*          <div className={`deliver-now  ${!currentServing ? "d-none" : ""}`}>*/}
              {/*              <p>Your order will be delivered before</p>*/}
              {/*              <h2>{totalCartData.branch_delivery_time}m</h2>*/}
              {/*          </div>*/}
              {/*          <div className={`deliver-later ${currentServing ? "d-none" : ""}`}>*/}
              {/*              <p>When do you need the delivery?</p>*/}
              {/*              <DatePicker*/}
              {/*                  className="form-control bg-white w-75 m-auto text-center"*/}
              {/*                  selected={props.startDate}*/}
              {/*                  showTimeSelect*/}
              {/*                  popperPlacement="bottom"*/}
              {/*                  popperModifiers={{*/}
              {/*                      flip: {*/}
              {/*                          behavior: ["bottom"] // don't allow it to flip to be above*/}
              {/*                      },*/}
              {/*                      preventOverflow: {*/}
              {/*                          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)*/}
              {/*                      },*/}
              {/*                      hide: {*/}
              {/*                          enabled: false // turn off since needs preventOverflow to be enabled*/}
              {/*                      }*/}
              {/*                  }}*/}
              {/*                  dateFormat="MMMM d, yyyy h:mm aa"*/}
              {/*                  onChange={date =>*/}
              {/*                      props.handleOrderDateChange(date, startServingHours, preServing)*/}
              {/*                  }*/}
              {/*                  minDate={preServing ? addDays(new Date(), 1) : new Date()}*/}
              {/*                  maxDate={addDays(new Date(), 5)}*/}
              {/*                  timeIntervals={30}*/}
              {/*                  placeholderText={`${*/}
              {/*                      currentServing ? "Delivery later" : "Pre Order"*/}
              {/*                  }`}*/}
              {/*                  showDisabledMonthNavigation*/}
              {/*                  timeCaption="time"*/}
              {/*                  minTime={setHours(*/}
              {/*                      setMinutes(addDays(new Date(), 1), startServingMinute),*/}
              {/*                      startServingHours*/}
              {/*                  )}*/}
              {/*                  maxTime={setHours(*/}
              {/*                      setMinutes(addDays(new Date(), 1), endServingMinute),*/}
              {/*                      endServingHours*/}
              {/*                  )}*/}
              {/*              />*/}
              {/*              <span className="text-danger fs-12 d-block text-center">*/}
              {/*  {isDateRequired ? toast.error("Please select Pre-Order time") : ""}*/}

              {/*</span>*/}
              {/*          </div>*/}
              {/*      </div>*/}
              {/*  </div>*/}
                <div className="cart-items px-3">

                    <h2 className="mt-4 ml-2 opacity-50">YOUR CART</h2>
                    {isArray(cartData) &&
                    cartData.map((data, index) => (
                        <div
                            key={data.product_id + index}
                            className="cart-item border-0 mb-0"
                        >
                            <h2>
                                {/*{data.is_veg === "1" ? (*/}
                                {/*    <img*/}
                                {/*        src={`${config.urlPrefix}/images/veg.svg`}*/}
                                {/*        className="mt--2px mr-1"*/}
                                {/*        alt=""*/}
                                {/*    />*/}
                                {/*) : (*/}
                                {/*    <img*/}
                                {/*        src={`${config.urlPrefix}/images/non_veg.svg`}*/}
                                {/*        className="mt--2px mr-1"*/}
                                {/*        alt=""*/}
                                {/*    />*/}
                                {/*)}*/}
                                {data.product_name}
                            </h2>
                            <div className="product_price">
                                <div className="product_price_item float-left">
                                    <p className="mb-0 mt-1">
                                        <strong>
                                            {Math.ceil(data.product_price)}
                                            {/* <sup className="currency">&#8377;</sup> */}
                                        </strong>{" "}
                                    </p>
                                </div>
                                <div className="add-cart float-right">
                                    <p className="btn btn-brand px-0">
                                        <button
                                            onClick={() => props.handleDecrementProductCount(data) + Event("E-Commerce", "Removed from cart", "Removed from cart")}
                                            className="btn-nothing minus"
                                        >
                                            -
                                        </button>
                                        <span>{checkProductExistOnCart(data, cartData)}</span>
                                        <button
                                            onClick={() => props.handleAddToCart(data) + Event("E-Commerce", "Add to cart", "Add to Cart")}
                                            className="btn-nothing plus"
                                        >
                                            +
                                        </button>
                                    </p>
                                </div>
                                <div className="clearfix"/>
                                {checkItemInStock(categories, cartData)}
                            </div>
                        </div>
                    ))}

                    <div className="checkout-tax">
                        <div className="coupon-holder">
                            <div className="form-group mb-0">
                                <input
                                    value={couponCode}
                                    onChange={e => handleCouponChange(e)}
                                    type="text"
                                    placeholder="Enter Coupon Code"
                                    className="form-control"
                                />
                                {!isValidCouponCode ? "Please enter valid coupon" : ""}
                                {/*{!isValidCouponCode ? "No coupons available currently" : ""}*/}
                                <img
                                    src={`${config.urlPrefix}/images/rim/discount.png`}
                                    alt=""
                                />
                                {(totalCartData.coupon === undefined ||
                                    totalCartData.coupon === null ||
                                    totalCartData.coupon === "" ||
                                    Number(totalCartData.coupon) === 0) && (
                                    <button
                                        onClick={handleApplyCoupon}
                                        className="btn-nothing coupon_apply"
                                        disabled={!isValidCouponCode}
                                    >
                                        Apply
                                    </button>
                                )}

                                {totalCartData.coupon > 0 && (
                                    <button
                                        onClick={handleRemoveCoupon}
                                        className="btn-nothing coupon_apply"
                                    >
                                        Remove
                                    </button>
                                )}
                            </div>
                            <button onClick={() => setOpen(true)} className="btn-coupon btn btn-outline-success btn-block rounded-50 mt-3">View
                                Coupons
                            </button>
                        </div>
                        {/*{totalCartData.wallet_usable && totalCartData.wallet_usable == true && (*/}

                        {/*    <div>*/}
                        {/*        {isLoggedIn && (*/}

                        {/*            <div className="box box-credit">*/}
                        {/*                <label htmlFor="wallet" className="cursor-pointer text-left">*/}
                        {/*                    <div className="radio-container checkbox">*/}
                        {/*                        <input*/}
                        {/*                            onChange={() => props.handleWalletCheck()}*/}
                        {/*                            checked={props.use_wallet ? true : false}*/}
                        {/*                            type="checkbox"*/}
                        {/*                            name="wallet"*/}
                        {/*                            id="wallet"*/}
                        {/*                        />*/}
                        {/*                        <span className="checkmark"/>*/}
                        {/*                    </div>*/}
                        {/*                    <img*/}
                        {/*                        className="mr-3"*/}
                        {/*                        height="40"*/}
                        {/*                        // src={`${config.urlPrefix}/images/teams/bat-big.png`}*/}
                        {/*                        src={`${config.urlPrefix}/images/wallet.svg`}*/}
                        {/*                        alt=""*/}
                        {/*                    />*/}
                        {/*                    <div>*/}
                        {/*                        <p className="mb-0 fs-12">*/}
                        {/*                            Your Current Credits (*/}
                        {/*                            {props.totalCartData.current_wallet_balance*/}
                        {/*                                ? props.totalCartData.current_wallet_balance*/}
                        {/*                                : 0}*/}
                        {/*                            )*/}
                        {/*                        </p>*/}
                        {/*                        <p>*/}
                        {/*                            Redeem Upto ({props.totalCartData.max_usable_credits}) Credits per*/}
                        {/*                            order*/}
                        {/*                        </p>*/}
                        {/*                    </div>*/}
                        {/*                    <span className="clearfix"/>*/}
                        {/*                </label>*/}
                        {/*            </div>*/}
                        {/*        )}*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {/*{totalCartData.wallet_usable == false && (*/}
                        {/*    <p>Credits (Your current runs) can be used only once a day</p>*/}
                        {/*)}*/}
                        <table className="table">
                            <tbody>
                            <tr>
                                <td>
                                    <strong>Subtotal</strong>
                                </td>
                                <td className="text-right">
                                    <strong>
                                        {new Intl.NumberFormat("en-IN", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        }).format(totalCartData.subtotal)}
                                    </strong>
                                </td>
                            </tr>
                            {totalCartData.coupon > 0 && (
                                <tr>
                                    <td>Coupon</td>
                                    <td className="text-right">
                                        -
                                        {new Intl.NumberFormat("en-IN", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        }).format(totalCartData.coupon)}
                                    </td>
                                </tr>
                            )}

                            {totalCartData.wallet_amount &&
                            totalCartData.wallet_amount > 0 && (
                                <tr>
                                    <td>Credits</td>
                                    <td className="text-right">
                                        -{" "}
                                        {new Intl.NumberFormat("en-IN", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        }).format(totalCartData.wallet_amount)}
                                    </td>
                                </tr>
                            )}

                            {isArray(totalCartData.charges) &&
                            totalCartData.charges.map((charge, index) => (
                                <tr key={index}>
                                    <td>{charge.name}</td>
                                    <td className="text-right">
                                        {new Intl.NumberFormat("en-IN", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        }).format(charge.value)}
                                    </td>
                                </tr>
                            ))}
                            {isArray(totalCartData.taxes) &&
                            totalCartData.taxes.map(tax => (
                                <tr key={tax.tax_type_name}>
                                    <td>{tax.tax_type_name}</td>
                                    <td className="text-right">
                                        {Number(tax.total_value).toFixed(1)}
                                    </td>
                                </tr>
                            ))}

                            {/* <td>
                    CGST (
                    {isArray(totalCartData.taxes) &&
                      getGstPercentage(totalCartData.taxes)}
                    %)
                  </td>
                  <td className="text-right">
                    {isArray(totalCartData.taxes) &&
                      getGstAmount(totalCartData.taxes)}
                  </td> */}
                            {/* </tr> */}

                            {/* <tr className="last">
                  <td>
                    SGST (
                    {isArray(totalCartData.taxes) &&
                      getCgstPercentage(totalCartData.taxes)}
                    %)
                  </td>
                  <td className="text-right">
                    {isArray(totalCartData.taxes) &&
                      getCgstAmount(totalCartData.taxes)}
                  </td>
                </tr> */}

                            <tr className="total">
                                <td>
                                    <strong>Total Payable</strong>
                                </td>
                                <td className="text-right">
                                    <strong>
                                        {new Intl.NumberFormat("en-IN", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        }).format(totalCartData.total)}
                                    </strong>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*<div className="mb-3">*/}
                    {/*    <p className="mb-0 pb-0">You can expect delivery by</p>*/}
                    {/*    <h2 className="mt- pt-0 text-success">{expectedDateOfDelivery.toDateString()}</h2>*/}
                    {/*</div>*/}
                </div>
            </div>
            <ReactModal
                isOpen={open}
                onHide={() => setOpen(false)}
                contentLabel="Minimal Modal Example"
                className="pre-modal coupon-pop coupon-pop-checkout"
                id="three"
            >
                <div className="available-coupons">
                    <p className="coupon-heading">Coupons</p>
                    <ul className="nav">
                        {couponList.length ? (couponList.map((item) => (
                            <li >
                                <h2 className="text-uppercase">{item.coupon_text}</h2>
                                <p>{item.description}</p>
                                <p className="apply-pop-coupon" onClick={(e) => {
                                    setOpen(false);
                                    applyCoupon(item.coupon_text);
                                }}>Apply Coupon</p>
                            </li>
                        ))) : (
                            <>
                            <p className="text-center mb-4 mt-2">No coupons available at the moment</p>
                            </>
                        )
                        }
                    </ul>

                </div>
                <button onClick={() => setOpen(false)} className="awesome btn btn-warning mt-2 mid">Close</button>
            </ReactModal>
        </div>
    );
};

export default CheckoutOrderCardDetail;
