import React, { Component } from "react";
import ProductDetailsPage from "./product-details-html";
import { homeService } from "../../_services";
import { isArray } from "../../utils/util";
import { AddToCart, SetBranchAddress } from "../../_reducers/cartReducer";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import { googleAnalyticKey } from "../../env";
ReactGA.initialize(googleAnalyticKey);

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "auto" });
    this.state = {
      categoryId: this.props.match.params.catId,
      productId: this.props.match.params.prodctId,
      similarFoods: [],
      productDetails: {},
      currentBranchInfo: {},
      cartRelatedCat: [],
      isLoading: true,
      categories: []
    };
  }

  componentDidMount() {
    this.getBranchCategory();
    this.getCurrentBranchInfo();
  }
  componentWillReceiveProps(nextProps) {
    this.getCurrentBranchInfo();
  }

  analyseGoogleAnalytics = () => {
    ReactGA.ga(
      "send",
      "pageview",
      `/products/${this.props.match.params.productName}`
    );
  };

  getCategoryId = products => {
    if (Array.isArray(products)) {
      let productList = [];
      for (let index = 0; index < products.length; index++) {
        productList.push(...products[index]["products"]);
      }
      const productName = this.props.match.params.productName;
      for (let idx = 0; idx < productList.length; idx++) {
        if (
          productName ===
          productList[idx]["product_name"]
            .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "-")
            .split(" ")
            .join("-")
            .toLowerCase()
          // .replace(/[^a-zA-Z1-9- ]/g, "")
        ) {
          return productList[idx];
          // console.log(productList)
        }
      }
    }
  };

  getBranchCategory = async () => {
    this.setState({ isLoading: true });
    let response = await homeService.getBranchLisById(this.props.branchId);
    /*
     *code commented because if you redirecting from any other website to potful its showing the last visited product because as previous discussion i was storing productId and CategoryId in local storage.
     *Now requirement change according to the requirement we need the show the product according the what ever he vising the url.
     */
    // let productId = localStorage.getItem("productId");
    // let categoryId = localStorage.getItem("categoryId");
    let productId, categoryId;
    if (categoryId == undefined || productId == undefined) {
      const slugUrls = this.getCategoryId(response.data && response.data);
      if (slugUrls) {
        // productId = localStorage.getItem("productId") || slugUrls.product_id;
        // categoryId = localStorage.getItem("categoryId") || slugUrls.category_id;
        productId = slugUrls.product_id;
        categoryId = slugUrls.category_id;
      }
    }
    if (response.data && response.data.length && productId && categoryId) {
      this.setState({ categories: response.data });
      let matchCategory = response.data.filter(
        category => category.category_id === categoryId
      );
      let getProductDetails = isArray(matchCategory[0].products)
        ? matchCategory[0].products.filter(
            product => product.product_id === productId
          )
        : {};
      // let cartRelatedCat = isArray(matchCategory[0].products)
      // ? matchCategory[0].products.filter(
      //     product =>
      //       product.product_id !== productId &&
      //       product.is_veg === getProductDetails[0].is_veg
      //   )
      // : [];
      this.analyseGoogleAnalytics();

      let cartRelatedCat = isArray(matchCategory[0].products)
        ? matchCategory[0].products.filter(
            product => product.product_id !== productId
          )
        : [];
      if (getProductDetails.length) {
        this.setState({
          productDetails: getProductDetails[0],
          similarFoods: getProductDetails[0].suggested_products,
          cartRelatedCat: cartRelatedCat,
          isLoading: false
        });
      } else this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleAddToCart = async (product, productDetails) => {
    let cartData = [...this.props.cartData];
    let findExistProduct = cartData.findIndex(
      cart => cart.product_price_id === product.product_price_id
    );
    if (findExistProduct !== -1) {
      cartData[findExistProduct].productCount =
        cartData[findExistProduct].productCount + 1;
      this.props.addToCart(cartData);
      this.forceUpdate();
    } else {
      product.productCount = 1;
      //adding product details inside the different serving sizes
      product.product_name = productDetails.product_name;
      product.product_desc = productDetails.product_desc;
      product.is_veg = productDetails.is_veg;
      product.category_id = productDetails.category_id;
      let updatedCartData = cartData.concat([product]);
      await this.props.addToCart(updatedCartData);
      this.forceUpdate();
    }
    this.forceUpdate();
  };

  handleDecrementProductCount = async product => {
    let cartData = [...this.props.cartData];
    let findExistProduct = cartData.findIndex(
      cart => cart.product_price_id === product.product_price_id
    );
    if (findExistProduct !== -1) {
      let productCount = cartData[findExistProduct].productCount;
      if (productCount > 1) {
        cartData[findExistProduct].productCount =
          cartData[findExistProduct].productCount - 1;
        this.props.addToCart(cartData);
        this.forceUpdate();
      } else {
        cartData = cartData.filter(
          cart => cart.product_price_id !== product.product_price_id
        );
        await this.props.addToCart(cartData);
        this.forceUpdate();
      }
    }
    this.forceUpdate();
  };
  handleSimilarProductClick = async (categoryId, productId) => {
    this.props.history.push(`./${productId}`);
    let response = await homeService.getBranchLisById(this.props.branchId);
    if (response.data && response.data.length) {
      this.setState({ categories: response.data });
      let matchCategory = response.data.filter(
        category => category.category_id === categoryId
      );
      let getProductDetails = isArray(matchCategory[0].products)
        ? matchCategory[0].products.filter(
            product => product.product_id === productId
          )
        : {};
      let similarFoods = isArray(matchCategory[0].products)
        ? matchCategory[0].products.filter(
            product =>
              product.product_id !== productId &&
              product.is_veg === getProductDetails[0].is_veg
          )
        : [];
      if (getProductDetails.length) {
        this.setState({
          productDetails: getProductDetails[0],
          similarFoods: similarFoods
        });
      }
    }
  };

  getCurrentBranchInfo = () => {
    let branchCategory = this.props.branchCategory;
    if (isArray(branchCategory)) {
      let currentBranchInfo = branchCategory.find(
        branch => branch.branch_id === this.props.branchId
      );
      currentBranchInfo &&
        this.setState({ currentBranchInfo: currentBranchInfo });
    }
  };

  handleShow = categoryId => {
    sessionStorage.setItem("categoryId", categoryId);
    this.props.history.push("../products");
  };

  render() {
    const { cartData } = this.props;
    const currentPage = this.props.location.pathname.split("/")[1];
    const shareUrl = window.location.href;
    return (
      <ProductDetailsPage
        {...this.state}
        cartData={cartData}
        handleAddToCart={this.handleAddToCart}
        handleDecrementProductCount={this.handleDecrementProductCount}
        handleSimilarProductClick={this.handleSimilarProductClick}
        currentPage={currentPage}
        cartRelatedCat={this.state.cartRelatedCat}
        shareUrl={shareUrl}
        handleShow={this.handleShow}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    cartData: state.CartReducer.cartProducts,
    branchId: state.CartReducer.branchId,
    branchCategory: state.CartReducer.branchCategory
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addToCart: products => dispatch(AddToCart(products)),
    setBranchIdAddress: (branchId, address) =>
      dispatch(SetBranchAddress(branchId, address))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
