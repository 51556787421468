import React, { Component } from "react";
import TrackOrderPage from "./track-order-html";
import { checkoutService } from "../../_services/checkout.service";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import ReactGA from "react-ga4";
import { googleAnalyticKey, googleTagKeg, facebookPixalKey } from '../../env';
import { ReactPixel } from 'react-facebook-pixel';
import {MyEvent} from '../../_actions/trackEvent'
ReactGA.initialize(googleAnalyticKey)
class TrackOrder extends Component {
  constructor() {
    super();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      orderDetail: {}
    };
  }

  componentDidMount() {
    if(this.props.accessToken){
    this.getOrderDetails();
    }else{
      this.props.history.push('/');
    }
    this.analyseGoogleAnalytics();
    this.tagSend();
    // this.facebookPurchase();
  }
  analyseGoogleAnalytics = () => {
    ReactGA.ga(
      "send",
      "pageview",
      `/track-order`
    );
  };
  tagSend = async () => {
    let orderId = this.props.match.params.orderId;
    let response = await checkoutService.getOrderDetails(orderId);
    window.gtag('event', 'purchase', {
      send_to: 'AW-339199008/W6BVCJeuxNECEKCI36EB',
      value: response.data.bill_total,
      currency: 'INR',
      transaction_id: response.data.bill_id,
    });
    // console.log( response.data.bill_total, response.data.bill_id, "total");
  };
  // facebookPurchase = async () => {
  //   let orderId = this.props.match.params.orderId;
  //   let response = await checkoutService.getOrderDetails(orderId);
  //   const ReactPixel =  require('react-facebook-pixel');
  //   ReactPixel.default.init(facebookPixalKey);
  //   ReactPixel.track('Purchase', {
  //     currency: "INR", 
  //     value: response.data.bill_total,
  //     order_id: response.data.bill_id
  //   });
  //   };
  getOrderDetails = async () => {
    let orderId = this.props.match.params.orderId;
    let response = await checkoutService.getOrderDetails(orderId);
    // MyEvent('INR', response.data.bill_total)
    
    if (response.error === "" && response.data.bill_id) {
      this.setState({ orderDetail: response.data });
      
    } else {
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
    }
  
  };

  render() {
    return <TrackOrderPage totalCartData={this.state.orderDetail} />;
  }
}

const mapStateToProps=state=>{
  return{
    accessToken: state.LoginReducer.accessToken
  }
}

export default connect(mapStateToProps)(TrackOrder);
