import { http } from '../_helpers';
/*global google*/
export const commonService = {
    listProducts,
    findLocation,
    listBanner,
    getCouponList,
    // getCategories,
    // getBanners
};
// function getCategories() {
//     return http.get('http://retroerp.com/products/loadMenu/1')
//         .then(data => {
//             return data;
//         });
// }
// function getBanners() {
//     return http.get('http://retroerp.com/manage_branch/loadBanners')
//         .then(data => {
//             return data;
//         });
// }
function listProducts(data) {
    return http.get('/products/loadMenu/1')
        .then(data => {
            return data;
        });
}
function listBanner(data) {
    return http.get('/manage_branch/loadBanners/1')
        .then(data => {
            return data;
        });
}
function getCouponList(data) {
    return http.get('/manage_branch/getCouponList/1')
        .then(data => {
            return data;
        });
}
function findLocation(lat, long) {
    const requestOptions = {
        method: 'GET',
    };
    return fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + long + '&key=AIzaSyBWZ8pckCiE-Qp5ka0FRveEGI8l8vfIt2E', requestOptions)
        .then(function (response) {
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (response.ok) {
                    return data.results
                }
            });
        });
}
