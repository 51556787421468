import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import {Helmet} from "react-helmet";

const TermsPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <Helmet>
                <meta charSet="utf-8"/>
                <title>POTFUL PREMIER LEAGUE 2020 (PPL 2020)</title>
                <meta name="description"
                      content="Order online your choice of authentic Lucknowi Biryani now. Potful delivery in the very same biryani pot in which it is cooked. Lucknowi biryani is more pleasant to the stomach and has a soothing aroma."/>
            </Helmet>
            <section className="mt-5">
                <div className="category_header mb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="heading mb-4"><span>POTFUL PREMIER LEAGUE 2020 (PPL 2020)</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="terms-p">
                <div className="container">
                    <div className="row">
                        <div className="offset-md-1 col-md-10">
                            <h5 className="text-uppercase text-underline">Terms & Conditions</h5>
                            <p>Following are the terms and conditions applicable for Potful users under the said
                                campaign.
                            </p>
                            <p><strong>Promotions – There are 3 (three) promotions in the offering during PPL 2020
                                campaign
                            </strong></p>
                            <ol>
                                <li className="mb-3">Score Runs on Products every time a customer order. Runs applicable for a product is
                                    mentioned below relevant products.
                                </li>
                                <li className="mb-3">Choose a team – When the team wins, we credit 50runs to your potful account. Once
                                    the team is chosen, you will be not be allowed to change the team during the PPL
                                    2020 campaign. Potful doesn’t endorse any specific team and selection of team is
                                    solely an individual’s choice.
                                </li>
                                <li className="mb-3">Score min 50 to max 500 runs on orders above 2000/- through Instant scratch card
                                    offer
                                </li>
                            </ol>
                            <p>The offer is valid only on Potful App (andoid and ios) and website (potful.in) and is
                                valid till 8 th Nov 2020</p>
                            <h5 className="text-uppercase text-underline">Redemption of accumulated RUNS</h5>
                            <ul>
                                <li>You can only redeem maximum 100 runs per transaction and only one transaction is
                                    allowed per day per customer</li>
                                <li>Redemption can be done only on min order value of INR 299/- and above</li>
                                <li>1 (one) Run = 1 (one) Rupee</li>
                                <li>Runs cannot be refunded in the form of cash or cannot be transferred to anyone.
                                    You can only redeem the accumulated runs against purchases from POTFUL</li>
                                <li>You can redeem any accumulated runs on or before 30 th Nov 2020. Any runs left in
                                    the account after 30 th Nov 2020 will be null and void.</li>
                                <li>If the order is cancelled by a customer after crediting the runs, the credited runs will
                                    be withdrawn.</li>
                                <li>Refer runs summary sheet in the app / web for transactions history of runs scored
                                    and used</li>
                            </ul>
                            <h5 className="text-uppercase text-underline">Others</h5>
                            <ul>
                            <li>You would be authorizing Potful India Pvt Ltd to send multiple notifications regarding
                                the campaign.</li>
                            <li>Please contact us on the Whatsapp chat provided in our app / website or write to us
                                at feedback@potful.in for any clarification required on the campaign.</li>
                            <li>Potful Premier league 2020 is a proprietary campaign of Potful India Pvt Ltd,
                                Jakkasandra, Koramangala, Bangalore – 560034.</li>
                            <li>IPL 2020 (Indian Premier league 2020) schedule shown in the app / web is basis the
                            schedule communicated by IPL governing body in the media</li>
                            <li>PPL 2020 is only a promotion campaign around IPL 2020 and has no direct
                                connection with the ongoing IPL 2020 tournament</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
            <ModalsPage/>
        </div>
    )
}
export default TermsPage;
