import React from 'react';
import { NavLink } from 'react-router-dom';
import {config} from "../../../env";

const AppDownloadModal = (props) => {
    return (
        <div className="modal fade login-modal" id="download-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <p className="modal-head">Download App</p>
                        <p className="modal-assist"></p>
                        <div className="app-modal">
                            <form className="form myform">
                                <div className="form-group">
                                    <input type="number" name="sendapplink" className="form-control" placeholder="Mobile" />
                                </div>
                                <button className="btn btn-brand btn-standard btn-block w-100">Send Download Link</button>
                            </form>
                        </div>
                        <div className="links">
                            <ul>
                                <li>
                                    <NavLink to="https://play.google.com/store/apps/details?id=in.potful" target="_blank"><img
                                        className="desaturate " src={`${config.urlPrefix}/images/google-playstore.png`} alt="" /></NavLink>
                                </li>
                                <li>
                                    <NavLink to="https://itunes.apple.com/in/app/potful-biryanis-of-india-delivered-online/id1277286857?mt=8"
                                        target="_blank"><img className="desaturate ios"  src={`${config.urlPrefix}/images/app-store.png`}alt="" /></NavLink>
                                </li>
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <p className="modal-slogan">yours deliciously!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppDownloadModal;
