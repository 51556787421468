import React from 'react';
import { isArray,countTotalItemsOnCart } from "../../../utils/util";

class ShowAddressCard extends React.Component {
    render() {
        const removeLocation = this.props.removeLocation;
        const openCart = this.props.openCart;
        const openFilter = this.props.openFilter;
        const removeRespLocation = this.props.removeRespLocation;
        const respcart = this.props.respcart;
        const page = this.props.page;
        const { address,cardData } = this.props;
        return (
            <div>
                {/*{(page === "main") ?*/}
                {/*    <div className={removeRespLocation}>*/}
                {/*        {removeLocation === "false" ? "" :*/}
                {/*            <div className="select-address d-block d-md-none">*/}
                {/*                <button className="btn-nothing" data-toggle="modal" data-target="#location-modal">*/}
                {/*                    <span className="d-inlinne-block align-middle">Showing Menu for</span> <span className="truncate text-orange text-white">{address}</span></button>*/}
                {/*            </div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    : null*/}
                {/*}*/}
                {(page === "main" || respcart === "false") ? "" :
                    <div className="checkout-home d-block d-md-none">
                        <div className="checkout-action">
                            <button className="btn-nothing" onClick={e => openCart(e)}><i className="fa fa-shopping-cart"></i>
                            Cart({isArray(cardData)
                          ? countTotalItemsOnCart(cardData)
                          : 0})
                            </button>
                        </div>
                        {/*<div className="checkout-filter">*/}
                        {/*    {(page === "details") ?*/}
                        {/*        <button className="btn-nothing"><i className="fa fa-filter opacity-0"></i></button>*/}
                        {/*        :*/}
                        {/*        <button className="btn-nothing" onClick={e => openFilter(e)}><i className="fa fa-filter"></i></button>*/}
                        {/*    }*/}
                        {/*</div>*/}
                        {/*<div className="checkout-location">*/}
                        {/*    <button className="btn-nothing" data-toggle="modal" data-target="#location-modal"> <i className="fa fa-map-marker-alt"></i> </button>*/}
                        {/*</div>*/}
                    </div>
                }
            </div>
        )
    }
}

export default ShowAddressCard;
