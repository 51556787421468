import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";

const KolkataBiryaniPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Best Clay pot Kolkata Biryani Home Delivery In Bangalore</title>
                <meta name="description"
                      content="Get the best Clay pot Kolkata Biryani at your doorstep in Bangalore. Potful provide home delivery of freshly cooked, mouth-watering traditional Kolkata biryanis."/>
            </Helmet>
            <section className="mt-5">
                <div className="category_header mb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="heading mb-4"><span>Potful Calcutta Biryani</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="terms-p">
                <div className="container">
                    <div className="row">
                        <div className="offset-md-1 col-md-10">
                            <p>
                                Somewhere between Rasagullas and lip-smacking fish preparations, many rich Bengali
                                delicacies go unnoticed. And one such delicacy is the Calcutta or Kolkata
                                biryani. One can also blame the greater popularity of its southern counterpart -
                                <NavLink className="text-dark no-hover" to="/restaurant-menu/claypot-hyderabadi-chicken-biryani"><strong>Hyderabadi Biryani</strong></NavLink>,
                                which might have for long prevented the Kolkata style biryani to flourish in all its
                                glory. But Kolkata biryani
                                is slowly working its way out of the canals of the City of Joy and gaining the due
                                recognition it has long deserved. Made with aromatic rice, tender meat and a handful
                                of <em>masalas</em>, the Kolkata biryani has long been delighting the palates of the
                                City of Joy since over a century now.
                            </p>
                            <p>
                                Calcutta or Kolkata biryani evolved from the Lucknow style. The hallmark
                                of Kolkata style biryani is the use of eggs and potatoes. The rice and meat are cooked
                                separately in this style of biryani. Similar to Lucknowi biryani, Kolkata biryani is
                                light and pleasant for the stomach. It is also regarded as a treat for vegetarians since
                                it has potatoes.
                            </p>
                            <h2>Origin of Calcutta Biryani</h2>
                            <p>
                                When the King of Awadh, Nawab Wajid Ali Shah was dethroned by the British in the year
                                1856, he was exiled to Kolkata from his capital Lucknow. Being fond of his tastes and
                                indulgences, and to ensure he didn't have to compromise on either, he brought his entire
                                entourage of chefs along with him. But the indulgences of the Nawab were in a fix due to
                                scarcity of money. It was here that his cooks decided to innovate, and tried various
                                combinations and experiments to enhance the taste of chicken or mutton biryani. On one
                                such occasion potatoes were added while cooking the biryani. It appealed to the taste
                                buds of Nawab Wajid Ali Shah. He was so pleased that he ordered that henceforth whenever
                                biryani is cooked, potatoes must be added! Also, the poorer households of Kolkata could
                                not afford meat and carried on the tradition of using potatoes instead, which went on to
                                become a specialty of the Calcutta biryani. And the rest is history!
                            </p>
                            <h3>How we make the best Calcutta Briyani in Bengaluru</h3>
                            <p>
                                Potful’s Calcutta or Kolkata mutton biryani is exceptionally light, low on essence and
                                colour. It is much lighter on spices, using just the right amount of spice to enhance
                                the taste. We marinate the meat in a dahi-based marinade and flavour it using nutmeg,
                                cinnamon, mace, cloves and cardamom. This combination of spices gives it a distinct
                                flavour as compared to other styles of biryani. The rice is flavoured with ketaki water
                                or rose water along with saffron to give it
                                flavour and light yellowish colour. Rice is steamed with cooked mutton, spices and the
                                much loved browned potatoes in a huge biryani pot. This clay pot biryani is then
                                delivered to your doorstep without breaking the seal. You get to enjoy the delicious
                                aroma of the freshly cooked Kolkata pot biryani as you open and savour it. It is served
                                with accompaniments like boiled egg, or gravy dishes like Chicken and Mutton Korma.
                            </p>
                            <p>
                                Drawing comparison to other biryanis, the <strong>Lucknowi Dum Biryani</strong> relies more on the
                                flavours of the meat than spices to pull it through. As you go down south to the courts
                                of the Nizams of Hyderabad, the Biryani tends to include more masalas.
                                The Kolkata biryani is a sub variant of the Lucknowi-style of biryani<em>.</em> Since
                                this was derived from the Lucknowi dum biryani, it is similar in a lot of aspects, the
                                main difference being the addition of potatoes. A person who does not enjoy rice that
                                much and would love to add eggs and potatoes to it and also for the vegetarians, Kolkata
                                biryani is the best. Most vegetarians find that they do not have any options when it
                                comes to Biryani. For our vegetarian food lovers, Kolkata biryani is the way to go.
                            </p>
                            <p>
                                Biryani is now a common dish in the menu at most food outlets. However, places which
                                inculcate the traditional olden cooking methods and bring you the authentic biryani
                                flavour are rare to find. Potful brings to your home the best biryanis from all over
                                India. We retain the original cooking styles invented as early as the
                                17<sup>th</sup> Century.
                            </p>
                            <p>
                            Potful Calcutta mutton biryani and Calcutta chicken biryani can be ordered online in the original clay
                                pot in which the biryani is cooked, and it is untouched and unopened until it reaches you.</p>
                            <h3>Now get the flavours of Calcutta at your doorstep in Bangalore.</h3>
                            <section>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 mt-4">
                                            <NavLink
                                                className="text-dark no-hover"
                                                to="/restaurant-menu/claypot-calcutta-chicken-biryani/"
                                            >
                                                <div className="product-item">
                                                    <div className="product-img">
                                                        <img
                                                            src={`https://img.restro.app/frontend/images/products/5.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="product_details">
                                                        <h2 className="pl-2 pb-1 text-center">
                                                            Calcutta Dum Biryani
                                                        </h2>
                                                    </div>
                                                    <div className="product_price px-4 pb-0">
                                                        <div className="product_price_item float-left">
                                                            {/*<p><small className="op-less">BLOG POST</small> </p>*/}
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <NavLink className="text-dark no-hover" to="/restaurant-menu/claypot-calcutta-mutton-biryani/">
                                                <div className="product-item">
                                                    <div className="product-img">
                                                        <img
                                                            src={`https://img.restro.app/frontend/images/products/6.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="product_details">
                                                        <h2 className="pl-2 pb-1 text-center">Calcutta Mutton Biryani</h2>
                                                    </div>
                                                    <div className="product_price px-4 pb-0">
                                                        <div className="product_price_item float-left">
                                                            {/*<p><small className="op-less">BLOG POST</small> </p>*/}
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        {/*<div className="col-lg-4 mt-4">*/}
                                            {/*<NavLink className="text-dark no-hover" to="/restaurant-menu/claypot-hyderabadi-mix-veg-biryani/">*/}
                                                {/*<div className="product-item">*/}
                                                    {/*<div className="product-img">*/}
                                                        {/*<img*/}
                                                            {/*src={`https://img.restro.app/frontend/images/products/3.jpg`}*/}
                                                            {/*alt=""*/}
                                                        {/*/>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="product_details">*/}
                                                        {/*<h2 className="pl-2 pb-1 text-center">*/}
                                                            {/*Vegetarian Calcutta Biryani*/}
                                                        {/*</h2>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="product_price px-4 pb-0">*/}
                                                        {/*<div className="product_price_item float-left">*/}
                                                            {/*/!*<p><small className="op-less">BLOG POST</small> </p>*!/*/}
                                                        {/*</div>*/}
                                                        {/*<div className="clearfix"></div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</NavLink>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </section>
                            <p>
                                Now get the flavours of Calcutta at your doorstep in Bangalore. We provide home delivery
                                of freshly cooked, mouth-watering traditional biryanis from across India. Kolkata dum
                                biryanis and other pot biryani variations can now be ordered on our site and you can download <a href="https://play.google.com/store/apps/details?id=in.potful">Android</a> and <a
                                href="https://itunes.apple.com/in/app/potful-biryanis-of-india-delivered-online/id1277286857?mt=8">iOS</a> application.
                            </p>
                            <p>
                                Feel like pot biryani kind of day? Don’t worry! Keep calm. Go off your kitchen duties.
                                <NavLink className="text-dark no-hover" to="/restaurant-menu"><strong> Order your choice of Potful clay pot biryani</strong></NavLink>. And, <NavLink className="text-dark no-hover" to="/offers"><strong>enjoy an additional discount of 20%
                                on all orders!</strong></NavLink>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
            <ModalsPage/>
        </div>
)
}
export default KolkataBiryaniPage;
