import React from "react";
import SimilarFoodCard from "./similarFoodCard";
import { isArray } from "../../../utils/util";
const similarFoods = props => {
  const {
    similarFoods,
    cartData,
    handleAddToCart,
    currentBranchInfo,
    handleDecrementProductCount,
    handleSimilarProductClick
  } = props;
  return (
    <section className="also">
      <div className="row">
        <div className="col-lg-12">
          <h4 className="heading">Related Products</h4>
        </div>
        {isArray(similarFoods) &&
          similarFoods.map(food => (
            Number(food.status) !== -1 && Object.keys(food).length &&
            <SimilarFoodCard
              key={food.product_id}
              food={food}
              cartData={cartData}
              handleAddToCart={handleAddToCart}
              handleDecrementProductCount={handleDecrementProductCount}
              handleSimilarProductClick={handleSimilarProductClick}
              currentBranchInfo={currentBranchInfo}
            />
          ))}
      </div>
    </section>
  );
};

export default similarFoods;
