import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import {config} from "../../env";
import {Helmet} from "react-helmet";

const AboutUsPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About - Retro in Metro</title>
                <meta name="description"
                      content="We, the Retro In Metro team, are trying to bring back the golden heritage of a long forgotten
                        natural way of consuming millets in the form of RetroSip® millet malts that blends with our current
                        metro lifestyle. #RetroFeelingInMetroLifestyle"/>
            </Helmet>
            <div className="container">
                <section className='mt-3'>
                    <h2>About Us</h2>
                    <hr/>
                    <p>In this world of food adulteration, chemical fertilizers, and fad foods, we always envy our
                        grandparents’ healthy lifestyle with longer life span.</p>
                    <p>Millets were the first cereal grains that were an important part of our forefathers’ healthy eating
                        habits.</p>
                    <p> We, the Retro In Metro team, are trying to bring back the golden heritage of a long forgotten
                        natural way of consuming millets in the form of RetroSip® millet malts that blends with our current
                        metro lifestyle. #RetroFeelingInMetroLifestyle</p>
                    <p><strong>
                        It’s our proud feeling that we have introduced the “first ever single millet malts“, which is the
                        ONLY correct way of sipping the millet malts.
                    </strong></p>



                    <h2>Our fundamental philosophy:</h2>
                    <p className="quote">“Eat the right food, in the right way.”</p>

                    <p>We all know the impressive health benefits of millet consumption, but to avail their maximum
                        nutritional benefits, millet should be consumed in the right way.</p>

                    <h2>As per doctors and nutrition experts:</h2>

                    <ul>
                        <li>Millets should not be mixed (multi millet food is not at all a healthy option).</li>
                        <li>A single millet should be consumed for at least three days regularly.</li>
                        <li>Millets should be soaked for 5 hours (minimum) before consuming.</li>
                    </ul>
                    <p>
                        By considering the above criteria, RetroSip® single millet malts are prepared using the traditional
                        malting process where millets have been soaked for 6–8 hours and cereals have been sprouted and sun
                        dried so that we can serve you the premium quality healthy millet malts which provide miraculous
                        health benefits. #HealthBackGuaranteed
                    </p>
                    <p>
                        <strong>
                            Can we mix millets? Why to soak and sundry millets before consuming? Let’s hear it from “Millet Man
                            of India”.
                        </strong>
                    </p>

                    <iframe width="100%" height="500px" src="https://www.youtube.com/embed/_ATZDXNAroQ"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>


                </section>
            </div>

            <Footer/>
            <ModalsPage/>
        </div>
    )
}
export default AboutUsPage;
