import React, { Component } from 'react';
import TermsPage  from './iplterms-html';
class Iplterms extends Component {
    constructor(props){ 
        super(props)
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    render() {
        return(
            <TermsPage 
            />
        )
    }
}
export default Iplterms;