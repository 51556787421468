/* eslint-disable no-undef */
/* global google */
import React, {Component} from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng} from "react-places-autocomplete";
import {config} from "../../../env";
//
// const google = window.google;
// const maps = window.maps;

class SearchLocationModal extends Component {

    onError = (status, clearSuggestions) => {
        // console.log('Google Maps API returned error with status: ', status)
        clearSuggestions()
    };

    render() {
        const google = window.google;
        const searchOptions = {
            location: new google.maps.LatLng(12.983425, 77.7519053),
            radius: 2000,
            // types: ["address"]
        };
        const {addressFromHeader} = this.props;
        return (
            <div
                className="modal fade location-modal"
                id="location-modal"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog"
                    role="document"
                >
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    />
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="location-header">
                            </div>
                            <div className="location-body">
                                <PlacesAutocomplete
                                    value={this.props.address}
                                    onChange={this.props.handleChange}
                                    onSelect={this.props.handleSelect}
                                    onError={this.onError}
                                    searchOptions={searchOptions}
                                >
                                    {({
                                          getInputProps,
                                          suggestions,
                                          getSuggestionItemProps,
                                          loading
                                      }) => (
                                        <div className="position-relative">
                                            <p className="d-md-none">Showing menu for <span
                                                className="truncate text-orange">{addressFromHeader && addressFromHeader}</span>
                                            </p>
                                            <div className="form-group mb-0">
                                                <div className="location-icons">
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: "Where are you?",
                                                            className: "location-search-input form-control"
                                                        })}
                                                    />
                                                    <img src={`${config.urlPrefix}/images/location-icon.svg`} alt=""/>
                                                </div>
                                            </div>
                                            <ul className="autocomplete-dropdown-container">
                                                {loading && <li>Loading...</li>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? "suggestion-item--active"
                                                        : "suggestion-item";
                                                    return (
                                                        <li
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                            <span className="location-i">
                                  <img src={`${config.urlPrefix}images/location-icon-li.svg`} alt=""/>
                                </span>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <div className="location-footer">
                                <button
                                    onClick={this.props.handleCurrentLocationClick}
                                    className="btn-nothing"><img src={`${config.urlPrefix}/images/gps.svg`} alt=""/>Locate
                                    Me
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchLocationModal;
