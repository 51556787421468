import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon as ReactPolygon
} from "react-google-maps";


const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBWZ8pckCiE-Qp5ka0FRveEGI8l8vfIt2E&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: props.chosenLat, lng: props.chosenLng }}
    center={props.center}
    defaultOptions={{ mapTypeControl: false, fullscreenControl: false }}  >
    {props.isMarkerShown && (
      <Marker
        // icon=""
        defaultDraggable={true}
        onDrag={coordinate => props.onMarkerDragEnd(coordinate)}
        position={{ lat: props.chosenLat, lng: props.chosenLng }}
        lat={props.chosenLat}
        lng={props.chosenLng}
      />
    )}

    {/*<ReactPolygon*/}
    {/*  path={props.polygonCoordinates}*/}
    {/*  key={1}*/}
    {/*  editable={false}*/}
    {/*  options={{*/}
    {/*    fillColor: "#e74c3c",*/}
    {/*    fillOpacity: 0.4,*/}
    {/*    strokeColor: "#e74c3c",*/}
    {/*    strokeOpacity: 1,*/}
    {/*    strokeWeight: 1*/}
    {/*  }}*/}
    {/*/>*/}
  </GoogleMap>
));

export default MyMapComponent;
