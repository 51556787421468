import React, { Component } from "react";
import { connect } from "react-redux";
import { authService } from "../../_services/auth.service";
import { toast } from "react-toastify";
import {
  LoginModal,
  OtpVerifyModal,
  AppDownloadModal,
  SignupModal,
    PreModal,

} from "./modalComponent";
import $ from "jquery";
import { userLogin, userSignup, otpVerify } from "../../_reducers/loginReducer";
import ReactGA from "react-ga4";
import { googleAnalyticKey } from "../../env";

ReactGA.initialize(googleAnalyticKey);
class ModalsPage extends Component {
  state = {
    mobileNo: ""
  };

  handleUserLogin = async mobileNo => {
    ReactGA.ga("send", "pageview", `/login`);
    const data = { phone: Number(mobileNo.phone) };
    let response = await authService.postOtpLogin(data);
    this.setState({ mobileNo: mobileNo.phone });
    if (response.data.customer_phone) {
      $(".otp-modal").click();
      $(".login-modal .close").click();
    } else {
      let errorMessage = response.errors;
      toast.error(errorMessage + 'Please signup');
    }
  };

  handleUserPasswordLogin = async formData => {
    ReactGA.ga("send", "pageview", `/login`);
    const data = { phone: Number(formData.phone),password:formData.password };
    let response = await authService.postPasswordLogin(data);
    if (
        response &&
        response.data.customer_phone !== "" &&
        response.data.customer_phone !== undefined
    ) {
      this.props.otpVerify(response.data);
      toast.success("Login Successful");
      window.location.reload(true);
    } else {
      ReactGA.ga("send", "pageview", `/login_failed`);
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
    }
  };


  handleSocialUserLogin = async (data, socialMediaType) => {
    let payload = {
      email: data.U3,
      first_name: data.ofa,
      last_name: data.wea,
      social_media_id: data.Eea,
      channel: socialMediaType
    };
    let response = await authService.postSocialLogin(payload);
    if (
      response &&
      response.data.customer_phone !== "" &&
      response.data.customer_phone !== undefined
    ) {
      this.props.otpVerify(response.data);
      toast.success("Login Successful");
    } else {
      ReactGA.ga("send", "pageview", `/signup`);

      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
    }
  };

  handleUserSignup = async values => {
    ReactGA.ga("send", "pageview", `/login`);

    values = {
      ...values,
      phone: Number(values.phone),
      gender:1
    };

    let response = await authService.postUserSignup(values);
    this.setState({ mobileNo: values.phone });
    if (
      response &&
      response.data.customer_phone !== "" &&
      response.data.customer_phone !== undefined
    ) {
      $(".otp-modal").click();
      toast.success("Otp sent successfully");
      $(".login-modal .close").click();
    } else {
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
    }
  };

  handleUserSignupWithPassword = async values => {
    ReactGA.ga("send", "pageview", `/login`);

    values = {
      ...values,
      phone: Number(values.phone),
      gender:1
    };

    if(values.password!==values.confirm_password){
      toast.error("Password do not match");
      return;
    }
    let response = await authService.postUserSignupWithPassword(values);
    this.setState({ mobileNo: values.phone });
    if (
        response &&
        response.data.customer_phone !== "" &&
        response.data.customer_phone !== undefined
    ) {
      this.props.otpVerify(response.data);
      toast.success("Login Successful");
      window.location.reload(true);
    } else {
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
    }
  };

  handleOtpVerification = async data => {
    ReactGA.ga("send", "pageview", `/verify_otp`);
    let payload = {
      phone: Number(this.state.mobileNo),
      otp_text: Number(data.otp),
      channel: 2
    };
    let response = await authService.postOtpVerify(payload);
    if (
      response &&
      response.data.customer_phone !== "" &&
      response.data.customer_phone !== undefined
    ) {
      this.props.otpVerify(response.data);
      $(".login-modal .close").click();
      toast.success("Login Successful");
      window.location.reload(true);
    } else {
      ReactGA.ga("send", "pageview", `/login_failed`);
      let errorMessage = response.errors || response.error_message;
      toast.error(errorMessage);
    }
  };

  render() {
    return (
      <div>
        <AppDownloadModal />
        <PreModal />
        <LoginModal
          handleUserLogin={this.handleUserLogin}
          handleSocialUserLogin={this.handleSocialUserLogin}
        />
        <SignupModal handleUserSignup={this.handleUserSignup} />
        <OtpVerifyModal handleOtpVerification={this.handleOtpVerification} />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userList: state.LoginReducer.isLoggedIn,
    otpSended: state.LoginReducer.otpSended,
    mobileNo: state.LoginReducer.mobileNo,
    otpVerified: state.LoginReducer.otpVerified
  };
};
const mapDispatchToProps = dispatch => {
  return {
    loginUser: mobileNo => dispatch(userLogin(mobileNo)),
    userSignup: values => dispatch(userSignup(values)),
    otpVerify: data => dispatch(otpVerify(data))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalsPage);
