import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import { Helmet } from "react-helmet";
import {NavLink} from "react-router-dom";
import {config} from "../../env";

const HyderabadiBiryaniPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Order Hyderabadi Dum Biryani Online - Potful</title>
                <meta name="description" content="Order Hyderabadi Dum Biryani online for Home or office delivery. Just use our site or download our app to order in your favorite Hyderabadi biryani and give your taste buds the experience of a lifetime."/>
            </Helmet>
            <section className="mt-5">
                <div className="category_header mb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="heading mb-4"><span>Potful Hyderabadi Biryani</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="terms-p">
                <div className="container">
                    <div className="row">
                        <div className="offset-md-1 col-md-10">
                            <p>
                                For foodies, if there’s one thing that makes Hyderabad stand out, it has to be its
                                delectable biryani. It is known across the country for serving the spiciest and the most
                                delicious biryanis ever.
                            </p>
                            <p>
                                <strong>Hyderabadi Biryani</strong> is a popular variety of Biryani in India, and the Hyderabadi biryani
                                is so named as it is seen mainly in the city of Hyderabad.
                            </p>
                            <p>
                                Hyderabadi biryani is generally believed to have originated in the kitchens of the Nizam
                                of Hyderabad, who was
                                a leader of the historical Hyderabad state. Considered a staple part of Indian cuisine,
                                it originated as
                                a blend of Mughlai and
                                Iranian cuisine. Hyderabadi biryani, like other biryanis, is made using Basmati rice
                                which is
                                only found on the Indian subcontinent.
                            </p>
                            <p>
                                The spices and
                                other ingredients remain the same, however the method of preparation involves more time
                                and sophistication. Fewer spices are used than in this traditional cooking technique
                                with fresh spices and herbs for flavouring. The ingredients are basmati rice, goat meat
                                or chicken or beef, dahi, onions, spices, lemon saffron to name a few. Hyderabadi
                                biriyani uses fine and long grained basmati rice which looks like pearl, each grain
                                separate and distinct. Coriander
                                leaves and fried onions are used as garnish. The original dish
                                includes red meat but chicken, eggs or vegetables are also used for some variations.
                            </p>
                            <h2>The Dum Cooking method:</h2>
                            <p>
                                We at Potful, prepare the Hyderabadi biryani using the dum method of
                                cooking. <em>Dum</em> means to 'breathe in' and <em>pukht</em> means to 'cook.' Dum
                                pukht cooking uses a round, heavy-bottomed pot, preferably a <em>handi</em> or a clay
                                biryani pot, in which food is sealed and cooked over a slow fire. Hence, it is also
                                called pot biryani. The two main aspects to this style of cooking
                                are: <em>bhunao</em> and <em>dum</em>, or 'roasting' and 'maturing' of a prepared dish.
                                In this cuisine, herbs and spices are important. The process of slow roasting gently
                                allows each ingredient to release their maximum flavor. The sealing of the lid of the
                                biriyani pot achieves maturing. Cooking slowly in its juices, the food retains all its
                                natural aromas.
                            </p>
                            <p>
                                At Potful, like in ancient times, dough is spread over the clay pot, like a lid to seal
                                the veg, chicken or mutton dum biriyani being cooked. This is known
                                as <em>pardah</em> or veil. Upon cooking, it becomes a bread which has absorbed the
                                flavours of the food. In the end, dum pukht food is about aroma when the seal is broken
                                on the table and the fragrance of a Persian past floats in the air. This is an
                                experience which you can enjoy with Potful since the pot is unopened and rice is
                                untouched until delivered.
                            </p>
                            <h3>Types:</h3>
                            <p>
                                Hyderabadi biryani is of two types: the <em>kachchi</em> or raw biryani, and
                                the <em>pakki</em> or cooked biryani.
                            </p>
                            <h3>Kachchi gosht ki biryani</h3>
                            <p>
                                The <em>kachchi</em> biryani is prepared with <em>kachchi gosht</em>, which means raw
                                meat, marinated with spices overnight and then soaked in curd before cooking. The meat
                                is sandwiched between layers of fragrant basmati rice and cooked in the dum biriyani
                                cooking method, sealing the pot biryani with dough. This is a challenging process as it
                                requires meticulous attention to time and temperature to avoid over- or under-cooking
                                the meat.
                            </p>
                            <h3>Pakki biryani</h3>
                            <p>
                                In a <em>pakki</em> biryani, the meat is marinated for a shorter time and cooked before
                                being layered with the rice and cooked in a dough-sealed vessel. Another version of
                                pakki biryani is called pakki aqni and contains cooked gravy. Here, the ingredients are
                                cooked before baking. Saffron and cardamom are used for flavouring.
                            </p>
                            <p>
                                There is also a vegetarian version of the biryani
                                which is made using vegetables such as carrots, peas, cauliflower, potatoes, and
                                cashews. The vegetarian version is
                                called 'tarkari' biryani. The <NavLink className="text-dark no-hover" to="/restaurant-menu/claypot-hyderabadi-mix-veg-biryani/"><strong>Hyderabadi mix veg biryani </strong></NavLink> version of the Vegetable
                                Biryani is the "<em>Tahiri</em>".
                            </p>
                            <p>
                                The biryani is usually served with curd chutney and mirchi ka
                                salan or curried chilli peppers. The salad includes onion, carrot, cucumber, and lemon
                                wedges.
                            </p>
                            <p>
                                So what are you waiting for? Open that App, order your favourite Potful biryani and give
                                your taste buds the experience of a lifetime!
                            </p>
                            <p>You can now buy biryani online for home or office delivery. Just use our site or download our app to
                                order in your favourite Potful Claypot.</p>
                            <section>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3 mt-4">
                                            <NavLink
                                                className="text-dark no-hover"
                                                to="/restaurant-menu/claypot-hyderabadi-chicken-biryani"
                                            >
                                                <div className="product-item">
                                                    <div className="product-img">
                                                        <img
                                                            src={`https://img.restro.app/frontend/images/products/1.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="product_details">
                                                        <h2 className="pl-2 pb-1 text-center">
                                                            Hyderabadi chicken biryani
                                                        </h2>
                                                    </div>
                                                    <div className="product_price px-4 pb-0">
                                                        <div className="product_price_item float-left">
                                                            {/*<p><small className="op-less">BLOG POST</small> </p>*/}
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-lg-3 mt-4">
                                            <NavLink className="text-dark no-hover" to="/restaurant-menu/claypot-hyderabadi-mutton-biryani/">
                                                <div className="product-item">
                                                    <div className="product-img">
                                                        <img
                                                            src={`https://img.restro.app/frontend/images/products/2.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="product_details">
                                                        <h2 className="pl-2 pb-1 text-center">Hyderabadi mutton biryani,</h2>
                                                    </div>
                                                    <div className="product_price px-4 pb-0">
                                                        <div className="product_price_item float-left">
                                                            {/*<p><small className="op-less">BLOG POST</small> </p>*/}
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-lg-3 mt-4">
                                            <NavLink className="text-dark no-hover" to="/restaurant-menu/claypot-hyderabadi-mix-veg-biryani/">
                                                <div className="product-item">
                                                    <div className="product-img">
                                                        <img
                                                            src={`https://img.restro.app/frontend/images/products/3.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="product_details">
                                                        <h2 className="pl-2 pb-1 text-center">
                                                            Hyderabadi mix veg biryani
                                                        </h2>
                                                    </div>
                                                    <div className="product_price px-4 pb-0">
                                                        <div className="product_price_item float-left">
                                                            {/*<p><small className="op-less">BLOG POST</small> </p>*/}
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-lg-3 mt-4">
                                            <NavLink className="text-dark no-hover" to="/products/claypot-hyderabadi-paneer-tikka-biryani/">
                                                <div className="product-item">
                                                    <div className="product-img">
                                                        <img
                                                            src={`https://img.restro.app/frontend/images/products/4.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="product_details">
                                                        <h2 className="pl-2 pb-1 text-center">Hyderabadi paneer tikka biryani</h2>
                                                    </div>
                                                    <div className="product_price px-4 pb-0">
                                                        <div className="product_price_item float-left">
                                                            {/*<p><small className="op-less">BLOG POST</small> </p>*/}
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/*<p>*/}
                                {/*Potful provides online food delivery and is dedicated to bringing the authentic taste of*/}
                                {/*Hyderabadi biryani at your doorstep. A good day, a good biryani and a good burp is now*/}
                                {/*just a click away for all food lovers in Bangalore. You can now buy biriyani online for*/}
                                {/*home or office delivery. Just use our site or download our app*/}
                                {/*to order in your favourite Potful clay pot Hyderabadi chicken biriyani, Hyderabadi*/}
                                {/*mutton biriyani, Hyderabadi mix veg biriyani and Hyderabadi paneer tikka biryani.*/}
                            {/*</p>*/}
                            <p>
                                What’s more? To showcase our gratitude for all the love showered on us, we are running
                                an online food order offer just for you. Enjoy an exclusive <NavLink className="text-dark no-hover" to="/offers/"><strong>20% off on all your online
                                food deliveries from Potful!</strong></NavLink>
                            </p>
                            <h3>Lucknowi and Hyderabadi Biriyani: A comparison</h3>
                            <p>
                                In Hyderabad, they use boiled eggs and use only 10% cooked rice and raw marinated mutton
                                chunks to be cooked in the raw papaya paste, yoghurt and lemon juices. It is called
                                'kachhi' method where both the principal ingredients are almost raw. But interestingly
                                Hyderabadi biryani omits the use of Mughlai mitha attar, which is used generously in
                                Lucknowi Biryani. Both types however, use saffron and fried onions for flavouring.
                                However, the cooking style varies.
                            </p>
                            <p>
                                Hyderabadi biriyani uses fine and long grained basmati rice which looks like pearl. Each
                                grain is separate and distinct. On the other hand, Lucknow uses a lesser version of
                                basmati. Also, the rice is soaked before cooking in Lucknowi Biryani.
                            </p>
                            <p>
                                Hyderabadi Biryani is rich in spices whereas one would find a lack of the same in its
                                Lucknowi counterpart. Mitha attar and the use of kewra however, give an intensity of
                                aroma which makes up for this omission. Eggs are not used in Lucknowi biryani which is
                                one of the ornaments of Hyderabadi biryani.
                            </p>
                            <p>
                                As for the style of cooking, Lucknowi vs Hyderabadi symbolizes 'Pakki' vs 'Kachhi'.
                                Although Hyderabadi dum biriyani can be cooked in either way, the traditional method
                                involves the Kachhi style of cooking. The difference to be highlighted here is how much
                                the meat is cooked.
                            </p>
                            <p>
                                In the <NavLink className="text-dark no-hover" to="/products/claypot-lucknowi-mutton-biryani/"><strong>Lucknowi mutton biryani</strong></NavLink>, the staple ingredients which are rice and the meat, are
                                70-80% pre-cooked. Then it is set for a slow dum, in a deep bottomed vessel, closing the
                                lid, wrapping it around with a dough, on a slow and smothering fire.
                            </p>
                            <p>
                                In the case of the Hyderabadi mutton biryani, 'Kachhi' is a method where the 10% cooked
                                rice is cooked in an hour with the marinated meat, getting cooked in the beaten yoghurt.
                                The result is brilliant with the rice having acquired the entire flavour of the mutton
                                juices, infused with saffron. The mutton is so soft that it literally comes out of its
                                bone.
                            </p>
                            <p>
                                <strong>
                                    Potful provides online food delivery and is dedicated to bringing the authentic taste of
                                    Hyderabadi biryani at your doorstep. A good day, a good biryani and a good burp is now just
                                    a click away for all food lovers in Bangalore.
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
            <ModalsPage/>
        </div>
    )
}
export default HyderabadiBiryaniPage;
