import React from "react";
import {NavLink} from "react-router-dom";
import {checkAllCartItemInStock, isArray} from "../.../../../../utils/util";
import MyMapComponent from "./googleMap";
import PlacesAutocomplete from "react-places-autocomplete";
import {config} from "../../../env";
import Loader from "../../_common/loader";

const checkoutAddress = props => {
    const {
        addressList,
        addressType,
        typeOfAddress,
        address_apartment,
        address_area,
        address_city,
        address_pincode,
        address_state,
        address_landmark,
        valid_address_apartment,
        valid_address_area,
        valid_address_city,
        valid_address_pincode,
        valid_address_state,
        handleAddressChange,
        handleAddressType,
        selAddressId,
        handleDeliveryAddressSelection,
        handleOrder,
        categories,
        cartData,
        isAddAddressClick,
        isSubmitClicked,
        isAddressLoading,
    } = props;

    const google = window.google;
    const searchOptions = {
        location: new google.maps.LatLng(12.983425, 77.7519053),
        radius: 3,
        // types: ["address"]
    };
    // let google_edited = props.addressByMap
    const center = {lat: props.chosenLat, lng: props.chosenLng};
    return (
        <div className="col-lg-8 col-md-7  mt-md-0 mt-4">
            <div className="mb-2">
                <p className="subheader pt-2">Saved Address</p>
                <div className="clearfix"></div>
            </div>
            <div className="address-display mb-0">
                {isAddressLoading ? (
                    <Loader/>
                ) : (
                    <div className="row">
                        {isArray(addressList) &&
                            addressList.map(address => (
                                <div key={address.address_id} className="col-lg-12">
                                    <div className="box address-item">
                                        <label
                                            htmlFor={address.address_id}
                                            className="cursor-pointer"
                                        >
                                            <h3>
                        <span className="float-left">
                          {Number(address.address_type) === 1
                              ? "Home"
                              : Number(address.address_type) === 2
                                  ? "Office"
                                  : "Other"}
                        </span>{" "}
                                                <span className="float-right">
                          <div className="radio-container">
                            <input
                                onChange={() =>
                                    handleDeliveryAddressSelection(
                                        address.address_id,
                                        address
                                    )
                                }
                                checked={
                                    Number(selAddressId) ===
                                    Number(address.address_id)
                                        ? true
                                        : false
                                }
                                type="radio"
                                name="address"
                                id={address.address_id}
                            />
                            <span className="checkmark"/>
                          </div>
                        </span>
                                                <b className="clearfix"/>
                                            </h3>
                                            <p>
                                                {address.address_text} {address.google_address}
                                            </p>
                                        </label>
                                    </div>
                                </div>
                            ))}
                        {!isArray(addressList) &&
                            (
                                <div className="no-address" onClick={props.handleAddAddress}>
                                    <img src="/images/add-address.svg" alt=""/> <br/>
                                    <p>Please add address to continue</p>
                                </div>
                            )}
                    </div>
                )}
            </div>
            <div className="separate mb-3 mt-0 text-center">

                <button
                    onClick={props.handleAddAddress}
                    className="btn btn-secondary btn-bg"
                >
                    Add Address
                </button>
            </div>
            {props.isMapAddressEnable && (
                <div className="map-holder">
                    <div className="map-address">
                        <div className="add-address">
                            <form action="">
                                <label>Address Type</label>
                                <select
                                    value={typeOfAddress}
                                    name=""
                                    id=""
                                    onChange={handleAddressType}
                                    className="form-control"
                                >
                                    {isArray(addressType) &&
                                        addressType.map(address => (
                                            <option key={address.key} value={address.key}>
                                                {address.value}
                                            </option>
                                        ))}
                                </select>

                                <label>Flat/House No,  Building/Company/Apartment* </label>
                                <input
                                    style={{borderColor: !props.valid_address_apartment ? "red" : ""}}
                                    value={address_apartment}
                                    onChange={e => handleAddressChange(e, "address_apartment")}
                                    type="text"
                                    maxLength={50}
                                    className="form-control"
                                    placeholder="Flat/House no, Building/Company/Apartment"
                                />

                                <label>Area*</label>
                                <input
                                    style={{borderColor: !props.valid_address_area ? "red" : ""}}
                                    value={address_area}
                                    onChange={e => handleAddressChange(e, "address_area")}
                                    type="text"
                                    maxLength={50}
                                    className="form-control"
                                    placeholder="Area, Street, Sector, Village"
                                />

                                <label>City*</label>
                                <input
                                    style={{borderColor: !props.valid_address_city ? "red" : ""}}
                                    value={address_city}
                                    onChange={e => handleAddressChange(e, "address_city")}
                                    type="text"
                                    maxLength={30}
                                    className="form-control"
                                    placeholder="Town/City"
                                />

                                <label>Pincode*</label>
                                <input
                                    style={{borderColor: !props.valid_address_pincode ? "red" : ""}}
                                    value={address_pincode}
                                    onChange={e => handleAddressChange(e, "address_pincode")}
                                    className="form-control"
                                    placeholder="Pincode"
                                    maxLength={6}
                                />

                                <label>State*</label>
                                <select
                                    value={address_state} className="form-control"
                                    onChange={e => handleAddressChange(e, "address_state")}
                                    style={{borderColor: !props.valid_address_state ? "red" : ""}}
                                >
                                    <option>State</option>
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                    <option value="Assam">Assam</option>
                                    <option value="Bihar">Bihar</option>
                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                    <option value="Goa">Goa</option>
                                    <option value="Gujarat">Gujarat</option>
                                    <option value="Haryana">Haryana</option>
                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                    <option value="Jharkhand">Jharkhand</option>
                                    <option value="Karnataka">Karnataka</option>
                                    <option value="Kerala">Kerala</option>
                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                    <option value="Maharashtra">Maharashtra</option>
                                    <option value="Manipur">Manipur</option>
                                    <option value="Meghalaya">Meghalaya</option>
                                    <option value="Mizoram">Mizoram</option>
                                    <option value="Nagaland">Nagaland</option>
                                    <option value="Odisha">Odisha</option>
                                    <option value="Punjab">Punjab</option>
                                    <option value="Rajasthan">Rajasthan</option>
                                    <option value="Sikkim">Sikkim</option>
                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                    <option value="Telangana">Telangana</option>
                                    <option value="Tripura">Tripura</option>
                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                    <option value="Uttarakhand">Uttarakhand</option>
                                    <option value="West Bengal">West Bengal</option>
                                </select>

                                {/*<label>Landmark</label>*/}
                                {/*<input*/}
                                {/*    value={address_landmark}*/}
                                {/*    type="text"*/}
                                {/*    className="form-control"*/}
                                {/*    placeholder="Landmark"*/}
                                {/*    maxLength={50}*/}
                                {/*/>*/}

                            </form>
                            {isAddAddressClick ? (
                                <Loader/>
                            ) : (
                                <button
                                    onClick={props.handleCreateAddress}
                                    className="btn btn-brand"
                                >
                                    Save{" "}
                                </button>
                            )}

                            <div className="clearfix"/>
                        </div>
                    </div>
                </div>
            )}

            <div className="payment-options">
                <p className="subheader text-left fw-400">Payment</p>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="box">
                            <label htmlFor="razorpay" className="cursor-pointer">
                                <div className="radio-container">
                                    <input
                                        onChange={() => props.handleSelectPayment(5)}
                                        checked={props.selectedPaymentType === 5}
                                        type="radio"
                                        name="payment"
                                        // checked
                                        id="razorpay"
                                    />
                                    <span className="checkmark"/>
                                </div>
                                <img
                                    className="mr-3"
                                    src={`${config.urlPrefix}/images/onlinepayment.svg`}
                                    alt=""
                                />
                                <span className="d-inline-block mt-2 pt-1">Online Payment</span>
                                <span className="clearfix"/>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="separate mt-3">
                <p className="text-center">

                    {checkAllCartItemInStock(categories, cartData) ? (
                        !isSubmitClicked ? (
                            <NavLink
                                onClick={handleOrder}
                                to="#"
                                className="btn btn-brand btn-full btn-standard"
                            >
                                Checkout
                            </NavLink>
                        ) : (
                            <div className="text-center">
                                <button
                                    disabled="disabled"
                                    className="btn btn-brand btn-full btn-standard on-h"
                                >
                                    Please wit...
                                </button>
                                <br/>
                                <div className="lds-ripple">
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        )
                    ) : (
                        <button
                            disabled="disabled"
                            className="btn btn-brand btn-full btn-standard"
                            gtag_report_conversion
                        >
                            Checkout
                        </button>
                    )}
                </p>
            </div>
        </div>
    );
};

export default checkoutAddress;
