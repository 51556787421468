import React from "react";
import Header from "../_common/header";
import {NavLink} from "react-router-dom";
import Helmet from 'react-helmet';
import {
    CheckoutOrderCardDetail,
    SignupLogin,
    CheckoutAddress
} from "./component";
import Loader from '../_common/loader';

const CheckoutPage = props => {
    const {
        isLoggedIn,
        cartData,
        couponList,
        coupon_text,
        google_edited,
        currentBranchInfo,
        startServingHour,
        endServingHour,
        categories,
        handleRemoveCoupon,
        isDateRequired,
        isValidCouponCode,
        isAddAddressClick,
        verifyingPayment,
        isAddressLoading,
        nextDayOrder
    } = props;
    return (
        <div>
            <Helmet>
                <title>Checkout - Retro in Metro</title>
            </Helmet>
            <Header
                page="main"
                checkoutpageclassName="checkoutcss"
                headerclassName="main"
                removeLocation="true"
                respcart="false"
                removeRespLocation="d-none"
            />
            {verifyingPayment ? <Loader payment={true}/> :
                <section className="checkout-page">
                    <div className="container">
                        {/*<div className="text-right mb-3">*/}
                        {/*  <NavLink*/}
                        {/*    to="/products"*/}
                        {/*    className="btn btn-secondary back-tomenu  d-md-none"*/}
                        {/*  >*/}
                        {/*    Back to Menu*/}
                        {/*  </NavLink>*/}
                        {/*</div>*/}
                        <div className="row">
                            <CheckoutOrderCardDetail
                                nextDayOrder={nextDayOrder}
                                isLoggedIn={isLoggedIn}
                                couponList={couponList}
                                isValidCouponCode={isValidCouponCode}
                                use_wallet={props.use_wallet}
                                isDateRequired={isDateRequired}
                                currentBranchInfo={currentBranchInfo}
                                categories={categories}
                                startServingHour={startServingHour}
                                endServingHour={endServingHour}
                                couponCode={coupon_text}
                                cartData={cartData}
                                handleAddToCart={props.handleAddToCart}
                                handleDecrementProductCount={props.handleDecrementProductCount}
                                deliverNow={props.deliverNow}
                                deliverLater={props.deliverLater}
                                totalCartData={props.totalCartData}
                                handleCouponChange={props.handleCouponChange}
                                handleApplyCoupon={props.handleApplyCoupon}
                                handleCouponSelect={props.handleCouponSelect}
                                handleOrderDateChange={props.handleOrderDateChange}
                                startDate={props.deliveryDate}
                                handleRemoveCoupon={handleRemoveCoupon}
                                handleWalletCheck={props.handleWalletCheck}
                            />
                            {!isLoggedIn && (
                                <SignupLogin
                                    handleUserLogin={props.handleUserLogin}
                                    handleUserSignup={props.handleUserSignup}
                                    handleOtpVerification={props.handleOtpVerification}
                                    login={props.login}
                                    signup={props.signup}
                                    isOtpSend={props.isOtpSend}
                                />
                            )}
                           {isLoggedIn && (
                                <CheckoutAddress
                                    isAddressLoading={isAddressLoading}
                                    isAddAddressClick={isAddAddressClick}
                                    isSubmitClicked={props.isSubmitClicked}
                                    categories={categories}
                                    use_wallet={props.use_wallet}
                                    totalCartData={props.totalCartData}
                                    selectedPaymentType={props.selectedPaymentType}
                                    cartData={cartData}
                                    address_apartment={props.address_apartment}
                                    address_area={props.address_area}
                                    address_city={props.address_city}
                                    address_pincode={props.address_pincode}
                                    address_state={props.address_state}
                                    address_landmark={props.address_landmark}
                                    valid_address_apartment={props.valid_address_apartment}
                                    valid_address_area={props.valid_address_area}
                                    valid_address_city={props.valid_address_city}
                                    valid_address_pincode={props.valid_address_pincode}
                                    valid_address_state={props.valid_address_state}
                                    addressList={props.addressList}
                                    addressType={props.addressType}
                                    typeOfAddress={props.typeOfAddress}
                                    handleAddressChange={props.handleAddressChange}
                                    handleAddressType={props.handleAddressType}
                                    handleCreateAddress={props.handleCreateAddress}
                                    handleDeliveryAddressSelection={
                                        props.handleDeliveryAddressSelection
                                    }
                                    selAddressId={props.selAddressId}
                                    handleOrder={props.handleOrder}
                                    isMapAddressEnable={props.isMapAddressEnable}
                                    handleChange={props.handleChange}
                                    handleAddAddress={props.handleAddAddress}
                                    handleSelectPayment={props.handleSelectPayment}
                                    handleWalletCheck={props.handleWalletCheck}
                                />
                            )}
                        </div>
                    </div>
                </section>
            }
        </div>
    );
};
export default CheckoutPage;
