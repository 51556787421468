import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import $ from "jquery";
import {connect} from "react-redux";
import inside from "point-in-polygon";
import {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import moment from "moment";
import FilterContainer from "./filter";
import Geocode from "react-geocode";
import {isArray} from "../../utils/util";


import {
    CartComponent,
    FoodCategory,
    ResponsiveMenu,
    SearchLocationModal,
    ShowAddressCard,
    TopHeaderCard
} from "./headerComponent/index";
import ReactGA from "react-ga4";
import {googleAnalyticKey} from "../../env";

import * as ActionMessage from "../../constants/constant";
import {homeService, menuService} from "./../../_services/index";
import {AddToCart, SetBranchAddress, SetBranchCategory} from "../../_reducers/cartReducer";
import {appLaunch} from "../../_reducers/loginReducer";

Geocode.setApiKey("AIzaSyBWZ8pckCiE-Qp5ka0FRveEGI8l8vfIt2E");
Geocode.enableDebug();
ReactGA.initialize(googleAnalyticKey);

class Header extends Component {
    state = {
        center: null,
        marker: null,
        directions: null,
        isClickScroll: true,
        isLocationFetching: false,
        branchId: "",
        launched: true,
        branchPhoneNumber: "",
        address: "",
        categories: [],
        allBranches: [],
        isBranchServing: true,
        currentBranchInfo: {},
        defaultBranch: ''
    };

    UNSAFE_componentWillMount() {
        if (
            this.props.branchId === "" ||
            this.props.branchId === undefined ||
            this.props.branchId === null
        ) {
            this.setState({isLocationFetching: true});
            navigator.geolocation.getCurrentPosition(position => {
                let branchId = this.checkPointExistInPolygon([
                    +position.coords.latitude,
                    +position.coords.longitude
                ]);
                this.setState({
                    center: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    marker: {
                        position: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                    }
                });

                let response = homeService.getAddressByLatLng(position.coords.latitude,
                    position.coords.longitude);
                response.then(async response => {
                    this.setState({isLocationFetching: false});
                    const address = response.results[0].formatted_address;
                    await this.props.setBranchIdAddress(branchId, address);
                    this.getBranchCategory();
                    this.getBranchList();
                });

            });
        }
    }

    componentDidMount() {
        this.getCurrentBranchInfo();
        let currentPage = this.props.location.pathname.split("/");
        if (currentPage[1] === "") {
            this.getBranchList();
        }
        if (currentPage[1] !== "checkout") {
            this.getBranchCategory();
            this.checkPointExistInPolygon();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.getCurrentBranchInfo();
    }

    convertObjectToArray = servingArea => {
        for (let i = 0; i < servingArea.length; i++) {
            servingArea[i].polygonArea = [];
            for (
                let index = 0;
                index < servingArea[i].branch_serving_area.serving_area.length;
                index++
            ) {
                let latitude =
                    servingArea[i].branch_serving_area.serving_area[index].lat;
                let longitude =
                    servingArea[i].branch_serving_area.serving_area[index].lng;
                servingArea[i].polygonArea[index] = [+latitude, +longitude];
            }
        }
        return servingArea;
    };
    getBranchCategory = async () => {
        let branchId = this.props.branchId;
        if (branchId) {
            let response = await homeService.getBranchLisById(branchId);
            if (response.data && response.data.length) {
                this.setState({categories: response.data});
            } else {
                this.setState({categories: []});
            }
        } else {
            this.setState({isClickScroll: false});
        }
    };
    handleChange = address => {
        this.setState({address});
    };

    handleSelect = async address => {
        // console.log("Handle select", address);
        $(".location-modal .close").click();
        this.setState({isLocationFetching: true});
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(async latLng => {
                let branchId = this.checkPointExistInPolygon([latLng.lat, latLng.lng]);
                if (branchId) {
                    this.setState({address: "", isLocationFetching: false});
                    await this.props.setBranchIdAddress(branchId, address);
                    this.getBranchList();
                    this.props.addToCart([]);
                    this.getBranchCategory();
                } else {
                    this.props.setBranchIdAddress((branchId = ""), address);
                    this.props.addToCart([]);
                    this.getBranchList();
                    this.setState({address: "", isLocationFetching: false});
                }
            })
            .catch(error => console.error(" "));
    };

    handleError = (status, clearSuggestions) => {
        // console.log('Google Maps API returned error with status: ', status)
        clearSuggestions()
    }
    getBranchList = async () => {
        let response = await menuService.getBranchList();
        if (response.data && response.data.length) {
            let current_branch_id = this.props.branchId;

            this.setState({defaultBranch: response.data[0].branch_name});

            let branchPhoneNumber = response.data.find(
                data => data.branch_id === this.props.branchId
            );
            let defaultBranchNumber =
                isArray(response.data) &&
                response.data[0] &&
                response.data[0].branch_phone;
            branchPhoneNumber = branchPhoneNumber
                ? branchPhoneNumber.branch_phone
                : defaultBranchNumber;

            this.props.addBranchCategory(response.data);
            let polygonArea = this.convertObjectToArray(response.data);
            let isBranchServing = response.data.find(
                branch => branch.branch_id === this.props.branchId
            );
            if (isBranchServing && isBranchServing.branch_status) {
                let branch_status = isBranchServing.branch_status;
                const servingHours = isBranchServing.branch_working_hrs;
                // const servingHours = "23:00,24:00";
                let startServingHour = servingHours.split(",")[0];
                let endServingHour = servingHours.split(",")[1];
                let startServing = startServingHour
                    ? startServingHour.split(":")
                    : null;
                let startServingHours = startServing ? startServing[0] : 0;
                let startServingMinute = startServing ? startServing[1] : 0;
                let endServing = endServingHour ? endServingHour.split(":") : null;
                let endServingHours = startServing ? endServing[0] - 1 : 0;
                let endServingMinute = startServing ? +endServing[1] : 0;
                let startServingTime =
                    Number(startServingHours) * 60 + Number(startServingMinute);
                let endServingTime =
                    Number(endServingHours) * 60 + Number(endServingMinute);
                let currentMinuteHour =
                    moment(new Date()).hour() * 60 + moment().minute();
                let preOrder =
                    currentMinuteHour >= startServingTime &&
                    currentMinuteHour <= endServingTime;
                preOrder = preOrder && branch_status ? true : false;
                // console.log('Is Pre ORder', preOrder);
                this.setState({
                    allBranches: polygonArea,
                    branchPhoneNumber: branchPhoneNumber,
                    isBranchServing: preOrder,
                    servingHours: servingHours,
                    branch_status: branch_status
                });
            } else {
                this.setState({
                    allBranches: polygonArea,
                    branchPhoneNumber: branchPhoneNumber
                });
            }

            // if (current_branch_id === "" || current_branch_id === 0 || current_branch_id === undefined || current_branch_id===false) {
            if (this.props.launched) {
                this.setState({launched: false});
                this.props.setAppLaunchState(false);
                this.handleCurrentLocationClick();
            }
        }
    };
    checkPointExistInPolygon = latLang => {
        let allBranches = [...this.state.allBranches];
        for (let index = 0; index < allBranches.length; index++) {
            let isExistInside = inside(latLang, allBranches[index].polygonArea);
            if (isExistInside) {
                // console.log('Branch = '+JSON.stringify(allBranches[index]));
                // console.log('Branch Status = '+(Number(allBranches[index].branch_status)===1));

                if (Number(allBranches[index].branch_status) === 1) {
                    // console.log('present');
                    return isExistInside ? allBranches[index].branch_id : "";
                } else {
                    // console.log('not present');
                    this.setState({isBranchServing: false});
                    return isExistInside ? allBranches[index].branch_id : "";
                }
            }
        }
        return false;
    };

    handleCurrentLocationClick = () => {
        // ReactGA.ga("send", "pageview", `/location_selected`);
        $(".location-modal .close").click();
        // navigator.permissions.query({name: 'geolocation'}).then(function (result) {
        //     if (result.state == 'granted' || result.state == 'prompt') {
        //         this.handleLocationChange();
        //     } else{
        //         alert("Need permission to locate you.");
        //     }
        //
        // });

        this.handleLocationChange();

    };

    handleLocationChange() {
        this.setState({isLocationFetching: true});
        navigator.geolocation.getCurrentPosition(position => {
            let branchId = this.checkPointExistInPolygon([
                +position.coords.latitude,
                +position.coords.longitude
            ]);
            let response = homeService.getAddressByLatLng(position.coords.latitude,
                position.coords.longitude);
            response.then(async response => {
                if (branchId) {
                    const address = response.results[0].formatted_address;
                    this.props.setBranchIdAddress(branchId, address);
                    this.props.addToCart([]);
                    this.getBranchList();
                    this.getBranchCategory();

                    this.setState({isLocationFetching: false});
                } else {
                    const address = response.results[0].formatted_address;
                    this.props.addToCart([]);
                    this.props.setBranchIdAddress("", "");
                    this.setState({address: "", isLocationFetching: false});
                }
            });
        });
    }

    openCart() {
        $(".cart-side").addClass("show");
        $(".cart-background").addClass("show");
    }

    opeMenu() {
        $(".resp-nav").removeClass("away");
    }

    closeMenu() {
        $(".resp-nav").addClass("away");
    }

    closeCart() {
        $(".cart-side").removeClass("show");
        $(".cart-background").removeClass("show");
    }

    openFilter() {
        $(".filter-container").toggleClass("show");
    }

    closeFilter() {
        $(".filter-container").removeClass("show");
    }

    handleAddToCart = product => {
        if (product !== undefined) {
            let cartData = [...this.props.cartData];
            let findExistProduct = cartData.findIndex(
                cart => cart.product_price_id === product.product_price_id
            );
            if (findExistProduct !== -1) {
                cartData[findExistProduct].productCount =
                    cartData[findExistProduct].productCount + 1;
                this.props.addToCart(cartData);
            } else {
                product.productCount = 1;
                let updatedCartData = cartData.concat([product]);
                this.props.addToCart(updatedCartData);
            }
        }
    };

    handleDecrementProductCount = product => {
        let cartData = [...this.props.cartData];
        let findExistProduct = cartData.findIndex(
            cart => cart.product_price_id === product.product_price_id
        );
        if (findExistProduct !== -1) {
            let productCount = cartData[findExistProduct].productCount;
            if (productCount > 1) {
                cartData[findExistProduct].productCount =
                    cartData[findExistProduct].productCount - 1;
                this.props.addToCart(cartData);
            } else {
                cartData = cartData.filter(
                    cart => cart.product_price_id !== product.product_price_id
                );
                this.props.addToCart(cartData);
            }
        }
    };
    handleProfileClick = () => {
        let currentPage = this.props.location.pathname;
        if (currentPage !== "/inner") {
            this.props.history.push("/user_profile");
        } else {
            this.props.history.push("./user_profile");
        }
    };
    handleCartAddToCart = (product, productDetails) => {
        let cartData = [...this.props.cartData];
        let findExistProduct = cartData.findIndex(
            cart => cart.product_price_id === product.product_price_id
        );
        if (findExistProduct !== -1) {
            cartData[findExistProduct].productCount =
                cartData[findExistProduct].productCount + 1;
            this.props.addToCart(cartData);
        } else {
            product.productCount = 1;
            //adding product details inside the different serving sizes
            product.product_name = productDetails.product_name;
            product.product_desc = productDetails.product_desc;
            product.is_veg = productDetails.is_veg;
            product.category_id = productDetails.category_id;
            let updatedCartData = cartData.concat([product]);
            this.props.addToCart(updatedCartData);
        }
    };
    getCurrentBranchInfo = () => {
        let branchCategory = this.props.branchCategory;
        if (isArray(branchCategory)) {
            let currentBranchInfo = branchCategory.find(
                branch => branch.branch_id === this.props.branchId
            );
            currentBranchInfo &&
            this.setState({currentBranchInfo: currentBranchInfo});
        }
    };

    render() {
        const {
            page,
            removeLocation,
            removeRespLocation,
            respcart,
            handleShow,
            cartData,
            address,
            cartRelatedCat,
            currentPage,
            checkoutpageclass,
            handleSimilarProductClick,
            userName
        } = this.props;
        const {
            categories,
            isLocationFetching,
            branchPhoneNumber,
            currentBranchInfo
        } = this.state;
        // console.log(this.state);
        return (
            <header className="header">
                {/*{!this.state.isBranchServing && this.props.branchId && (*/}
                {/*    <div className="no-internet">*/}
                {/*        {ActionMessage.BRANCH_CLOSED}*/}
                {/*        Business Hours :{" "}*/}
                {/*        {this.state.servingHours &&*/}
                {/*        isArray(this.state.servingHours.split(",")) &&*/}
                {/*        moment(this.state.servingHours.split(",")[0], ["HH"]).format(*/}
                {/*            "hha"*/}
                {/*        ) +*/}
                {/*        " to " +*/}
                {/*        moment(this.state.servingHours.split(",")[1], ["HH"]).format(*/}
                {/*            "hha"*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*)}*/}
                <TopHeaderCard
                    checkoutpageclass={checkoutpageclass}
                    isLocationFetching={isLocationFetching}
                    openCart={this.openCart}
                    opeMenu={this.opeMenu}
                    page={page}
                    address={address || this.state.defaultBranch}
                    cardData={cartData}
                    isLoggedIn={this.props.isLoggedIn}
                    handleProfileClick={this.handleProfileClick}
                    branchPhoneNumber={branchPhoneNumber}
                    currentBranchInfo={currentBranchInfo}
                    branchId={this.props.branchId}
                    userName={userName}
                />
                {/*<FoodCategory*/}
                {/*    openFilter={this.openFilter}*/}
                {/*    openCart={this.openCart}*/}
                {/*    page={page}*/}
                {/*    categories={categories}*/}
                {/*    isClickScroll={this.state.isClickScroll}*/}
                {/*    handleShow={handleShow}*/}
                {/*    cardData={cartData}*/}
                {/*    categoryId={this.props.categoryId}*/}
                {/*    handleFilter={this.props.handleFilter}*/}
                {/*    filterBy={this.props.filterBy}*/}
                {/*/>*/}

                <ResponsiveMenu
                    closeMenu={this.closeMenu}
                    handleProfileClick={this.handleProfileClick}
                    userName={userName}
                />
                <SearchLocationModal
                    handleChange={this.handleChange}
                    address={this.state.address}
                    handleSelect={this.handleSelect}
                    handleError={this.handleError}
                    handleCurrentLocationClick={this.handleCurrentLocationClick}
                    addressFromHeader={address}
                />

                <CartComponent
                    handleAddToCart={this.handleAddToCart}
                    handleDecrementProductCount={this.handleDecrementProductCount}
                    closeCart={this.closeCart}
                    cartData={this.props.cartData}
                    categories={categories}
                    cartRelatedCat={cartRelatedCat}
                    handleSimilarProductClick={handleSimilarProductClick}
                    currentPage={currentPage}
                    handleCartAddToCart={this.handleCartAddToCart}
                    currentBranchInfo={currentBranchInfo}
                />
                <ShowAddressCard
                    removeLocation={removeLocation}
                    page={page}
                    respcart={respcart}
                    address={address}
                    openCart={this.openCart}
                    openFilter={this.openFilter}
                    removeRespLocation={removeRespLocation}
                    cardData={cartData}
                />

                <FilterContainer
                    close="true"
                    className="d-md-none"
                    closeFilter={e => this.closeFilter()}
                    handleFilter={this.props.handleFilter}
                />
            </header>
        );
    }
}

const mapStateToProps = state => {
    return {
        cartData: state.CartReducer.cartProducts,
        branchId: state.CartReducer.branchId,
        address: state.CartReducer.address,
        isLoggedIn: state.LoginReducer.isLoggedIn,
        branchCategory: state.CartReducer.branchCategory,
        userName: state.LoginReducer.userName,
        launched: state.LoginReducer.launched
    };
};
const mapDispatchToProps = dispatch => {
    return {
        addToCart: products => dispatch(AddToCart(products)),
        addBranchCategory: categories => dispatch(SetBranchCategory(categories)),
        setBranchIdAddress: (branchId, address) =>
            dispatch(SetBranchAddress(branchId, address)),
        setAppLaunchState: (state) => dispatch(appLaunch(state))
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Header)
);
