import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import {config} from "../../env";
import {Helmet} from "react-helmet";
import ReactModal from 'react-modal';

const AboutUsPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Online Biryani Delivery in Bangalore - Potful</title>
                <meta name="description"
                      content="Potful provides online biryani delivery and is dedicated to bringing the authentic taste of biryani at your doorstep."/>
            </Helmet>
            <section className="mt-5">
                <div className="category_header mb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="heading"><span>Media</span></h2>
                                <p className="text-center">Potful in Media</p>
                                <section className="mt-5">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div>
                                                <a target="_blank" href="/images/media/real-dum-full.jpeg">
                                                    <img className="img-fluid" src="/images/media/real-dum.jpg"
                                                         alt=""/>
                                                    <p className="mt-2 mb-5"><span
                                                        className="fa fa-arrow-right"></span> Empowering Women</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div>
                                                <a target="_blank" href="/images/media/times.jpg">
                                                    <img className="img-fluid" src="/images/media/times-tumb.jpg"
                                                         alt=""/>
                                                    <p className="mt-2 mb-5"><span
                                                        className="fa fa-arrow-right"></span> Times Award</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div>
                                                <a target="_blank" href="/images/media/green.jpg">
                                                    <img className="img-fluid" src="/images/media/green-thumb.jpg"
                                                         alt=""/>
                                                    <p className="mt-2 mb-5"><span
                                                        className="fa fa-arrow-right"></span> Green Award</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div>
                                                <a target="_blank" href="/images/media/comment.jpg">
                                                    <img className="img-fluid" src="/images/media/comment-tumb.jpg"
                                                         alt=""/>
                                                    <p className="mt-2 mb-5"><span
                                                        className="fa fa-arrow-right"></span> Social Media</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div>
                                                <a target="_blank"
                                                   href="https://yourstory.com/smbstory/potful-bengaluru-hyderabadi-mughlai-dum-biryani-kebabs-msme">
                                                    <img className="img-fluid" src="/images/media/1.jpg" alt=""/>
                                                    <p className="mt-2 mb-5"><span
                                                        className="fa fa-arrow-right"></span> YourStory</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div>
                                                <a target="_blank"
                                                   href="https://www.indiaretailing.com/2017/11/30/food/potful-delivers-sizzling-biryani-straight-home-clay-pots/">
                                                    <img className="img-fluid" src="/images/media/2.jpg" alt=""/>
                                                    <p className="mt-2 mb-5"><span
                                                        className="fa fa-arrow-right"></span> India Retailing</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div>
                                                <a target="_blank"
                                                   href="https://yourstory.com/smbstory/small-medium-business-online-selling-ecommerce-entrepreneurs">
                                                    <img className="img-fluid" src="/images/media/3.jpg" alt=""/>
                                                    <p className="mt-2 mb-5"><span
                                                        className="fa fa-arrow-right"></span> YourStory / SMBStory</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="mt-5"></section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
            <ModalsPage/>
        </div>
    )
}
export default AboutUsPage;
