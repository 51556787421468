import React from 'react';
import $ from 'jquery';
import Header from '../_common/header';
import {NavLink} from 'react-router-dom';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import Banner from '../_common/banner';
import Cards from '../_common/cards';
import {homeService} from '../../_services/index';
import {config, facebookPixalKey} from '../../env';
import LazyLoad from 'react-lazyload';
import {connect} from 'react-redux';
import {isArray} from '../../utils/util';
import Loader from '../_common/loader';
import {Helmet} from 'react-helmet';
// import ExitIntentModal from '../_common/modalComponent/exit-intent-modal';
import ReactModal from 'react-modal';
import Iframe from 'react-iframe';
import {checkoutService} from '../../_services/checkout.service';
import {toast} from 'react-toastify';
import {Event} from '../../_actions/trackEvent';
import ReactGA from 'react-ga';
import {checkFeedback} from '../../_services/feedback.service';
import {getValue} from '../../utils/lodash';
import StarRatings from 'react-star-ratings';

const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};

//   export const trackPurchaseFB = (category,action,label) =>{
//     ReactPixel.track('CustomeEvent', {
//         category: category,
//         action: action,
//         label: label
//     });
//   }
class LandingPage extends React.Component {
    state = {
        categoryId: '',
        isLoading: true,
        categories: [],
        selectedTeam: {},
        feedback: {},
        activeOrder: {},
        ratingState: false
    };

    constructor() {
        super();
        this.state = {
            showModal: false,
            showModal1: false,
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenModal1 = this.handleOpenModal1.bind(this);
        this.handleCloseModal1 = this.handleCloseModal1.bind(this);
    }

    handleOpenModal() {
        this.setState({showModal: true});
    }

    handleOpenModal1() {
        this.setState({showModal1: true});
    }

    handleCloseModal() {
        this.setState({showModal: false});
    }

    handleCloseModal1() {
        this.setState({showModal1: false});
    }

    componentDidMount() {
        this.getOrders();
        this.getBranchCategory();
        if (this.props.accessToken !== '') {
            this.getSelectedTeam();
            this.checkFeedbackInfo();
        }
        // initGA(googleAnalyticKey);
        const ReactPixel = require('react-facebook-pixel');
        ReactPixel.default.init(facebookPixalKey);
        this.analyseGoogleAnalytics();
    }

    getOrders = async () => {
        try {
            let resp = await checkoutService.getAllOrders();
            if (resp) {
                let liveOrder = getValue(resp, `data`, []).filter(item => item.is_active)
                this.setState({
                    activeOrder: liveOrder[0],
                });
            }
        } catch (error) {
        }
    };

    checkFeedbackInfo = async () => {
        try {
            let resp = await checkFeedback();
            if (resp) {
                if(getValue(resp,"errors","")===""){
                    this.setState({
                        feedback: getValue(resp, `data`, {}),
                        ratingState: true
                    });
                }

            }
        } catch (error) {
        }
    };

    analyseGoogleAnalytics = () => {
        ReactGA.ga('send', 'event', `pageview`);
    };
    getSelectedTeam = async () => {
        let response = await checkoutService.getSelectedTeam();
        if (response.error === '' && response.data) {
            this.setState({selectedTeam: response.data});
        } else {
            let errorMessage = response.errors || response.error_message;
            toast.error(errorMessage);
        }
    };

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.branchId !== '' ||
            nextProps.branchId !== this.state.branchId
        ) {
            await this.getBranchCategoryBYId(nextProps.branchId);
            await this.setState({branchId: this.props.branchId});
        } else if (nextProps.branchId === '') {
            await this.setState({categories: [], branchId: ''});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.branchId !== nextState.branchId;
    }

    getBranchCategoryBYId = async (branchId) => {
        this.setState({isLoading: true});
        if (branchId) {
            let response = await homeService.getBranchLisById(branchId);
            if (response.data && response.data.length) {
                this.setState({categories: response.data, isLoading: false});
                this.forceUpdate();
            } else {
                this.setState({categories: [], isLoading: false});
            }
        } else {
            this.setState({categories: [], isLoading: false});
        }
        this.forceUpdate();
    };
    getBranchCategory = async () => {
        let branchId = this.props.branchId;
        this.setState({isLoading: true});
        if (branchId) {
            let response = await homeService.getBranchLisById(branchId);
            if (response.data && response.data.length) {
                this.setState({categories: response.data, isLoading: false});
                this.forceUpdate();
            } else {
                this.setState({categories: [], isLoading: false});
            }
        } else {
            this.setState({categories: [], isLoading: false});
        }
        this.forceUpdate();
    };

    handleShow(categoryId) {
        this.setState({categoryId: categoryId});
        $('html, body').animate(
            {
                scrollTop: $('#' + categoryId).offset().top,
            },
            1000
        );
    }

    handleClickCategory = (categoryId) => {
        sessionStorage.setItem('categoryId', categoryId);
    };

    render() {
        const {categories, handleShow, selectedTeam} = this.state;
        // console.log(selectedTeam, 'selected');
        return (
            <div>
                {/*<div className="prep">*/}
                {/*Preven*/}
                {/*</div>*/}
                <button
                    className="btn-nothing d-none"
                    type="button"
                    data-toggle="modal"
                    data-target="#exit-intent-modal"
                    id="exit-intent"
                >
                    Exit intent modal
                </button>
                <Header
                    page="main"
                    handleShow={handleShow}
                    address={this.props.address}
                    branchId={this.props.branchId}
                    categoryId={this.state.categoryId}
                />
                <Helmet>
                    <title>Retro In Metro - The Retro feeling In Metro lifestyle</title>
                    <meta
                        name="description"
                        content="We, the Retro In Metro team, are trying to bring back the golden heritage of a long forgotten natural way of consuming millets in the form of RetroSip® millet malts that blends with our current metro lifestyle. #RetroFeelingInMetroLifestyle"
                    />
                </Helmet>
                {getValue(this.state, `activeOrder.is_active`, false) ? (
                    <div className="container">
                        <div className="ongoing-order cursor-pointer">
                            <NavLink
                                to={`/live-track/${getValue(
                                    this.state,
                                    `activeOrder.bill_id`,
                                    ''
                                )}`}
                            >
                                <h5>
                                    <img
                                        src={`${config.urlPrefix}/images/tracknew.svg`}
                                        alt=""
                                    />{' '}
                                    <span>Track your order</span>
                                </h5>
                            </NavLink>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <Banner branchId={this.props.branchId}/>
                {/*<div className="container mt-4">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-md-12">*/}
                {/*            {this.state.ratingState && !getValue(this.state, `feedback.feedback_found`, '') && (*/}
                {/*                <div className="please-rate">*/}
                {/*                    <NavLink*/}
                {/*                        to={`/feedback/${getValue(*/}
                {/*                            this.state,*/}
                {/*                            `feedback.bill_id`,*/}
                {/*                            ''*/}
                {/*                        )}`}*/}
                {/*                    >*/}
                {/*                        /!*Please Rate for {getValue(this.state, `feedback.bill_id`, '')}*!/*/}
                {/*                        <p className="mb-0">Rate your recent order</p>*/}
                {/*                        <StarRatings*/}
                {/*                            rating={0}*/}
                {/*                            starDimension="20px"*/}
                {/*                            starSpacing="5px"*/}
                {/*                            starEmptyColor="white"*/}
                {/*                        />*/}
                {/*                    </NavLink>*/}
                {/*                </div>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <img className="img-fluid" src="/images/rim/millet-home.jpg" alt=""/>
                            </div>
                            <div className="col-md-6 text-sm-center">
                              <h2 className="title pt-3 mt-md-5">Eat the right food, in the right way.</h2>
                              <p>In this world of food adulteration, chemical fertilizers, and fad foods, we always envy
                                our grandparents’ healthy lifestyle with longer life span.</p>
                              <p>Millets were the first cereal grains that were an important part of our forefathers’
                                healthy eating habits.</p>
                              <p>We, the Retro In Metro team, are trying to bring back the golden heritage of a long
                                forgotten natural way of consuming millets in the form of RetroSip® millet malts that
                                blends with our current metro lifestyle. #RetroFeelingInMetroLifestyle</p>
                              <p><strong>It’s our proud feeling that we have introduced the “first ever single millet malts“,
                                which is the ONLY correct way of sipping the millet malts.</strong> <a href="/about-us">Read More...</a>
                              </p>

                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <hr/>
                                <h1 className="title  mb-3 text-center mb-4">Prepare a healthy and tasty RetroSip malt drink in 3 simple steps.</h1>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <iframe width="100%" height="515" src="https://www.youtube.com/embed/WXRGR4BN3WQ"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </div>
                        </div>
                        {/*<div className="row">*/}
                        {/*    <div className="col-6 col-sm-6 col-md-3">*/}
                        {/*        <img className="img-fluid img-round-shadow mb-3" src="/images/rim/dishes/1.jpg" alt=""/>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-6 col-sm-6 col-md-3">*/}
                        {/*        <img className="img-fluid img-round-shadow mb-3" src="/images/rim/dishes/2.jpg" alt=""/>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-6 col-sm-6 col-md-3">*/}
                        {/*        <img className="img-fluid img-round-shadow mb-3" src="/images/rim/dishes/3.jpg" alt=""/>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-6 col-sm-6 col-md-3">*/}
                        {/*        <img className="img-fluid img-round-shadow mb-3" src="/images/rim/dishes/4.jpg" alt=""/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    {/*<div className="container">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col-md-12">*/}
                    {/*            <p className="text-center mt-3 mb-3"><a href="https://everydaynourishingfoods.com/how-to-cook-fluffy-millets/" target="_new">Check out some Recepies</a></p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </section>
                <section className="cycle mb-5  ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 text-sm-center">
                                <img className="img-fluid" src="/images/rim/cycle-metro.jpg" alt=""/>
                            </div>
                            <div className="col-md-8 vertical-align-centered text-sm-center">
                                <div className="did-you-know mt-4 text-sm-center">
                                    <img className="img-fluid" src="/images/rim/did-you-know.svg" alt=""/>
                                </div>
                                <p className="mt-3">
                                    As per <strong>doctors</strong> and <strong>nutrition experts</strong>, <strong>millets should not be mixed</strong> and single millet should be consumed for at least <u>3 days regularly</u> so finish one type of single millet malt pouch and then open the other type of malt pouch to avail their miraculous health benefits.
                                </p>
                                <p> For example, if you have opened a little millet malt pouch, finish it first, then go for the other millet malt pouch.</p>
                                {/*<p className="mt-3">As per the <strong>doctors</strong> and <strong>nutrition*/}
                                {/*    experts</strong>,*/}
                                {/*    millet's should not be mixed.*/}
                                {/*</p>*/}
                                {/*<p>So, Single millet malt should be consumed*/}
                                {/*    for at least <u>3 days continuously</u> to avail*/}
                                {/*    their miraculous health benefits*/}
                                {/*</p>*/}
                                <hr/>
                                <div className="shoplink">
                                    <a href="/products">
                                      <span className="shop-text">
                                        Shop Now
                                      </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer categories={categories}/>
                <ModalsPage/>
                {/*<ExitIntentModal/>*/}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cartData: state.CartReducer.cartProducts,
        branchId: state.CartReducer.branchId,
        address: state.CartReducer.address,
        isLoggedIn: state.LoginReducer.isLoggedIn,
        accessToken: state.LoginReducer.accessToken,
    };
};
export default connect(mapStateToProps)(LandingPage);
