import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import FilterContainer from "../filter";
import { isArray,countTotalItemsOnCart } from "../../../utils/util";
import {config} from "../../../env";
class FoodCategory extends Component {
  render() {
    const {
      page,
      categories,
      categoryId,
      isClickScroll
    } = this.props;
    return page === "main" ? (
      ""
    ) : (
        <div className="submenu">
          <div className="container-fluid">
            <div className="submenu-container">
              <div className="sub-menu-left">
                {page === "" ? (
                  ""
                ) : (
                    <div className="categories" id="categories">
                      <ul className="nav" role="tablist">
                        {categories.length > 0 &&
                          categories.map(category => {
                            return (
                              <li
                                key={category.category_id}
                                className={
                                  `nav-item ` +
                                  (Number(category.category_id) ===
                                    Number(categoryId)
                                    ? " active"
                                    : "")
                                }
                              >
                                {isClickScroll ? (
                                  <button
                                    onClick={e =>
                                      this.props.handleShow(category.category_id)
                                    }
                                    className="btn-nothing nav-link cursor-pointer"
                                  >
                                    {category.category_name}
                                  </button>
                                ) : (
                                    <button
                                      className="btn-nothing nav-link cursor-pointer"
                                    >
                                      {category.category_name}
                                    </button>
                                  )}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}
              </div>
              <div className="sub-menu-right">
                <ul className="filter">
                  {page === "details" ? (
                    ""
                  ) : (
                      <li className="filter-btn">
                        <button
                          className="btn-nothing"
                          onClick={e => this.props.openFilter(e)}
                        >
                          <img src={`${config.urlPrefix}/images/filter.svg`} alt="" />
                        </button>
                        <FilterContainer
                          handleFilter={this.props.handleFilter}
                          filterBy={this.props.filterBy}
                        />
                      </li>
                    )}
                  <li className="cart">
                    <button
                      onClick={e => this.props.openCart(e)}
                      className="btn-nothing"
                    >
                      <img src={`${config.urlPrefix}/images/cart.svg`} alt="" />
                      <span>
                        {isArray(this.props.cardData)
                          ? countTotalItemsOnCart(this.props.cardData)
                          : 0}
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      );
  }
}

export default FoodCategory;
