export function authTokenHeader() {
  let persistData = JSON.parse(localStorage.getItem("persist:potful"));
  let loginReducerData =
    persistData &&
    persistData.LoginReducer &&
    JSON.parse(persistData.LoginReducer);
  let accessToken = loginReducerData && loginReducerData.accessToken;
  if (accessToken !== "" && accessToken !== undefined) {
    return {
      access_token: accessToken,
      version: '2.0',
        domain:'retroinmetro.com'

    };
  } else {
    return { version: '2.0',domain:'retroinmetro.com'};
  }
}
