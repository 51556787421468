import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";

const KebabsPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Best Kebabs Home Delivery In Bangalore</title>
                <meta name="description"
                      content="Potful provide home delivery of freshly cooked best kebabs in Bangalore. We explore the different traditional kebabs preparations and bring to you the most authentic recipes and flavors from all over Asia."/>
            </Helmet>
            <section className="mt-5">
                <div className="category_header mb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="heading mb-4"><span>Kebabs</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="terms-p">
                <div className="container">
                    <div className="row">
                        <div className="offset-md-1 col-md-10">
                            <p>
                                Are you a huge fan of kebab? Did you know that it is a Middle Eastern cuisine? Its
                                origins can also be traced to royal courts and humble kitchens, from across the length
                                and breadth of India.
                            </p>
                            <p>
                                Popular across the world, a kebab is any of a wide variety of grilled meat dishes in
                                India, Middle East, other parts of Asia, and the Muslim world. Some dishes ultimately
                                derived from Middle Eastern kebab may have different names in their local languages.
                            </p>
                            <h3>Indian Kebab</h3>
                            <p>
                                Though pit- or skewer-cooked meat dishes are noted in an ancient Indian text, modern-day
                                kebabs in India mostly trace their origin to the influence of Mughlai cuisine. Some
                                varieties of kebab in India are more or less similar to kebab preparations elsewhere but
                                with a distinctive taste, which can be credited to the use of Indian spices. Other
                                varieties are entirely distinct versions native to India such as Tunde ke kabab, Tikka
                                kebab, Shami kebab, Soovar ki Saanth (pork belly kebabs from Rajasthan) and Rajpooti
                                soolah. The prevalence of vegetarianism in much of India also means that there are many
                                local vegetarian varieties made from Paneer or potato.
                            </p>
                            <p>
                                <NavLink className="text-dark no-hover" to="/categories/kebabs"><strong>Potful signature Kebabs</strong></NavLink> are boneless pieces of meat, fish or vegetables seasoned by hand
                                with a mixture of ingredients from the traditional recipes. It is then placed on the
                                grill until the fat drips from the meat and becomes tender and juicy, and brown quickly
                                to perfection letting the flavor of the meat shine through.
                            </p>
                            <p>
                                Although the best kebabs are often cooked on a skewer, many types of kebab are not.
                                Kebab dishes can consist of cut up or ground meat or seafood, sometimes with fruits and
                                vegetables, cooked on a skewer over a fire, or like a hamburger on a grill, baked in a
                                pan in an oven, or as a stew; and served with various accompaniments according to each
                                recipe. The traditional meat for kebabs is most often mutton or lamb, but regional
                                recipes may include beef, goat, chicken, fish, or pork.
                            </p>
                            <h3>The Potful Varieties</h3>
                            <p>
                                While kebabs are a predominantly non vegetarian area, Potful offers a variety of kebabs
                                online to both the vegetarian and non vegetarian food lover. Apart from the basic Veg
                                and Non veg kebab platter, Potful also has other delicious specials in both areas from
                                Kali Mirch Lasooni Paneer Tikka for the Vegetarian foodie to the Nawabi Tangdi for the
                                hard core meat lover.
                            </p>
                            <p>
                                <NavLink className="text-dark no-hover" to="/restaurant-menu/potful-veg-kebab-platter-12pc/"><strong>The Potful Veg Kebab platter</strong></NavLink> consists of a combination of Malai Paneer tikka, Bharwan
                                aloo, Shammi (tikki) baby corn and fresh mushrooms. Malai Paneer is cooked with fresh
                                cream, cashew paste, cheese cubes, and a melange of spices. Bell peppers make the dish
                                more colourful and flavourful. Bharwan aloo is a fascinating potato dish with barrel
                                shaped
                            </p>
                            <p>
                                potatoes, stuffed with paneer mixture, marinated in yogurt masala and grilled on
                                charcoal with spicy red tandoori marination. These along with the baby corn and mushroom
                                tikki fry make the veg kebab a mouth watering appetizer dish.
                            </p>
                            <p>
                                Potful also offers other variations of everyone’s favourite Paneer. The Kali Mirch
                                Lasooni Paneer Tikka is a Potful special, prepared in the authentic Indian kebab method
                                by marinating cottage cheese in a fresh cream, ginger garlic and pepper powder paste and
                                cooked on a skewer over the fire with bell peppers, onions and tomatoes.
                            </p>
                            <p>
                                <NavLink className="text-dark no-hover" to="/restaurant-menu/potful-non-veg-kebab-platter-12pc/"><strong>The Non Veg Kebab platter</strong></NavLink> consists of combination of Peshawari Murgh, Murgh hara mirch,
                                Murgh pasanda, tawa fish and gelafi seekh, which provides you with a variety of chicken
                                kebabs prepared with green chilli, bell peppers and fried in ghee and Indian spices,
                                fish fry and lamb kebabs that are rich and melt in your mouth.
                            </p>
                            <p>
                                Other Non vegetarian favourites at Potful include the Murgh Khada masala which include
                                boneless Chicken tikka marinated in curd flavoured with indian spices and masala. It is
                                served with Potful special curd onion & fresh mint chutney.
                            </p>
                            <p>
                                For those who want to mix it up, we have the Nawabi Tangdi. The taste is doubled when
                                wholesome chicken leg is stuffed with delicious mutton keema, marinated in creamy
                                cardamom, cashew and saffron paste & grilled on charcoal.
                            </p>
                            <p>
                                For the quintessential meat lovers, we have the Galouti kebab. These perfect round
                                patties are made with minced mutton and tenderized to the core with raw papaya and deep
                                fried in ghee. The name of the dish means "melt in the mouth." This is served with tawa
                                parathas.
                            </p>
                            <p>
                                At Potful, we explore the different traditional kebab preparations and bring to you the
                                most authentic recipes and flavours from all over Asia. For the best kebabs in
                                Bangalore, look no further! We provide home delivery of freshly cooked best veg and non
                                veg kebabs. Good food is just a click away! <NavLink className="text-dark no-hover" to="/categories/kebabs"><strong>Order online now!</strong></NavLink>
                            </p>
                            <p>
                                You can also get your Potful food delivery through our Android/ iOS online food delivery
                                app. Use the coupon code <NavLink className="text-dark no-hover" to="/offers"><strong>‘POTLUC’ to avail 20% OFF</strong></NavLink> on all orders!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
            <ModalsPage/>
        </div>
    )
}
export default KebabsPage;
