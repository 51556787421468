import { http } from '../_helpers';

const getBranchList=()=> {
    return http.get(`/manage_branch/getBranchList?version=2.0`)
    .then(data => {
        return data;
    });
}

export const menuService={
    getBranchList
}
