import { config } from '../env';
import { authHeader } from '../_helpers';

export const http = {
    get,
    post,
    put,
    delete: _delete
};

function get(url) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };
    return fetch(config.apiUrl + url, requestOptions).then(handleResponse);
}
function post(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify(data)
    };
    return fetch(config.apiUrl + url, requestOptions).then(handleResponse);
}
function put(url, data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify(data)
    };
    return fetch(config.apiUrl + url, requestOptions).then(handleResponse);
}
function _delete(url) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(config.apiUrl + url, requestOptions).then(handleResponse);
}
function handleResponse(response) {
    return response.text().then(text => {
        // const data = text && JSON.parse(text);
        let data;
        try {
            data = text && JSON.parse(text);
        } catch (error) {
            return "Server down or check your internet connection"
        }
        if (!response) {
            if (response.status === 0) {
                return "Server down or check your internet connection"
            } else if (response.status === 500) {
                return "Internal server error contact API"
            } else if (response.status === 401) {
                window.location(window.location.origin);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        } if (response.status === 500) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
