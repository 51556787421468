import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  isArray,
  checkProductExistOnCart,
  checkHourExistInBetweenTime
} from "../../../utils/util";
import { config } from "../../../env";
// import LazyLoad from "react-lazyload";
import { AddToCart } from "../../../_reducers/cartReducer";
import { connect } from "react-redux";
import LazyLoad from "react-lazyload";
import { Event, AddFB, ViewContentFB } from '../../../_actions/trackEvent';
class FoodItemCardList extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  handleAddToCart = (product, productDetails) => {
    if (product !== undefined) {
      let cartData = [...this.props.cartData];
      let findExistProduct = cartData.findIndex(
        cart => cart.product_price_id === product.product_price_id
      );
      if (findExistProduct !== -1) {
        cartData[findExistProduct].productCount =
          cartData[findExistProduct].productCount + 1;
        this.props.addToCart(cartData);
        this.forceUpdate();
      } else {
        product.productCount = 1;
        //adding product details inside the different serving sizes
        product.product_name = productDetails.product_name;
        product.product_desc = productDetails.product_desc;
        product.is_veg = productDetails.is_veg;
        product.category_id = productDetails.category_id;
        let updatedCartData = cartData.concat([product]);
        this.props.addToCart(updatedCartData);
        this.forceUpdate();
      }
    }
    this.forceUpdate();
  };

  handleDecrementProductCount = product => {
    let cartData = [...this.props.cartData];
    let findExistProduct = cartData.findIndex(
      cart => cart.product_price_id === product.product_price_id
    );
    if (findExistProduct !== -1) {
      let productCount = cartData[findExistProduct].productCount;
      if (productCount > 1) {
        cartData[findExistProduct].productCount =
          cartData[findExistProduct].productCount - 1;
        this.props.addToCart(cartData);
        this.forceUpdate();
      } else {
        cartData = cartData.filter(
          cart => cart.product_price_id !== product.product_price_id
        );
        this.props.addToCart(cartData);
        this.forceUpdate();
      }
    }
    this.forceUpdate();
  };
  handleSetCategoryAndProductId=(categoryId,productId)=>{
    localStorage.setItem('categoryId',categoryId);
    localStorage.setItem('productId',productId);
  }
  render() {
    const { products, cartData, currentBranchInfo } = this.props;
    return (
      <section>
        <div className="products">
          <div className="container">
            <div className="row">
              {products.length &&
                products.map(product => {
                  return (
                    isArray(product.serving_size) && (
                      <div
                        key={product.category_id + product.product_id}
                        className="col-lg-4"
                      >
                        {/* <LazyLoad> */}
                        {product.serving_size.length >= 2 ? (
                          <NavLink
                            className="text-dark"
                            onClick={()=>this.handleSetCategoryAndProductId(product.category_id,product.product_id) + Event("Explore", "View Content", "View Content") + ViewContentFB()}
                            to={`/products/${(product.product_name)&&product.product_name.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-').split(' ').join('-').toLowerCase()}`}
                          >
                            <div className="product-item">
                              {/*<LazyLoad height={321}>*/}
                                <div className="product-img">
                                  {product.image_url ? (
                                    <>
                                      {product.serving_size[0].product_price && product.serving_size[0].markup_price >= product.serving_size[0].product_price ? (
                                          <>
                                            <div className="offer-banner">
                                              <div className="offer-banner-inside">
                                                <div className="offer-banner-number">
                                                  {
                                                    Math.floor(
                                                        ((product.serving_size[0].markup_price -
                                                            product.serving_size[0].product_price)/product.serving_size[0].markup_price)*100
                                                    )
                                                  }%
                                                  {console.log(product.serving_size[0].markup_price, 'if top')}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                      ) : (
                                          ""
                                      )}
                                      <img
                                          src={product.image_url}
                                          alt={product.product_name}
                                      />
                                    </>
                                  ) : (
                                      <>
                                        {product.serving_size[0].product_price && product.serving_size[0].markup_price >= product.serving_size[0].product_price ? (
                                            <>
                                              <div className="offer-banner">
                                                <div className="offer-banner-inside">
                                                  <div className="offer-banner-number">
                                                    {
                                                      Math.floor(
                                                          ((product.serving_size[0].markup_price -
                                                              product.serving_size[0].product_price)/product.serving_size[0].markup_price)*100
                                                      )
                                                    }%
                                                    {console.log(product.serving_size[0].markup_price, 'else top')}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        <img
                                            src={`/images/no-image.jpg`}
                                            alt={product.product_name}
                                        />
                                      </>
                                  )}
                                  {/* <div className="product-label">Bestseller</div> */}
                                </div>
                                {/*</LazyLoad>*/}
                              <div className="product_details">
                                  <h2>
                                    {product.product_name}
                                  </h2>
                              </div>
                              <div className="product_price">
                                <div className="product_price_item float-left">
                                  <p>
                                    <strong>
                                      {product.serving_size[0].product_price && product.serving_size[0].markup_price >= product.serving_size[0].product_price ? (
                                          isArray(product.serving_size[0].product_price) <= product.serving_size[0].markup_price &&
                                          (
                                              <>
                                                <span className="opacity-50"><s>&#8377;{Math.floor(product.serving_size[0].markup_price)}</s></span> {" "} &#8377;{Math.floor(product.serving_size[0].product_price)}
                                              </>
                                          )
                                      ): (
                                          <>
                                            &#8377;{Math.floor(product.serving_size[0].product_price)}
                                          </>
                                      )}
                                    </strong>{" "}
                                  </p>
                                </div>
                                <div className="add-cart float-right">
                                  {product.serving_size.length === 1 ? (
                                      <>
                                        {product.markup_price}
                                      </>
                                  ) : (
                                      <></>
                                  )}
                                  {product.serving_size.length === 1 ? (
                                    checkProductExistOnCart(
                                      product.serving_size[0],
                                      cartData
                                    ) !== 0 ? (
                                      Number(product.stock_status) !== -1 ? (
                                        <p className="btn btn-brand px-0">
                                          <button
                                            onClick={() =>
                                              this.handleDecrementProductCount(
                                                product.serving_size[0]
                                              )
                                            }
                                            className="btn-nothing minus"
                                          >
                                            -
                                          </button>
                                          <span>
                                            {checkProductExistOnCart(
                                              product.serving_size[0],
                                              cartData
                                            )}
                                          </span>
                                          <button
                                            onClick={() =>
                                              this.handleAddToCart(
                                                product.serving_size[0],
                                                product
                                              )
                                            }
                                            className="btn-nothing plus"
                                          >
                                            +
                                          </button>
                                        </p>
                                      ) : (
                                        <p className="">
                                          <button className="btn-brand btn out-of-stock fw-400 py-1">
                                            Out Of Stock
                                          </button>
                                        </p>
                                      )
                                    ) : (
                                      <p className="">
                                        {Number(product.stock_status) !== -1 ? (
                                          <button
                                            onClick={() =>
                                              this.handleAddToCart(
                                                product.serving_size[0],
                                                product
                                              )
                                            }
                                            className="btn btn-brand add-to-cart py-1"
                                          >
                                            {checkHourExistInBetweenTime(
                                              currentBranchInfo,
                                              "Single"
                                            )}
                                          </button>
                                        ) : (
                                          <button className="btn-brand btn out-of-stock fw-400 py-1">
                                            Out Of Stock
                                          </button>
                                        )}
                                      </p>
                                    )
                                  ) : (
                                    <div className="add-cart float-right">
                                      <button
                                        className={
                                          Number(product.stock_status) === -1
                                            ? "btn btn-brand py-1 out-of-stock"
                                            : "btn btn-brand py-1"
                                        }
                                      >
                                        {Number(product.stock_status) === -1
                                          ? "Out Of Stock"
                                          : checkHourExistInBetweenTime(
                                              currentBranchInfo,
                                              "Options"
                                            )}
                                      </button>
                                    </div>
                                  )}
                                </div>
                                <div className="clearfix" />
                              </div>
                            </div>
                          </NavLink>
                        ) : (
                          <div className="product-item">
                            <NavLink
                              className="text-dark"
                              onClick={()=>this.handleSetCategoryAndProductId(product.category_id,product.product_id) + Event("Explore", "View Content", "View Content") + ViewContentFB()}
                            to={`/products/${(product.product_name)&&product.product_name.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-').split(' ').join('-').toLowerCase()}`}
                            >
                              <div className="product-img">

                                {product.image_url && product.image_url ? (
                                    <>
                                      {product.serving_size[0].markup_price > 0 ? (
                                        <>
                                        <div className="offer-banner">
                                          <div className="offer-banner-inside">
                                            <div className="offer-banner-number">
                                                {
                                                  Math.floor(
                                                  ((product.serving_size[0].markup_price -
                                                  product.serving_size[0].product_price)/product.serving_size[0].markup_price)*100
                                                  )
                                                }%
                                              {/*{console.log(product.serving_size[0].markup_price, 'else')}*/}
                                            </div>
                                          </div>
                                        </div>
                                        </>
                                        ) : (
                                            ""
                                      )}
                                      <img
                                          src={product.image_url}
                                          alt={product.product_name}
                                      />
                                    </>
                                ) : (
                                    <>
                                      {product.serving_size[0].markup_price > 0 ? (
                                          <>
                                            <div className="offer-banner">
                                              <div className="offer-banner-inside">
                                                <div className="offer-banner-number">
                                                  {
                                                    Math.floor(
                                                        ((product.serving_size[0].markup_price -
                                                            product.serving_size[0].product_price)/product.serving_size[0].markup_price )*100
                                                    )
                                                  }%
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                      ) : (
                                          ""
                                      )}
                                      <img
                                          src="/images/no-image.jpg"
                                          alt={product.product_name}
                                      />
                                    </>
                                )}
                                {/* <div className="product-label">Bestseller</div> */}
                              </div>
                              <div className="product_details">
                                  <h2>
                                    {product.product_name}
                                  </h2>
                              </div>
                            </NavLink>
                            <div className="product_price">
                              <div className="product_price_item float-left">
                                {product.markup_price >= 0 ? (
                                    <>
                                      {product.markup_price}
                                    </>
                                ) : (
                                    <></>
                                )}
                                <p>
                                  <strong>
                                    {/*{isArray(product.serving_size) &&*/}
                                    {/*  Math.ceil(*/}
                                    {/*    product.serving_size[0].product_price*/}
                                    {/*  )*/}
                                    {/*}*/}
                                    {/*{product.serving_size[0].product_price && (product.serving_size[0].markup_price >= product.serving_size[0].product_price) ? (*/}
                                    {product.serving_size[0].markup_price > 0 ? (
                                        // isArray(product.serving_size[0].product_price) <= product.serving_size[0].markup_price &&
                                            <>
                                              <span className="opacity-50"><s>&#8377;{Math.floor(product.serving_size[0].markup_price)}</s></span> {" "} &#8377;{Math.floor(product.serving_size[0].product_price)}

                                            </>
                                    ): (
                                        <>
                                          &#8377;{Math.floor(product.serving_size[0].product_price)}
                                        </>
                                    )}
                                  </strong>
                                </p>
                              </div>
                              <div className="add-cart float-right">

                                {product.serving_size.length === 1 ? (
                                  checkProductExistOnCart(
                                    product.serving_size[0],
                                    cartData
                                  ) !== 0 ? (
                                    Number(product.stock_status) !== -1 ? (
                                      <p className="btn btn-brand px-0">
                                        <button
                                          onClick={() =>
                                            this.handleDecrementProductCount(
                                              product.serving_size[0], product
                                            ) + Event("E-Commerce", "Removed from cart", "Removed from cart")
                                          }
                                          className="btn-nothing minus"
                                        >
                                          -
                                        </button>
                                        <span>
                                          {checkProductExistOnCart(
                                            product.serving_size[0],
                                            cartData
                                          )}
                                        </span>
                                        <button
                                          onClick={() =>
                                            this.handleAddToCart(
                                              product.serving_size[0],
                                              product
                                            ) + Event("E-Commerce", "Add to cart", "Add to cart") + AddFB()
                                          }
                                          className="btn-nothing plus"
                                        >
                                          +
                                        </button>
                                      </p>
                                    ) : (
                                      <p className="">
                                        {" "}
                                        <button className="btn-brand btn out-of-stock fw-400 py-1">
                                          Out Of Stock
                                        </button>
                                      </p>
                                    )
                                  ) : (
                                    <p className="">
                                      {Number(product.stock_status) !== -1 ? (
                                        <button
                                          onClick={() =>
                                            this.handleAddToCart(
                                              product.serving_size[0],
                                              product
                                            ) + Event("E-Commerce", "Add to cart", "Add to cart") + AddFB()


                                          }
                                          className="btn btn-brand add-to-cart py-1"
                                        >
                                          {checkHourExistInBetweenTime(
                                            currentBranchInfo,
                                            "Single"
                                          )}
                                        </button>
                                      ) : (
                                        <button className="btn-brand btn out-of-stock fw-400 py-1">
                                          Out Of Stock
                                        </button>
                                      )}
                                    </p>
                                  )
                                ) : (
                                  <div className="add-cart float-right">
                                    <button
                                      className={
                                        Number(product.stock_status) === -1
                                          ? "btn btn-brand py-1 out-of-stock"
                                          : "btn btn-brand py-1"
                                      }
                                    >
                                      {Number(product.stock_status) === -1
                                        ? "Out Of Stock"
                                        : checkHourExistInBetweenTime(
                                            currentBranchInfo,
                                            "Option"
                                          )}
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className="clearfix" />
                              {/*{product.product_score && product.product_score && (*/}
                              {/*    <p className="run-text"><img src="/images/cricket.png" alt=""/>{product.product_score} Runs when you order this product</p>*/}

                              {/*)}*/}
                            </div>
                          </div>
                        )}
                        {/* </LazyLoad> */}
                      </div>
                    )
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    cartData: state.CartReducer.cartProducts
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addToCart: products => dispatch(AddToCart(products))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FoodItemCardList);
