import React from 'react';
import { NavLink } from 'react-router-dom';
import { config } from "../../env";
const PageNotFoundPage = (props) => {
    return (
        <div className="page-not-found">
            <div className="container">
                <div className="d-table-m">
                    <div className="d-table-middle">
                        <div className="page-con">
                            <div className="logo">
                                <NavLink to="/">
                                    <img height="100" src={`${config.urlPrefix}/images/rim/logo.svg`} alt="" />
                                </NavLink>
                            </div>
                            <h3 className="mt-4">Oops!</h3>
                            <h5 className="mt-3">404, Page not found.</h5>
                            <NavLink to="/">
                                <button className="btn btn-brand py-2 mt-3">Back to home</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PageNotFoundPage;
