import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { googleAnalyticKey } from "../../../env";
import FacebookLogin from "react-facebook-login"
import GoogleLogin from "react-google-login"
ReactGA.initialize(googleAnalyticKey);
class LoginModal extends Component {
  state = {
    isFaceBookLoginClicked: false,
    disableLoginBtn: false
  };
  LoginSchema = Yup.object().shape({
    phone: Yup.string()
      .min(10, "Phone number is not valid!")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .required("Phone number is not valid!")
  });

  handleSubmit = async (values, { setSubmitting }) => {
    this.analyseGoogleAnalytics();
    this.setState({ disableLoginBtn: true });
    setTimeout(() => {
      this.setState({ disableLoginBtn: false });
    }, 1500);
    await this.props.handleUserLogin(values);
    setSubmitting(false);
  };

  analyseGoogleAnalytics = () => {
    ReactGA.ga("send", "pageview", `/login`);
  };

  responseGoogle = response => {
    this.props.handleSocialUserLogin(response.w3, 2);
  };

  responseGoogleFailed = response => {
    if (response.details !== "Cookies are not enabled in current environment.")
      toast.error(response.error);
  };

  responseFacebook = response => {};
  handleFacebookLoginClick = () => {
    this.setState({ isFaceBookLoginClicked: true });
  };

  render() {
    return (
      <div
        className="modal fade login-modal"
        id="login-modal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close myclose"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="checkout-login-details">
                <p className="modal-head">Login</p>
                {/*<div className="social-login">*/}
                {/*  <ul>*/}
                {/*    <li>*/}
                {/*      {this.state.isFaceBookLoginClicked &&*/}
                {/*      <FacebookLogin*/}
                {/*        appId=""*/}
                {/*        autoLoad={true}*/}
                {/*        fields="name,email,picture"*/}
                {/*        onClick={() => this.responseFacebook()}*/}
                {/*        callback={this.responseFacebook}*/}
                {/*      />*/}
                {/*      }*/}
                {/*      <button onClick={this.handleFacebookLoginClick} className={"kep-login-facebook metro " + (this.state.isFaceBookLoginClicked === true ? "d-none" : "")}>Login with Facebook</button>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <GoogleLogin*/}
                {/*        clientId=""*/}
                {/*        buttonText="Login"*/}
                {/*        onClick={this.handleGoogleLoginClick}*/}
                {/*        onSuccess={this.responseGoogle}*/}
                {/*        onFailure={this.responseGoogleFailed}*/}
                {/*        className="google-link"*/}
                {/*      />*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*  <div className="clearfix" />*/}
                {/*</div>*/}
                <Formik
                  validationSchema={this.LoginSchema}
                  initialValues={{ phone: "" }}
                  onSubmit={this.handleSubmit}
                >
                  {({ isSubmitting, onSubmit }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor='phone'>Phone</label>
                        <Field
                          name="phone"
                          id="phone"
                          type="text"
                          className="form-control"
                          placeholder="10 Digit Phone Number"
                        />
                        <p className="text-danger fs-12">
                          <ErrorMessage name="phone" />
                        </p>
                      </div>
                      {/*<div className="form-group">*/}
                      {/*  <label htmlFor="password">Password</label>*/}
                      {/*  <Field*/}
                      {/*  name="password"*/}
                      {/*  id="password"*/}
                      {/*  type="password"*/}
                      {/*  className="form-control"*/}
                      {/*  placeholder="Password"*/}
                      {/*  />*/}
                      {/*</div>*/}
                      {/*<div className="form-group">*/}
                      {/*  <button*/}
                      {/*      // disabled={isSubmitting || this.state.disableLoginBtn}*/}
                      {/*      className="d-none otp-modal"*/}
                      {/*      data-toggle="modal"*/}
                      {/*      data-target="#otp-modal"*/}
                      {/*  />*/}
                      {/*  <button*/}
                      {/*      type="submit"*/}
                      {/*      className="btn btn-brand btn-block btn-standard "*/}
                      {/*      disabled={isSubmitting || this.state.disableLoginBtn}*/}
                      {/*      onClick={onSubmit}*/}
                      {/*      aria-label="Close"*/}
                      {/*  >*/}
                      {/*    Login*/}
                      {/*  </button>*/}
                      {/*</div>*/}
                      <div className="form-group">
                        <button
                          disabled={isSubmitting || this.state.disableLoginBtn}
                          className="d-none otp-modal"
                          data-toggle="modal"
                          data-target="#otp-modal"
                        />
                        <button
                          type="submit"
                          className="btn btn-brand btn-block btn-standard "
                          disabled={isSubmitting || this.state.disableLoginBtn}
                          onClick={onSubmit}
                          aria-label="Close"
                        >
                          Login
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <p className="noaccount">
                  Don't have an accout?{" "}
                  <button
                    className="btn-nothing"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#signup-modal"
                  >
                    Signup
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginModal;
