import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
const OffersPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <section className="mt-5">
                <div className="category_header mb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="heading mb-4"><span>Offers</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="offers-container">
                                <img src="/images/potful-logo-classic.svg" width="40" alt="" />
                                <p className="my-2">Relish the tantalizing flavours of Potful Claypot Biryanis and avail a 50% off upto Rs. 100 on all your orders. So go on, treat yourself today! Use code: <strong>POTLUC</strong>. Limited period offer.</p>
                                <div className="on-hover">
                                    <button className="btn-nothing text-danger">View Details</button>
                                    <ul>
                                        <li>Get 50% off upto Rs.100</li>
                                        <li>No Minimum Transaction Value.</li>
                                        <li>Limited period offer</li>
                                        {/*<li>Offer is only valid from 1st July to 31th July</li>*/}
                                        {/*<li>Offer is valid in the cities where the merchant is operational</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-lg-4">*/}
                            {/*<div className="offers-container">*/}
                                {/*<img src="/images/amazon-pay.png" width="40" alt="" />*/}
                                {/*<p className="my-2">Pay by OLA Money Postpaid and Get 20% Cashback upto Rs.100 on Ola Money Postpaid. No Minimum Order Value.</p>*/}
                                {/*<div className="on-hover">*/}
                                    {/*<button className="btn-nothing text-danger">View Details</button>*/}
                                    {/*<ul>*/}
                                        {/*<li>Get 20% Cashback upto Rs.100 on Ola Money Postpaid.</li>*/}
                                        {/*<li>No Minimum Transaction Value.</li>*/}
                                        {/*<li>Applicable on First ever txn on FreshMenu using OlaMoney Postpaid</li>*/}
                                        {/*<li>Offer is only valid from 1st July to 31th July</li>*/}
                                        {/*<li>Offer is valid in the cities where the merchant is operational</li>*/}
                                    {/*</ul>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="col-lg-4">*/}
                            {/*<div className="offers-container">*/}
                                {/*<img src="/images/freecharge.png" width="40" alt="" />*/}
                                {/*<p className="my-2">Pay by OLA Money Postpaid and Get 20% Cashback upto Rs.100 on Ola Money Postpaid. No Minimum Order Value.</p>*/}
                                {/*<div className="on-hover">*/}
                                    {/*<button className="btn-nothing text-danger">View Details</button>*/}
                                    {/*<ul>*/}
                                        {/*<li>Get 20% Cashback upto Rs.100 on Ola Money Postpaid.</li>*/}
                                        {/*<li>No Minimum Transaction Value.</li>*/}
                                        {/*<li>Applicable on First ever txn on FreshMenu using OlaMoney Postpaid</li>*/}
                                        {/*<li>Offer is only valid from 1st July to 31th July</li>*/}
                                        {/*<li>Offer is valid in the cities where the merchant is operational</li>*/}
                                    {/*</ul>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="col-lg-4">*/}
                            {/*<div className="offers-container">*/}
                                {/*<img src="/images/simpl.png" width="40" alt="" />*/}
                                {/*<p className="my-2">Pay by OLA Money Postpaid and Get 20% Cashback upto Rs.100 on Ola Money Postpaid. No Minimum Order Value.</p>*/}
                                {/*<div className="on-hover">*/}
                                    {/*<button className="btn-nothing text-danger">View Details</button>*/}
                                    {/*<ul>*/}
                                        {/*<li>Get 20% Cashback upto Rs.100 on Ola Money Postpaid.</li>*/}
                                        {/*<li>No Minimum Transaction Value.</li>*/}
                                        {/*<li>Applicable on First ever txn on FreshMenu using OlaMoney Postpaid</li>*/}
                                        {/*<li>Offer is only valid from 1st July to 31th July</li>*/}
                                        {/*<li>Offer is valid in the cities where the merchant is operational</li>*/}
                                    {/*</ul>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="col-lg-4">*/}
                            {/*<div className="offers-container">*/}
                                {/*<img src="/images/mobikwik.png" width="40" alt="" />*/}
                                {/*<p className="my-2">Pay by OLA Money Postpaid and Get 20% Cashback upto Rs.100 on Ola Money Postpaid. No Minimum Order Value.</p>*/}
                                {/*<div className="on-hover">*/}
                                    {/*<button className="btn-nothing text-danger">View Details</button>*/}
                                    {/*<ul>*/}
                                        {/*<li>Get 20% Cashback upto Rs.100 on Ola Money Postpaid.</li>*/}
                                        {/*<li>No Minimum Transaction Value.</li>*/}
                                        {/*<li>Applicable on First ever txn on FreshMenu using OlaMoney Postpaid</li>*/}
                                        {/*<li>Offer is only valid from 1st July to 31th July</li>*/}
                                        {/*<li>Offer is valid in the cities where the merchant is operational</li>*/}
                                    {/*</ul>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="col-lg-4">*/}
                            {/*<div className="offers-container">*/}
                                {/*<img src="/images/freshmoney.png" width="40" alt="" />*/}
                                {/*<p className="my-2">Pay by OLA Money Postpaid and Get 20% Cashback upto Rs.100 on Ola Money Postpaid. No Minimum Order Value.</p>*/}
                                {/*<div className="on-hover">*/}
                                    {/*<button className="btn-nothing text-danger">View Details</button>*/}
                                    {/*<ul>*/}
                                        {/*<li>Get 20% Cashback upto Rs.100 on Ola Money Postpaid.</li>*/}
                                        {/*<li>No Minimum Transaction Value.</li>*/}
                                        {/*<li>Applicable on First ever txn on FreshMenu using OlaMoney Postpaid</li>*/}
                                        {/*<li>Offer is only valid from 1st July to 31th July</li>*/}
                                        {/*<li>Offer is valid in the cities where the merchant is operational</li>*/}
                                    {/*</ul>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
            <Footer />
            <ModalsPage />
        </div>
    )
}
export default OffersPage;
