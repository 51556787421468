// import { http } from "../_helpers/http-handler";
// import { toast } from "react-toastify";

const USER_LOGIN_START = "USER_LOGIN_START";
const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
const USER_SIGNUP_START = "USER_SIGNUP_START";
const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";
const OTP_VERIFICATION_START = "OTP_VERIFICATION_START";
const OTP_VERIFICATION_FAILED = "OTP_VERIFICATION_FAILED";
const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const APP_LAUNCH = "APP_LAUNCH";

const initialState = {
  accessToken: "",
  mobileNo: "",
  customer_id:"",
  launched:true,
  isLoginClick: false,
  isLoggedIn: false,
  otpSended: false,
  otpVerificationStart: false,
  otpVerified: false,
  userDetail: {}
};

const LoginUser = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_START:
      return {
        ...state,
        isLoginClick: true,
        isLoggedIn: false
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoginClick: false,
        isLoggedIn: true,
        otpSended: true,
        userDetail: action.value
      };
    case USER_LOGIN_FAIL:
      return {
        ...state,
        isLoginClick: false,
        isLoggedIn: false
      };

    case USER_SIGNUP_START:
      return {
        ...state,
        isLoginClick: true
      };
    case USER_SIGNUP_SUCCESS:
      return {
        ...state,
        isLoginClick: false,
        otpSended: true,
        mobileNo: action.value.customer_phone,
        customer_id: action.value.customer_id
      };
    case USER_SIGNUP_FAIL:
      return {
        ...state,
        isLoginClick: false,
        mobileNo: ""
      };

    case OTP_VERIFICATION_START:
      return {
        ...state,
        otpSended: false,
        otpVerificationStart: true
      };
    case OTP_VERIFICATION_FAILED:
      return {
        ...state,
        otpSended: false,
        otpVerificationStart: false
      };
    case OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        accessToken: action.value.accessToken,
        userName:action.value.customer_first_name,
        otpVerified: true,
        isLoggedIn: true,
        otpSended: false,
        otpVerificationStart: false,
        mobileNo: action.value.customer_phone,
        customer_id: action.value.customer_id
      };
      case LOGOUT_SUCCESS:
        return{
          ...state,
          isLoggedIn: false,
          accessToken:'',
          userName:''
        };
    case APP_LAUNCH:
      return{
        ...state,
        launched: action.value
      }

    default:
      return state;
  }
};

export default LoginUser;

export const LoginStart = () => {
  return {
    type: USER_LOGIN_START
  };
};

export const LoginSuccess = value => {
  return {
    type: USER_LOGIN_SUCCESS,
    value: value
  };
};

export const LoginFailed = () => {
  return {
    type: USER_SIGNUP_FAIL
  };
};

export const userLogin = data => {
  // const data = { phone: Number(mobileNumber) };
  return dispatch => {
    dispatch(LoginSuccess(data))
    // dispatch(LoginStart());
    // http
    //   .post(`authentication/customerOTPLogin?version=2.0`, data)
    //   .then(data => {
    //     if (data.error === "" || data.customer_phone !== "") {
    //       dispatch(LoginSuccess(data));
    //     } else {
    //       let errorMessage = data.errors || data.error_message;
    //       toast.error(errorMessage);
    //       dispatch(LoginFailed());
    //     }
    //   })
    //   .catch(err => {
    //     dispatch(LoginFailed());
    //   });
  };
};

export const SignupStart = () => {
  return {
    type: USER_SIGNUP_START
  };
};

export const SignupSuccess = value => {
  return {
    type: USER_SIGNUP_SUCCESS,
    value: value
  };
};

export const SignupFail = () => {
  return {
    type: USER_SIGNUP_START
  };
};

export const userSignup = data => {
  // data = { ...data, version: "2.0" };
  return dispatch => {
    dispatch(SignupSuccess(data));
    // dispatch(SignupStart());
    // http
    //   .post(`authentication/customerSignup?version=2.0`, data)
    //   .then(data => {
    //     if (data.customer_phone !== "") {
    //       dispatch(SignupSuccess(data));
    //     } else {
    //       let errorMessage = data.errors || data.error_message;
    //       toast.error(errorMessage);
    //       dispatch(SignupFail());
    //     }
    //   })
    //   .catch(err => {
    //     dispatch(SignupFail());
    //   });
  };
};

export const OtpVerifyStart = () => {
  return {
    type: OTP_VERIFICATION_START
  };
};

export const OtpVerifySuccess = value => {
  return {
    type: OTP_VERIFICATION_SUCCESS,
    value: value
  };
};

export const OtpVerifyFail = () => {
  return {
    type: OTP_VERIFICATION_FAILED
  };
};

export const otpVerify = (data) => {
  return dispatch => {
    dispatch(OtpVerifySuccess(data));
  };
};

export const logoutSuccess=()=>{
  return {
    type: LOGOUT_SUCCESS
  };
}

export const logout = (data) => {
  return dispatch => {
    dispatch(logoutSuccess(data));
  };
};

export const appLaunched=(value)=>{
  return {
    type: APP_LAUNCH,
    value:value
  };
}

export const appLaunch = (data) => {
  return dispatch => {
    dispatch(appLaunched(data));
  };
};
