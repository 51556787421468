import React from 'react';
import Header from '../_common/header';
import Footer from '../_common/footer';
import ModalsPage from '../_common/modals';
import {config} from "../../env";
import {Helmet} from "react-helmet";

const RefundPolicyPage = (props) => {
    let data = props;
    return (
        <div>
            <Header
                handleShow={e => data.handleShow(e)}
                page="main"
                headerclassName="main"
                removeLocation="false"
            />
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About - Retro in Metro</title>
                <meta name="description"
                      content="We, the Retro In Metro team, are trying to bring back the golden heritage of a long forgotten
                        natural way of consuming millets in the form of RetroSip® millet malts that blends with our current
                        metro lifestyle. #RetroFeelingInMetroLifestyle"/>
            </Helmet>
            <section className="mt-3">
            <div className="container">
               <div className="row">
                   <div className="col">
                       <h1 className="title">
                           Refund policy
                       </h1>
                       <hr/>
                       <p>Refund Policy: We do not refund the amount once the product has been purchased.</p>

                       <p>An order placed can be cancelled within one hour of placement only. To cancel your
                           order, please email us at customersupport@retroinmetro.com with your order ID, and
                           reason for cancellation.</p>
                   </div>
               </div>
            </div>
            </section>
            <Footer/>
            <ModalsPage/>
        </div>
    )
}
export default RefundPolicyPage;
