import { http } from '../_helpers';

export const userService = {
    login,
    logout,
    getMatchSchedule
};
 
function login(data) {
    return http.post('login',data)
    .then(data => {
        // login successful if there's a jwt token in the response
        if (data.result.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(data.result));
        }

        return data;
    });
}
function logout(){
    localStorage.removeItem('user');
}
const getMatchSchedule=()=> {
    return http.get(`/campaign/getMatchSchedule/1`)
        .then(data => {
            return data;
        });
}
