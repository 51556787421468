import React, { Component } from 'react';
import { BrowserRouter as Router , Route,Switch} from "react-router-dom";
import routes from '../../routes';
class HomePage extends Component {
    render() { 
        return (
            <div className="container-fluid p-0">
                <Router>
                    <div className="wrapper">
                        <Switch>
                            {routes.map((route, key) => {
                                return route.component ? (<Route key={key} path={route.path} exact={route.exact} name={route.name} render={props => (
                                    <route.component {...props} />
                                )} />): (null);
                            },
                            )}  
                        </Switch>
                    </div>
                </Router>
            </div>           
        )
    }
}
export default HomePage;