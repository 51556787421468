import React, { Component, useState, useEffect } from 'react';
import TrackOrderPage from './track-order-html';
import { checkoutService } from '../../_services/checkout.service';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import {
  googleAnalyticKey,
  googleTagKeg,
  facebookPixalKey,
  config,
} from '../../env';
import { getValue } from '../../utils/lodash';

ReactGA.initialize(googleAnalyticKey);

function LiveTrackOrder(props) {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [orderDetail, setOrderDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [branchLatLng,setBranchLatLng] = useState([])
  const [customerLatLng,setCustomerLatLng] = useState([])
  const [socketOrderId,setOrderId] = useState('')
  const [socketAllOrders,setSocketAllOrders] = useState('')


  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props.accessToken) {
      getOrderDetails();
    } else {
      props.history.push('/');
    }
    analyseGoogleAnalytics();
    tagSend();
  }, []);


  /* -------------------------------------------------------------------------- */
  /*                               Analytics Section                            */
  /* -------------------------------------------------------------------------- */

  const analyseGoogleAnalytics = () => {
    ReactGA.ga('send', 'pageview', `/track-order`);
  };
  const tagSend = async () => {
    let orderId = props.match.params.orderId;
    let response = await checkoutService.getOrderDetails(orderId);
    // window.gtag('event', 'purchase', {
    //   send_to: 'AW-339199008/W6BVCJeuxNECEKCI36EB',
    //   value: response.data.bill_total,
    //   currency: 'INR',
    //   transaction_id: response.data.bill_id,
    // });
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getOrderDetails = async () => {
    try {
      setIsLoading(true);
      let orderId = props.match.params.orderId;
      let response = await checkoutService.getOrderDetails(orderId);
      // MyEvent('INR', response.data.bill_total)
      if (response.error === '' && response.data.bill_id) {
        let branchLatLng = getValue(response,`data.branch_latlng`,'').split(',');
        let customerLatLng = getValue(response,`data.customer_address.latlng`,'').split(',');
       
        setCustomerLatLng(customerLatLng)
        setBranchLatLng(branchLatLng)

        setOrderDetail(response.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        let errorMessage = response.errors || response.error_message;
        toast.error(errorMessage);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  return (
    <TrackOrderPage
      {...props}
      totalCartData={orderDetail}
      isLoading={isLoading}
      orderId={getValue(props, `match.params.orderId`, '')}
      branchLatLng={branchLatLng}
      customerLatLng={customerLatLng}
      socketOrderId={socketOrderId}
      setOrderId={setOrderId}

      setSocketAllOrders={setSocketAllOrders}
      socketAllOrders={socketAllOrders}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.LoginReducer.accessToken,
  };
};

export default connect(mapStateToProps)(LiveTrackOrder);
