import React, { Component } from "react";
import LoginCheckout from "./login-checkout";
import SignupCheckout from "./signup-checkout";
// import OtpVerifyModal from "../../_common/modalComponent/otp-verify";
import OtpVerify from "../../_common/modalComponent/otp-verify-component";
class SignupLogin extends Component {
  render() {
    return (
      <div className="col-lg-4 offset-lg-2 col-md-7 mt-md-0 mt-4">

        <div className="checkout-login">
          <div className="checkout-login-links">
          {!this.props.isOtpSend && (
            <ul>
              <li>
                <button
                  onClick={e => this.props.login()}
                  className="btn-nothing checkout-login-trigger active"
                >
                  Login
                </button>
              </li>
              <li>
                <button
                  onClick={e => this.props.signup()}
                  className="btn-nothing checkout-signup-trigger"
                >
                  Signup
                </button>
              </li>
            </ul>
          )}
            <div className="clearfix" />
          </div>
          {!this.props.isOtpSend ? (
            <div>
              <LoginCheckout
                handleUserLogin={this.props.handleUserLogin}
                signup={this.props.signup}
              />
              <SignupCheckout
                handleUserSignup={this.props.handleUserSignup}
                login={this.props.login}
              />
            </div>
          ) : (
            <OtpVerify
              handleOtpVerification={this.props.handleOtpVerification}
            />
          )}
          {/* <OtpVerifyModal
            handleOtpVerification={this.props.handleOtpVerification}
          /> */}
        </div>
      </div>
    );
  }
}

export default SignupLogin;
