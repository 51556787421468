import { isArray } from "../utils/util";
const ADD_TO_CART = "ADD_TO_CART_START";
const SET_DEFAULT_CART_DATA = "SET_DEFAULT_CART_DATA";
const SET_BRANCH_ADDRESS = "SET_BRANCH_ADDRESS";
const SET_BRANCH_CATEGORY = "SET_BRANCH_CATEGORY";
// Default branch id 1 as per discussion ,showing default address of branch id 1
const initialState = {
    branchId: 1,
    address: "India",
    cartProducts: [],
    branchCategory: []
};
// Change Branch
const CartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            return {
                ...state,
                cartProducts: action.value
            };
        case SET_DEFAULT_CART_DATA:
            return { cartProducts: [], ...state };
        case SET_BRANCH_ADDRESS:
            return {
                ...state,
                branchId: action.value.branchId,
                address: action.value.address
            };
        case SET_BRANCH_CATEGORY:
            return {
                ...state,
                branchCategory: action.value
            };
        default:
            return state;
    }
};

export default CartReducer;

export const AddToCartAction = value => {
    return {
        type: ADD_TO_CART,
        value: value
    };
};

export const SetDefaultCartData = value => {
    return {
        type: ADD_TO_CART,
        value: value
    };
};

export const AddToCart = value => {
    if (isArray(value)) {
        return dispatch => {
            dispatch(AddToCartAction(value));
        };
    } else {
        return dispatch => {
            dispatch(SetDefaultCartData(value));
        };
    }
};

export const BranchAddress = value => {
    return {
        type: SET_BRANCH_ADDRESS,
        value: value
    };
};

export const SetBranchAddress = (branchId, address) => {
    let data = {
        branchId: branchId,
        address: address
    };
    return dispatch => {
        dispatch(BranchAddress(data));
    };
};

export const SuccessBranchCategory = value => {
    return {
        type: SET_BRANCH_CATEGORY,
        value: value
    };
};

export const SetBranchCategory = data => {
    return dispatch => {
        dispatch(SuccessBranchCategory(data));
    };
};
