import React from 'react';
const FilterContainer = (props) => {
    const { filterBy } = props;
    return (
        <div className={"filter-container " + props.className}>
            {props.close === "true" ?
                <button onClick={e => props.closeFilter()} className="btn-nothing filter-close">&times; Close Filter</button> : ""}
            <div className="filter-head">
                {/*<div className="reset-filter">*/}
                    {/*<button className="btn-nothing pr-0">Reset All Filters</button>*/}
                {/*</div>*/}
                <h6>Filters</h6>
            </div>
            <div className="filter-scroll">
                <h6>Food Preference</h6>
                <div>
                    <button onClick={() => props.handleFilter('1')} className={`btn-border ${filterBy === '1' ? 'active' : ''}`}>Veg</button>
                    <button onClick={() => props.handleFilter('0')} className={`btn-border ${filterBy === '0' ? 'active' : ''}`}>Non Veg</button>
                    <button onClick={() => props.handleFilter('2')} className={`btn-border ${filterBy === '2' ? 'active' : ''}`}>Show All</button>
                </div>
                {/* <h6>Trending</h6>
            <div>
                <button className="btn-border">Hot Selling</button>
                <button className="btn-border">Healthy</button>
                <button className="btn-border">Desserts</button>
                <button className="btn-border">Best Offers</button>
            </div> */}
            </div>
            {/*<div className="filter-footer">*/}
                {/*<button className="btn-brand" onClick={e => this.props.openFilter(e)}>*/}
                    {/*Close*/}
            {/*</button>*/}
            {/*</div>*/}
        </div>
    )
}
export default FilterContainer;
