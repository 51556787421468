import React, { Component } from "react";
import InnerPage from "./inner-html";
import { connect } from "react-redux";
import { AddToCart } from "../../_reducers/cartReducer";
import ls from 'local-storage';

class Inner extends Component {

  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "auto" });
  }


    handleShow=(categoryId)=>{
        sessionStorage.setItem("categoryId", categoryId);
        this.props.history.push('../products');
    }
  render() {
    // const { cartData } = this.props;
    return (
      <InnerPage
        // handleAddToCart={this.handleAddToCart}
        // handleDecrementProductCount={this.handleDecrementProductCount}
        // cartData={cartData}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    cartData: state.CartReducer.cartProducts
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addToCart: products => dispatch(AddToCart(products))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inner);
