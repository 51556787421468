import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import Header from "../_common/header";
import Footer from "../_common/footer";
import ModalsPage from "../_common/modals";
import { CategoryCard, FoodItemCardList } from "./innerComponent/index";
import { homeService } from "../../_services/index";
import { isArray } from "../../utils/util";
import { config } from "../../env";
import { withRouter } from "react-router-dom";

import LazyLoad from "react-lazyload";
import Loader from "../_common/loader";
import ReactGA from "react-ga4";

import { googleAnalyticKey } from "../../env";
import ls from "local-storage";
ReactGA.initialize(googleAnalyticKey);
class InnerPage extends React.Component {
  componentDidMount() {
    fetch(URL)
      .then(response => response.json())
      .then(json =>
        this.setState({
          articles: json.results,
          readNow: ls.get("readNow") || [],
          readLater: ls.get("readLater") || [],
          likedSections: ls.get("products") || []
        })
      );
    this.startInterval();
  }
  state = {
    categoryId: "",
    branchId: "",
    startInterval: "",
    isLoading: true,
    filterBy: "2",
    categories: [],
    prevCategoryProduct: [],
    currentBranchInfo: {},
    isMenu: false
  };

  componentDidMount() {
    const currentLocation = this.props.history.location.pathname;
    this.setCurrentLocation(currentLocation, this.props.branchId);
    this.getBranchCategory();
    this.getCurrentBranchInfo();
    this.analyseGoogleAnalytics();
  }

  setCurrentLocation = (currentLocation, branchId) => {
    currentLocation = currentLocation.split("/");
    const isMenu = currentLocation[1] !== "products" ? true : false;
    // if currentLocation 3 then just show products on basis of product category other wise show all the data.
    this.setState({ branchId: branchId, isMenu });
  };

  analyseGoogleAnalytics = () => {
    ReactGA.ga("send", "pageview", `/products`);
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getCurrentBranchInfo();
    if (nextProps.branchId !== this.state.branchId) {
      this.setState({ branchId: nextProps.branchId });
      this.getBranchCategoryBYId(nextProps.branchId);
    } else if (nextProps.branchId === "") {
      this.setState({ branchId: nextProps.branchId });
      this.setState({
        categories: [],
        prevCategoryProduct: [],
        isLoading: false
      });
    }
  }
  getCurrentBranchInfo = () => {
    let branchCategory = this.props.branchCategory;
    if (isArray(branchCategory)) {
      let currentBranchInfo = branchCategory.find(
        branch => branch.branch_id === this.props.branchId
      );
      currentBranchInfo &&
        this.setState({ currentBranchInfo: currentBranchInfo });
    }
  };
  getBranchCategoryBYId = async branchId => {
    this.setState({ isLoading: true });
    if (branchId) {
      let response = await homeService.getBranchLisById(branchId);
      if (response.data && response.data.length) {
        this.setState({
          categories: response.data,
          prevCategoryProduct: response.data,
          isLoading: false
        });
      } else {
        this.setState({
          categories: [],
          isLoading: false,
          prevCategoryProduct: []
        });
      }
    } else {
      this.setState({
        categories: [],
        isLoading: false,
        prevCategoryProduct: []
      });
    }
  };
  getBranchCategory = async () => {
    let branchId = this.props.branchId;
    if (branchId) {
      let response = await homeService.getBranchLisById(branchId);
      if (response.data && response.data.length) {
        const categoryId = Number(sessionStorage.getItem("categoryId"));
        await this.setState({
          categories: response.data,
          isLoading: false,
          prevCategoryProduct: response.data
        });
        if (categoryId) {
          setTimeout(() => {
            this.handleOnPageLoadShow(categoryId);
          }, 1000);
        }
      } else {
        this.setState({
          categories: [],
          isLoading: false,
          prevCategoryProduct: []
        });
      }
    }
  };
  handleOnPageLoadShow = async categoryId => {
    this.setState({ categoryId: categoryId });
    const getOffset = $("#" + categoryId).offset();
    if (getOffset && getOffset.top) {
      $("html, body").animate(
        {
          scrollTop: $("#" + categoryId).offset().top - 50
        },
        1000
      );
    }
  };

  handleShow = categoryId => {
    const isMenu = this.state.isMenu;
    /*
    for header category onClick event
    if isMenu is true then on click of any category push to category.
    */
    if (isMenu) {
      const categories = [...this.state.categories];
      let categoryName = categories.filter(
        category => category.category_id === categoryId
      );
      categoryName = categoryName[0].category_name;
      categoryName =
        categoryName &&
        categoryName
          // .replace(/[^a-zA-Z1-9- ]/g, "")
            .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-')
          .split(" ")
          .join("-")
          .toLowerCase()

      this.props.history.push(`./${categoryName}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    this.setState({ categoryId: categoryId });
    const getOffset = $("#" + categoryId).offset();
    if (getOffset && getOffset.top) {
      $("html, body").animate(
        {
          scrollTop: $("#" + categoryId).offset().top - 50
        },
        1000
      );
    }
  };
  handleFilter = filterBy => {
    let currentCategoryProduct = JSON.parse(
      JSON.stringify(this.state.prevCategoryProduct)
    );
    this.setState({ filterBy: filterBy });
    if (filterBy !== "2") {
      for (let index = 0; index < currentCategoryProduct.length; index++) {
        let filteredData = currentCategoryProduct[index].products.filter(
          product => product.is_veg === filterBy
        );
        currentCategoryProduct[index].products = filteredData;
      }
      let filteredProducts = [...currentCategoryProduct];
      this.setState({ categories: filteredProducts });
    } else {
      this.setState({ categories: currentCategoryProduct });
    }
    ReactGA.ga("send", "pageview", `/filter_selected`);
  };
  componentWillUnmount() {
    sessionStorage.removeItem("categoryId");
  }
  render() {
    const { isLoading, currentBranchInfo, filterBy, isMenu } = this.state;
    // console.log(isLoading, 'loading');
    let categories = [];
    if (isMenu) {
      let currentCategory = this.props.history.location.pathname;
      currentCategory = currentCategory.split("/")[2];
      categories = this.state.categories.filter(
        category =>
          category.category_name
            // .replace(/[^a-zA-Z1-9- ]/g, "")
              .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-')
            .split(" ")
            .join("-")
            .toLowerCase() === currentCategory

      );
    } else {
      categories = this.state.categories;
    }
    const currentPage = "inner";
    const shareUrl = window.location.origin;
    const productImage = isArray(categories) && categories[0].category_id;
    // console.log(categories,'---',categories.length,'-++branchId--',this.props.branchId)
    return (
      <div>
        <Header
          handleShow={this.handleShow}
          categoryId={this.state.categoryId}
          page="products"
          cartRelatedCat={isArray(categories) && categories[0].products}
          currentPage={currentPage}
          handleFilter={this.handleFilter}
          filterBy={filterBy}
        />
          <Helmet>
            <title>Shop - Retro In Metro</title>
            <meta name="description"
                  content="We, the Retro In Metro team, are trying to bring back the golden heritage of a long forgotten
          natural way of consuming millets in the form of RetroSip® millet malts that blends with our current metro
          lifestyle. #RetroFeelingInMetroLifestyle"/>
              <meta property="og:image:type" content="image/jpeg" />
              <meta
                  property="og:image"
                  content={`${config.productImgUrl}/${productImage}.jpg`}
              />
              <meta property="og:image:width" content="400" />
              <meta property="og:image:height" content="300" />
          </Helmet>
          {/*<LazyLoad>*/}

          {isArray(categories)
          ? categories.map((category, index) => (
              <div key={index} id={category.category_id}>
                {/*<CategoryCard category={category} />{" "}*/}
                <div className="dummy mt-4"></div>
                <FoodItemCardList
                  products={category.products}
                  handleAddToCart={this.props.handleAddToCart}
                  handleDecrementProductCount={
                    this.props.handleDecrementProductCount
                  }
                  // cartData={this.props.cartData}
                  currentBranchInfo={currentBranchInfo}
                />{" "}
              </div>
            ))
          : isLoading && (
              <div>
                {/*<LazyLoad debounce={200000}>*/}
                {/*  <h2 className="mt-4 mb-4 pt-4 pb-4 text-center">*/}
                {/*    Sorry, we couldn't find any categories for the selected*/}
                {/*    location.*/}
                {/*  </h2>*/}
                {/*</LazyLoad>*/}
                <Loader />
              </div>
            )}
        {/*<LazyLoad*/}
        {/*    debounce={3000}*/}
        {/*>*/}
        {/*{!isArray(categories) &&  (*/}

        {/*      <div className="container">*/}
        {/*        <div className="col-12">*/}
        {/*          <div className="nothing-available">*/}
        {/*            <h4 className="m-2">*/}
        {/*              Sorry, we might not be serving this location.*/}
        {/*            </h4>*/}
        {/*            <p className="m-2">Please change the location.</p>*/}
        {/*            <button*/}
        {/*                className="btn btn-brand px-4 py-2 w-auto"*/}
        {/*                data-toggle="modal"*/}
        {/*                data-target="#location-modal"*/}
        {/*            >*/}
        {/*              Change Location*/}
        {/*            </button>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}

        {/*)}*/}
        {/*</LazyLoad>*/}
        <Footer categories={this.state.categories} isMenu={isMenu} />
        <ModalsPage />
           {/*</LazyLoad>*/}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    branchId: state.CartReducer.branchId,
    address: state.CartReducer.address,
    branchCategory: state.CartReducer.branchCategory,
    cartData: state.CartReducer.cartProducts
  };
};
export default withRouter(connect(mapStateToProps)(InnerPage));
