import { http } from '../_helpers';

const postOtpLogin=(payload)=> {
    return http.post('/authentication/customerOTPLogin?version=2.0',payload)
        .then(data => {
            return data?data:'';
        });
}

const postPasswordLogin=(payload)=> {
    return http.post('/authentication/verify_customer_login?version=2.0',payload)
        .then(data => {
            return data?data:'';
        });
}

const postUserSignup=(payload)=>{
    return http.post('/authentication/customerSignup?version=2.0',payload)
    .then(data => {
        return data?data:'';
    });
}

const postUserSignupWithPassword=(payload)=>{
    return http.post('/authentication/sign_up_customer?version=2.0',payload)
        .then(data => {
            return data?data:'';
        });
}

const postOtpVerify=(payload)=>{
    return http.post('/authentication/verifyOTP?version=2.0',payload)
    .then(data => {
        return data?data:'';
    });
}

const postSocialLogin=(payload)=> {
    return http.post('/authentication/verifySocialLogin?version=2.0',payload)
        .then(data => {
            return data?data:'';
        });
}

export const authService = {
    postOtpLogin,
    postPasswordLogin,
    postUserSignup,
    postUserSignupWithPassword,
    postOtpVerify,
    postSocialLogin
};
