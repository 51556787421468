import React from "react";
import { NavLink } from 'react-router-dom';
import Header from "../_common/header";
import Footer from "../_common/footer";
import ModalsPage from "../_common/modals";
import { CategoryCard, FoodItemCardList } from "./innerComponent/index";
class RestaurantMenuPage extends React.Component {
  render() {
    return (
      <div>
        <Header
        />
        <div>
          {/* <CategoryCard /> */}
          <section className="mar-t40 offset">
            <div className="category_header">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="heading"><span>Claypot Biryani</span></h2>
                    <p>Lorem Ipsum is simply dummy text used by designers.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <FoodItemCardList
          /> */}
          <section>
            <div className="products">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6"
                  >
                    <NavLink
                      className="text-dark"
                      to="/restaurant-menu1"
                    >
                      <div className="product-item">
                        <div className="product-img">
                          <img
                            src="/images/no-picture.png"
                            alt=""
                          />
                          <div className="product-disc">
                            <p>Biryani is good for health</p>
                          </div>
                        </div>
                        <div className="product_details">
                          <h2>
                            <img
                              src="/images/veg.svg"
                              className="mt--2px mr-1"
                              alt=""
                            />
                            Loremk Ipsm
                          </h2>
                        </div>
                        <div className="product_price">
                          <div className="product_price_item float-left">
                            <p>
                              <strong>222
                              </strong>{" "}
                            </p>
                          </div>
                          <div className="add-cart float-right">
                            <p className="btn btn-brand px-0">
                              <button
                                className="btn-nothing minus"
                              >
                                -
                                          </button>
                              <span>
                                2
                                      </span>
                              <button
                                className="btn-nothing plus"
                              >
                                +
                                          </button>
                            </p>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className="col-lg-6"
                  >
                    <NavLink
                      className="text-dark"
                      to="/restaurant-menu1"
                    >
                      <div className="product-item">
                        <div className="product-img">
                          <img
                            src="/images/no-picture.png"
                            alt=""
                          />
                          <div className="product-disc">
                            <p>Biryani is good for health</p>
                          </div>
                        </div>
                        <div className="product_details">
                          <h2>
                            <img
                              src="/images/veg.svg"
                              className="mt--2px mr-1"
                              alt=""
                            />
                            Loremk Ipsm
                          </h2>
                        </div>
                        <div className="product_price">
                          <div className="product_price_item float-left">
                            <p>
                              <strong>222
                              </strong>{" "}
                            </p>
                          </div>
                          <div className="add-cart float-right">
                            <p className="btn btn-brand px-0">
                              <button
                                className="btn-nothing minus"
                              >
                                -
                                          </button>
                              <span>
                                2
                                      </span>
                              <button
                                className="btn-nothing plus"
                              >
                                +
                                          </button>
                            </p>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        <ModalsPage />
      </div>
    );
  }
}
export default RestaurantMenuPage;
