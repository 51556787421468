/* eslint-disable no-undef */
import React from "react";
import Helmet from "react-helmet";
import Header from "../_common/header";
import Footer from "../_common/footer";
import { NavLink } from "react-router-dom";
import ModalsPage from "../_common/modals";
import ProductSize from "./components/productSize";
import SocialShare from "./components/socialShare";
import SimilarFoods from "./components/similarFoods";
import { config } from "../../env";
import LazyLoad from "react-lazyload";
import { isArray } from "../../utils/util";
import Loader from "../_common/loader";
import "react-image-gallery/styles/css/image-gallery.css";
  import ImageGallery from 'react-image-gallery';
import StarRatings from 'react-star-ratings';
const ProductDetailsPage = props => {

  const images = [
    {
      original: '/images/rim/products/1.jpeg',
      thumbnail: '/images/rim/products/1.jpeg',
    },
    {
      original: '/images/rim/products/2.jpeg',
      thumbnail: '/images/rim/products/2.jpeg',
    },
    {
      original: '/images/rim/products/3.jpeg',
      thumbnail: '/images/rim/products/3.jpeg',
    },
    {
      original: '/images/rim/products/4.jpeg',
      thumbnail: '/images/rim/products/4.jpeg',
    },
    {
      original: '/images/rim/products/5.jpg',
      thumbnail: '/images/rim/products/5.jpg',
    },
    {
      original: '/images/rim/products/6.jpg',
      thumbnail: '/images/rim/products/6.jpg',
    },
    {
      original: '/images/rim/products/7.jpg',
      thumbnail: '/images/rim/products/7.jpg',
    },
    {
      original: '/images/rim/products/8.jpeg',
      thumbnail: '/images/rim/products/8.jpeg',
    },
    {
      original: '/images/rim/products/9.jpg',
      thumbnail: '/images/rim/products/9.jpg',
    },
    {
      original: '/images/rim/products/10.jpeg',
      thumbnail: '/images/rim/products/10.jpeg',
    },
    {
      original: '/images/rim/products/11.jpg',
      thumbnail: '/images/rim/products/11.jpg',
    },
    {
      original: '/images/rim/products/12.jpeg',
      thumbnail: '/images/rim/products/12.jpeg',
    },
    {
      original: '/images/rim/products/13.jpg',
      thumbnail: '/images/rim/products/13.jpg',
    },
  ];
  const {
    similarFoods,
    productDetails,
    cartData,
    currentPage,
    cartRelatedCat,
    handleAddToCart,
    currentBranchInfo,
    handleDecrementProductCount,
    handleSimilarProductClick,
    categories,
    shareUrl,
    handleShow
  } = props;

  return (
    <div>
      <LazyLoad>
        <Helmet>
          <title>{productDetails.product_name}</title>
          <meta property="og:url" content={`${shareUrl}/products`} />{" "}
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={`${productDetails.product_name}`}
          />
          <meta
            property="og:description" name="description"
            content={`${productDetails.product_desc}`}
          />
          <meta property="og:image:type" content="image/jpeg" />
          <meta
            property="og:image"
            content={`${config.productImgUrl}/${productDetails.product_id}.jpg`}
          />{" "}
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="300" />
        </Helmet>{" "}
        <Header
          page="details"
          currentPage={currentPage}
          cartRelatedCat={cartRelatedCat}
          handleSimilarProductClick={handleSimilarProductClick}
          handleShow={handleShow}
        />{" "}
        <section className="product-details mb-5">
          <div className="container-fluid">
            <div className="text-right mb-3 d-lg-none">
              <NavLink
                to="/products"
                className="btn btn-secondary back-tomenu"
              >
                Back to Menu{" "}
              </NavLink>{" "}
            </div>{" "}
            <div className="row">
              <div className="col-lg-6">
                <div className="sticky-div">
                  {" "}
                  {props.isLoading ? (
                    <Loader />
                  ) : (
                    <div className="border-radius mb-3">
                      <p>

                      </p>
                      <LazyLoad>
                        <ImageGallery
                            items={productDetails.image_url_list.map((image) => (
                                {
                                  original: image.image_url,
                                  thumbnail: image.image_url
                                }
                            ))}
                            thumbnailPosition="left"
                            disableThumbnailScroll={false}
                            showPlayButton={false}
                        />
                        {/*<img*/}
                        {/*  className="img-fluid"*/}
                        {/*  src={productDetails.image_url}*/}
                        {/*  alt=""*/}
                        {/*/>*/}
                      </LazyLoad>{" "}
                    </div>
                  )}{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-lg-6">
                {" "}
                {props.isLoading ? (
                  <Loader />
                ) : (
                  <div>
                    {/*<div className="text-right mb-3 d-none d-lg-block">*/}
                    {/*  <NavLink*/}
                    {/*    to="/restaurant-menu"*/}
                    {/*    className="btn btn-secondary back-tomenu"*/}
                    {/*  >*/}
                    {/*    Back to Menu{" "}*/}
                    {/*  </NavLink>{" "}*/}
                    {/*</div>{" "}*/}
                    <h2 className="mt-1 product-name">
                      {/*{" "}*/}
                      {/*{productDetails.is_veg === "1" ? (*/}
                      {/*    <img*/}
                      {/*        src={`${config.urlPrefix}/images/veg.svg`}*/}
                      {/*        className="mt--2px mr-1"*/}
                      {/*        alt=""*/}
                      {/*    />*/}
                      {/*) : (*/}
                      {/*    <img*/}
                      {/*        src={`${config.urlPrefix}/images/non_veg.svg`}*/}
                      {/*        className="mt--2px mr-1"*/}
                      {/*        alt=""*/}
                      {/*    />*/}
                      {/*)}{" "}*/}
                      {productDetails.product_name
                          ? productDetails.product_name
                          : ""}{" "}
                    </h2>
                    {/*<h2>*/}
                    {/*  {productDetails.serving_size[0].product_price ? (*/}

                    {/*          <strong>*/}
                    {/*            <span className="opacity-50"><s>&#8377;{Math.floor(productDetails.serving_size[0].markup_price)}</s></span> {" "} &#8377;{Math.floor(productDetails.serving_size[0].product_price)}*/}
                    {/*          </strong>*/}

                    {/*  ): (*/}
                    {/*      <>*/}
                    {/*        &#8377;{Math.floor(productDetails.serving_size[0].product_price)}*/}
                    {/*      </>*/}
                    {/*  )} {" "}*/}
                    {/*  {productDetails.serving_size[0].markup_price > 0 ? (*/}
                    {/*      <>*/}
                    {/*        <div className="badge badge-success">*/}
                    {/*              {*/}
                    {/*                Math.floor(*/}
                    {/*                    ((productDetails.serving_size[0].markup_price -*/}
                    {/*                        productDetails.serving_size[0].product_price)/productDetails.serving_size[0].markup_price)*100*/}
                    {/*                )*/}
                    {/*              }%*/}
                    {/*        </div>*/}
                    {/*      </>*/}
                    {/*  ) : (*/}
                    {/*      ""*/}
                    {/*  )}*/}
                    {/*</h2>*/}
                    {/*<h2>₹ {Math.ceil(productDetails.serving_size[0].product_price)}</h2>*/}
                    {/*<p>*/}
                    {/*  <StarRatings*/}
                    {/*      rating={3.5}*/}
                    {/*      starRatedColor="#f9ca24"*/}
                    {/*      numberOfStars={5}*/}
                    {/*      name='rating'*/}
                    {/*      starDimension="20px"*/}
                    {/*      starSpacing='3px'*/}
                    {/*  />*/}

                    {/*</p>*/}
                    <ProductSize
                        // key={ Math.random()}
                        productSizes={productDetails.serving_size}
                        // cartData={cartData}
                        // handleAddToCart={handleAddToCart}
                        // handleDecrementProductCount={handleDecrementProductCount}
                        productDetails={productDetails}
                        currentBranchInfo={currentBranchInfo}
                    />{" "}

                    <pre className="desc-p opacity-1 mb-1 pre-wrap">
                      {productDetails.product_desc
                          ? productDetails.product_desc
                          : ""}
                        </pre>
                    {/*<pre className="desc-p opacity-1 mb-1 pre-wrap">*/}
                    {/*  RetroSip millet malts are purely single millet malts prepared using the traditional malting process where millets have been soaked for 6–8 hours and cereals have been sprouted and sun dried.*/}
                    {/*  <br/>*/}
                    {/*  This product is a combination of 200g packs of 5 single millet malt prepared from the millets listed below:*/}
                    {/*  <br/>*/}
                    {/*  <ul>*/}
                    {/*    <li>Kodo Millet</li>*/}
                    {/*    <li>Little Millet</li>*/}
                    {/*    <li>Barnyard Millet</li>*/}
                    {/*    <li>Browntop Millet</li>*/}
                    {/*    <li>Foxtail Millet</li>*/}
                    {/*  </ul>*/}
                    {/*</pre>*/}
                    {/*/!* <p className="mb-0">*/}
                    {/*                  <img src="/images/chilli.svg" height="14" alt="Chilli" />*/}
                    {/*                  <img src="/images/chilli.svg" height="14" alt="Chilli" />*/}
                    {/*                  <img src="/images/chilli.svg" height="14" alt="Chilli" />*/}
                    {/*                </p> *!/{" "}*/}
                    {/*{productDetails.product_score && productDetails.product_score && (*/}
                    {/*    <p className="run-text"><img src="/images/cricket.png" alt=""/>{productDetails.product_score} Runs when you order this product</p>*/}

                    {/*)}*/}


                    {/*<SocialShare*/}
                    {/*    shareUrl={shareUrl}*/}
                    {/*    title={productDetails.product_name}*/}
                    {/*    img={productDetails.image_url}*/}
                    {/*    description={productDetails.product_desc}*/}
                    {/*/>{" "}*/}
                    {isArray(similarFoods) && (
                      <SimilarFoods
                        similarFoods={similarFoods}
                        // cartData={cartData}
                        // handleAddToCart={handleAddToCart}
                        // handleDecrementProductCount={handleDecrementProductCount}
                        // handleSimilarProductClick={handleSimilarProductClick}
                        currentBranchInfo={currentBranchInfo}
                      />
                    )}{" "}
                  </div>
                )}{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <Footer categories={categories} /> <ModalsPage />
      </LazyLoad>{" "}
    </div>
  );
};
export default ProductDetailsPage;
